export const turnArrToString = (arr: string[]) => {
    return arr.join(', ')
}

export const updateElementById = (array: any[], id: number, updatedElement: any) => {
    return array.map((element: any) => {
        if (element.id === id) {
            return {
                ...element,
                ...updatedElement
            };
        }
        return element;
    });
}
