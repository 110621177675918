import clsx from 'clsx'
import { SpeechBubbleProps } from '../consts/interfaces/SpeechBubble.interface'
import GenericProfilePic from './GenericProfilePic'
import "../style/speechBubble.scss"

const SpeechBubble: React.FC<SpeechBubbleProps> = (props) => {
    return (
        <div className="single-message" onClick={props.onClick ? props.onClick : () => { }}>
            <div className={clsx('container-content-right ', props.imgDown && 'hidden')}>
                {props.isSameOrg ?
                    <> </>
                    :
                    <div className="container-content-right-inner">
                        <GenericProfilePic src={props.imgSrc} className="logo" />
                        <span className="name">{props.title} </span>
                    </div>
                }
            </div>

            <div className="container-content-left">
                <div className={props.classname ? props.classname : "bubble"}> {props.children} </div>
            </div>
        </div>
    )
}

export default SpeechBubble;
