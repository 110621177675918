// import { useQuery } from "@tanstack/react-query";
// import { getThankYouFeedbacksForAgencyInfo } from "../../utils/api/agencyStatistics";
import axios from "axios";

export async function useThankYouFeedbacksForAgencyInfo(agencyId: string) {
    // return useQuery(
    //     ['feedback', 'agency', agencyId],
    //     () => getThankYouFeedbacksForAgencyInfo(agencyId)
    //     )

    return (await axios.get(`/api/statistics/thank-you-feedbacks-for-agency?agencyId=${agencyId}`)).data

}
