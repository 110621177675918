// Request number context
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAsyncEffect } from '@hilma/tools';
import { ChildProps, RequestNumberContextValue } from '../consts/interfaces/RequestNumberContext.interface';
import { useIsAuthenticated } from '@hilma/auth';

export const RequestNumberContext = React.createContext<RequestNumberContextValue>({
    requestNumber: 0,
    isPopupRequestOpen: false,
    isWaitingOrganization: false,
    isWaitingAgency: false,
    setRequestNumber: () => { },
    setIsPopupRequestOpen: () => { }
});

export const RequestNumberProvider: React.FC<ChildProps> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();

    const [isWaitingAgency, setIsWaitingAgency] = useState<boolean>(false)
    const [isWaitingOrganization, setIsWaitingOrganization] = useState<boolean>(false)
    const [isPopupRequestOpen, setIsPopupRequestOpen] = useState<boolean>(false)
    const [requestNumber, setRequestNumber] = useState<number>(0);

    useAsyncEffect(async () => {
        if (!isAuthenticated) return;
        try {
            setIsWaitingAgency((await axios.get(`/api/agency/request-minimal`)).data.length > 0)
            setIsWaitingOrganization((await axios.get(`/api/organization/request-minimal`)).data.length > 0)
        } catch (err) {
            console.error(err);
        }
    }, [isAuthenticated])

    useEffect(() => {
        (isWaitingAgency || isWaitingOrganization) && setIsPopupRequestOpen(true)
    }, [isWaitingAgency, isWaitingOrganization])

    return <RequestNumberContext.Provider value={{
        requestNumber, isWaitingOrganization,
        isWaitingAgency, setRequestNumber, isPopupRequestOpen, setIsPopupRequestOpen
    }}>
        {children}
    </RequestNumberContext.Provider>
}

export const useRequestNumber = () => useContext(RequestNumberContext)!;