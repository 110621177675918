import { useContext } from "react";
import { agencyStatContext } from "../../consts/interfaces/AgencyStatisticsContext.interface";
import { FROM_THIS_AGENCY, ORGS_RECEIVED } from "../../consts/hebrew";

import { AgencyStatisticsContext } from "../../context/AgencyStatisticsContext";
import WhiteBoxWrapper from "../../generic-components/WhiteBoxWrapper"

import "../../style/receivedFromSpecific.scss";

const ReceivedFromSpecific: React.FC<{ style: { width?: string, maxHeight?: string } }> = ({ style }) => {
    const { receivedOrgsFromSpecific } = useContext(AgencyStatisticsContext) as agencyStatContext

    return (
        <WhiteBoxWrapper>
            <div className="value-container" style={style}>
                <div className="num-value">
                    {receivedOrgsFromSpecific}
                </div>
                <div className="value-title">
                    <p> {ORGS_RECEIVED}</p>
                    <p> {FROM_THIS_AGENCY}</p>
                </div>
            </div>
        </WhiteBoxWrapper>
    )
}
export default ReceivedFromSpecific