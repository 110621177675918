import { OrgsFeedbackProps } from '../consts/interfaces/OrgsFeedback.interface';
import '../style/orgsFeedback.scss';

const OrgsFeedback: React.FC<OrgsFeedbackProps> = (props) => {

    return (
        <div className="container-of-logos">
            {props.orgs.map((item, i) => {
                return (
                    <div key={i} className="inner-container">
                        <div className="logo">
                            <img src={`${item.logoImage}`} alt='profile' />
                        </div>
                        <span className="name">{item.name} </span>
                    </div>
                )
            })}
        </div>
    )
}
export default OrgsFeedback;