import { useContext } from "react";
import moment from "moment";

import { agencyStatContext } from "../../consts/interfaces/AgencyStatisticsContext.interface";
import { FEEDBACKS } from "../../consts/hebrew";

import { AgencyStatisticsContext } from "../../context/AgencyStatisticsContext";
import GenericPopup from "../../generic-components/GenericPopup";

import '../../style/popupFeedbacks.scss'

interface PopupFeedbacksPropsInterface {
    isPopupOpen: boolean
    handleClose: () => void
}

const PopupAgencyFeedback: React.FC<PopupFeedbacksPropsInterface> = ({ isPopupOpen, handleClose }) => {

    const { feedbacksOfAgency } = useContext(AgencyStatisticsContext) as agencyStatContext;

    return (
        <>
            {feedbacksOfAgency && (
                <GenericPopup
                    open={isPopupOpen}
                    title={FEEDBACKS}
                    handleClose={() => handleClose()}
                    className="popup-feedbacks-body">

                    <div className="scrollable-container">
                        {feedbacksOfAgency.map((feedbacksOfEvent) => {
                            const momentDate = moment(new Date(feedbacksOfEvent.date))
                            const fullDate = momentDate.format('DD.MM.YYYY')
                            return (
                                <>
                                    <div className="event-details-popup-feedback">{feedbacksOfEvent.name + " | " + fullDate}</div>
                                    {feedbacksOfEvent.feedbacks?.map((feedback) => {
                                        return (
                                            (feedback.feedbackText !== "") ?
                                                <>
                                                    <div key={feedback.feedbackId} className="bubble-and-logo-feedbacks right">
                                                        <div>
                                                            <div className="logo-feedbacks right">
                                                                <img src={`${feedback.profilePhoto}`} alt="profile" />
                                                            </div>
                                                            <div className="organization-name">{feedback.organizationName}</div>
                                                        </div>
                                                        <div className="bubble-feedbacks right">{feedback.feedbackText}</div>
                                                    </div>
                                                    {feedback.images.length > 0 ?
                                                        feedback.images.map((img) => {
                                                            return (
                                                                <div key={feedback.feedbackId} className="bubble-and-logo-feedbacks right">
                                                                    <div className="bubble-feedbacks imgBox right"><img className="imgPopupFeedbacks" src={`${img}`} alt="visual feedback" /></div>
                                                                </div>
                                                            )
                                                        })
                                                        : null}
                                                </>
                                                : null)
                                    })
                                    }
                                </>
                            );
                        })}
                    </div>
                </GenericPopup>
            )}
        </>
    );

}

export default PopupAgencyFeedback;
