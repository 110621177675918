
// valid text- numbers letters and dashes
export const VALID_TEXT = /^[0-9\u0590-\u05fea-z\s ]+$/i;

// valid phone number- 10 numbers and has to start with a 0
export const VALID_PHONE_NUMBER = /^05\d([-]{0,1})\d{7}$/
// /^0[0-9]{2}-?[0-9]{3}-?[0-9]{4}$/;

//valid Email address
export const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,5})+$/i;

// valid date
export const VALID_DATE = /^[0-9]+.[0-9]+.[0-9]/;

// valid number- only numbers
export const VALID_NUM = /^[0-9]/;

//valid name- only english and hebrew letters
export const VALID_NAME = /^[a-z\u0590-\u05fe ]+$/i;

//valid code- only 6 numbers
export const VALID_CODE = /^[0-9]{6}$/;

//valid link
//eslint-disable-next-line
export const VALID_LINK = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
