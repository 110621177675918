import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";

import { EventLiveDetails } from "../consts/interfaces/EventLiveDetails.interface";
import { NO_RESULTS } from "../consts/hebrew";

import GenericSearchBar from "../generic-components/GenericSearchBar";
import LiveEventBox from "./LiveEventBox";

import { useEventsEnterLiveQuery } from "../hooks/api/event";
import { useSocketListener } from "../hooks/socket";
import "../style/listOfEventsEnterLive.scss";

//this component should get the events list from context and present the relevant events (past or future) according to the url
const ListOfEventsEnterLive: React.FC<{
    eventsType: "events" | "archive-events";
}> = ({ eventsType }) => {
    const { data, refetch, isLoading } = useEventsEnterLiveQuery();

    const [filteredEvents, setFilteredEvents] = useState<EventLiveDetails[]>(
        []
    );

    const eventsArr =
        eventsType === "events" ? data?.futureEvents : data?.pastEvents || [];

    useEffect(() => {
        setFilteredEvents(eventsArr);
    }, [eventsArr]);

    useSocketListener("event-added", () => {
        refetch();
    });

    return (
        <div>
            <GenericSearchBar
                setFilteredArr={setFilteredEvents}
                initialOptions={eventsArr}
            />
            <div className="live-events-container">
                <div className="live-events-list">
                    {!isLoading && filteredEvents ? (
                        filteredEvents.length > 0 ? (
                            filteredEvents.map((event) => (
                                <LiveEventBox
                                    key={event.eventId}
                                    eventId={event.sourceId}
                                    img={event.coverImage}
                                    artist={event.title}
                                    date={event.date}
                                    totalTickets={event.ticketsAmount}
                                    timeLeft={event.timeLeft?.split(",")[0]}
                                    timerDuration={
                                        event.timeLeft?.split(",")[1]
                                    }
                                    purchasedTickets={event.purchasedAmount}
                                    orgsOffered={event.offeredOrgsCount}
                                    orgsPurchased={event.purchasedOrgsCount}
                                    publishedAt={event.publishedAt}
                                    agencyName={event.agencyName}
                                    imageCoordinates={event.imageCoordinates}
                                    eventType={event.eventType}
                                    eventStatus={event.eventStatus}
                                />
                            ))
                        ) : (
                            <div className="no-results">{NO_RESULTS}</div>
                        )
                    ) : (
                        <>
                            <Skeleton
                                variant="rounded"
                                sx={{ marginBottom: "2vh" }}
                                height={"15vw"}
                                width={"100%"}
                            />
                            <Skeleton
                                variant="rounded"
                                sx={{ marginBottom: "2vh" }}
                                height={"15vw"}
                                width={"100%"}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListOfEventsEnterLive;
