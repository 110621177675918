import EventCategoriesPopupProps from "../../consts/interfaces/EventCategoriesPopup.interface";
import { EVENT_TYPES } from "../../consts/hebrew";

import GenericTextNumberRow from "../../generic-components/GenericTextNumberRow";
import GenericPopup from "../../generic-components/GenericPopup";

import '../../style/eventCategoriesPopup.scss';

const PopupEventCategories: React.FC<EventCategoriesPopupProps> = ({ categories, open, handleClose }) => {

    return (
        <GenericPopup className="event-categories-popup" title={EVENT_TYPES} open={open} handleClose={handleClose} >
            <div className="categories-container">
                {categories.map(category =>
                    <GenericTextNumberRow key={category.name} text={category.name} number={category.value} width="98%" />)}
            </div>
        </GenericPopup>
    )
}

export default PopupEventCategories;