import { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';

import { REMOTE_LOGIN_TEXTS } from '../../consts/hebrew';
import { RemoteLoginPopupStages, codeInputProps } from '../../consts/variables';
import GenericPopup from '../../generic-components/GenericPopup';
import ResetLink from './ResetLink';
import { SendRemoteLoginCodeButton } from './SendRemoteLoginCodeButton';

import styles from './popupAdminRemoteLogin.module.scss';
import { VerifyCodeButton } from './VerifyCodeButton';
import clsx from 'clsx';
import { usePubSubHandler, usePubSubRegistry } from '../../hooks/pubSub';

interface IPopupAdminRemoteLogin {
    open: boolean
    handleClose(): void,
    adminEmail: string,
    entityName: string,
    entityType: string,
    entityId: number
}
function PopupAdminRemoteLogin(props: IPopupAdminRemoteLogin) {

    const [stage, setStage] = useState<RemoteLoginPopupStages>(RemoteLoginPopupStages.Approve)
    const [isValidCode, setIsValidCode] = useState(true)
    const inputRef = useRef<ReactCodeInput>(null)
    const codeValueRef = useRef<string>('');
    const codeValidityUpdatedPubSub = usePubSubRegistry<boolean>(false);

    usePubSubHandler(codeValidityUpdatedPubSub, setIsValidCode);

    const handleCodeChange = (code: string) => {
        const isValidLength = code.length === 6;
        codeValueRef.current = code;

        codeValidityUpdatedPubSub.publish(isValidLength);
    }

    const handleClick = () => {
        setStage(RemoteLoginPopupStages.VerifyCode)
    }

    return (
        <GenericPopup
            open={props.open}
            handleClose={props.handleClose}
            className={clsx(styles.popupRemoteLogin, stage === RemoteLoginPopupStages.VerifyCode && styles.extend)}
        >
            <>
                <div className={styles.remoteLoginDescriptionContainer}>
                    <p className={styles.remoteLoginDescription}>{REMOTE_LOGIN_TEXTS.POPUP_TITLE_TEXT} </p>
                    <p className={styles.remoteLoginEntityName}>{props.entityName}</p>
                </div>
                {
                    stage === RemoteLoginPopupStages.Approve
                        ? <>
                            <div className={styles.popupLabelText}>
                                <p>{REMOTE_LOGIN_TEXTS.FIRST_POPUP_INFO_TEXT}</p>
                                {props.entityType === 'agency' ?
                                    <p>{REMOTE_LOGIN_TEXTS.SECOND_POPUP_INFO_TEXT_AGENCY}</p> :
                                    <p>{REMOTE_LOGIN_TEXTS.SECOND_POPUP_INFO_TEXT_ORGANIZATION}</p>
                                }
                            </div>
                            <SendRemoteLoginCodeButton {...props} onCodeSent={handleClick} />
                        </>
                        : <>
                            <div className={styles.popupLabelText}>
                                <p>{REMOTE_LOGIN_TEXTS.POPUP_CODE_SEND_TO_MAIL}</p>
                                <p>{props.adminEmail}</p>
                            </div>
                            <ReactCodeInput
                                ref={inputRef}
                                isValid={isValidCode || codeValueRef.current.length !== 6}
                                type='number'
                                inputMode='numeric'
                                fields={6}
                                name='remote-login-code'
                                className={styles.codeInput}
                                onChange={handleCodeChange}
                                {...codeInputProps}
                            />
                            {/* error code input */}
                            <ResetLink entityId={props.entityId} entityType={props.entityType} />
                            <VerifyCodeButton codeValidityUpdatedPubSub={codeValidityUpdatedPubSub} codeRef={codeValueRef} />
                        </>
                }
            </>
        </GenericPopup>
    )
}

export default PopupAdminRemoteLogin
