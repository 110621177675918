import { useLocation, useNavigate } from "react-router";
import { Card, CardActionArea } from "@mui/material";

import { ArchiveItemInterface } from "../consts/interfaces/ArchiveEvents.interface";
import { OUT_OF, TICKETS, WERE_ORDERED } from '../consts/hebrew';
import '../style/archiveItem.scss';

const ArchiveItem: React.FC<ArchiveItemInterface> = ({ eventName, day, date, time, tickets, ticketsPurchased, isAgency, eventId }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleEventClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        navigate(`${location.pathname}/${eventId}`);
    }

    return (
        <Card
            className="archive-item-box"
            variant="outlined"
            onClick={isAgency ? handleEventClick : undefined}
            sx={{
                direction: 'rtl',
                '.MuiCardActionArea-root': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '10vh',
                    fontSize: '1.1vw',
                },
            }}
        >
            <CardActionArea disabled={!isAgency}>
                <div className='beggining'>
                    <div className='date'>{day} | {date} | {time}</div>
                    <div className='artist'>{eventName}</div>
                </div>
                <div className='amount'>
                    {!isAgency && < div className='ticket'><img src='/icons-admin/emptyTicket.svg' alt='ticket' /></div>}
                    {isAgency ?
                        `${WERE_ORDERED} ${ticketsPurchased} ${TICKETS} ${OUT_OF} ${tickets}`
                        :
                        `${WERE_ORDERED} ${tickets} ${TICKETS} `
                    }
                </div>
                {isAgency && <div className='arrow'><img src='/icons-admin/big-arrow.png' alt='arrow' /></div>}
            </CardActionArea>
        </Card>
    )
}
export default ArchiveItem;