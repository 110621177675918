import GenericButton from "../../generic-components/GenericButton";

import clsx from "clsx";
import styles from './remoteLoginButton.module.scss';

interface RemoteLoginButtonProps {
  onClick(): void;
  entityType: string;
}
export function RemoteLoginButton(props: RemoteLoginButtonProps) {
  
  const systemTypeHebrew = props.entityType === 'organization' ? 'העמותה' : 'המוסד' 

  return (
    <GenericButton
      className={clsx('yellow', styles.floatingButton, styles.labelWithOffset)}
      handleClick={props.onClick}
    >
      כניסה לחשבון {systemTypeHebrew}
    </GenericButton>
  )
}
