import { useContext } from "react"
import { SystemTypeContext, systemTypeContextProps } from "../context/SystemTypeContext"
import { SystemProfileImageContext } from "../context/SystemProfileContexts"
import { useSystemInfo } from "../generic-components/GenericSystemProfile"
import AcceptedTableCell from "./AcceptedTableCell"
import { SystemProfileImageCx } from "../consts/interfaces/SystemProfileContexts.interface"
import { PROFILE } from "../consts/variables"

const RequestMiniNavbar: React.FC = () => {
    const { systemNameCx } = useContext(SystemProfileImageContext) as SystemProfileImageCx
    const { systemType } = useContext(SystemTypeContext) as systemTypeContextProps
    const { systemId } = useSystemInfo()

    return (
        <div className="navbar-profile">
            <div className="pro-name">
                <div className="actual-name">{PROFILE.PROFILE}</div>
                <hr className='no-little' />
                <AcceptedTableCell id={systemId} organizationOrAgency={systemType} name={systemNameCx}/>
            </div>
        </div>
    )
}
export default RequestMiniNavbar