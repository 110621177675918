//requests is the page where u see any orgs that have requested to sign up or agencies 
import { useContext, useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { OrganizationAddedSocket } from "../consts/interfaces/OrganizationSocket.interface";
import { SYSTEM_TYPE } from "../consts/interfaces/SpecificStatistics.interface";
import { AgencyAddedSocket } from "../consts/interfaces/AgencySocket.interface";
import TableDetails from "../consts/interfaces/TableDetails.interface";
import { REQUEST, _ERROR_MESSAGE } from "../consts/variables";
import { SYSTEM_FILTER_KEYS } from "../consts/arrays";

import { SystemTypeContext, systemTypeContextProps } from "../context/SystemTypeContext";
import { ErrorContext } from "../context/ErrorContext";
import { useRequestNumber } from "../context/RequestNumberContext";
import GenericTable from "../generic-components/GenericTable";
import GenericSearchBar from "../generic-components/GenericSearchBar";
import { useSocketListener } from '../hooks/socket';
import '../style/requests.scss'


const Requests: React.FC = () => {
    const { systemType } = useContext(SystemTypeContext) as systemTypeContextProps
    const { requestNumber } = useRequestNumber(); // shows the amount of orgs/agencies requested
    const { showError } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<TableDetails>>([]);
    const [initTableData, setInitTableData] = useState<TableDetails[]>([]) //initial table data for search bar 

    //requests from database all minimal data about agencies or orgs trying to signup
    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get(`/api/${systemType}/request-minimal`);
            const systemNew: TableDetails[] = data.map((item: any) => {
                return {
                    systemName: item.typeName,
                    contactName: item.contactName,
                    email: item.email,
                    picture: item.picture,
                    contactPhone: item.contactPhone,
                    id: item.id
                }
            })
            setInitTableData(systemNew);
            setTableData(systemNew);
            setIsLoading(false);
        } catch (err) {
            showError(_ERROR_MESSAGE);
        }
    }, [requestNumber]);

    //updates details when a new agency or organization is added
    const handleNewSystem = (addedSystem: OrganizationAddedSocket | AgencyAddedSocket) => {
        if (!addedSystem) return;
        const isAgency = 'agencyName' in addedSystem;
        const isOrganization = 'organizationName' in addedSystem;

        const canProceedTruthTable: Record<typeof systemType, boolean> = {
            'agency': isAgency,
            'organization': isOrganization,
        };
        if (!canProceedTruthTable[systemType]) return;

        const newSystem: TableDetails = {
            picture: addedSystem.profilePhoto,
            systemName: isAgency ? addedSystem.agencyName : addedSystem.systemName,
            contactName: addedSystem.contacts[0].name,
            contactPhone: addedSystem.contacts[0].phone,
            email: addedSystem.contacts[0].email,
            id: addedSystem.id,
            activityStatus: true,
            imageCoordinates: addedSystem.imageCoordinates,
        };
        AddSystem(newSystem);
    }
    useSocketListener('agency-added', handleNewSystem);
    useSocketListener('organization-added', handleNewSystem);

    const AddSystem = (newSystem: TableDetails) => {
        setInitTableData(prev => [...prev, newSystem]);
        setTableData(prev => [...prev, newSystem]);
    }

    return (
        <div>
            {isLoading || initTableData?.length !== 0 ?
                <div className="wrapper-list">
                    <GenericSearchBar keysToSearch={SYSTEM_FILTER_KEYS} setFilteredArr={setTableData} initialOptions={initTableData} />
                    {isLoading || tableData?.length !== 0 ?
                        <GenericTable setInit={setInitTableData} data={tableData ? tableData : []} accepted={true} setTableData={setTableData} isLoading={isLoading} />
                        : <div className="no-results">{systemType === SYSTEM_TYPE.agency ? REQUEST.NO_AGENCY_REQUESTS_WITH_THIS_NAME : REQUEST.NO_ORG_REQUEST_WITH_THIS_NAME}</div>
                    }
                </div>
                : <div className="no-results">{systemType === SYSTEM_TYPE.agency ? REQUEST.NO_REQUESTS_OF_AGENCY : REQUEST.NO_REQUESTS_OF_ORG}</div>
            }
        </div >
    )
}
export default Requests;