import { useEffect, useState } from "react";
import { EXTRA_TIME, OUT_OF_ALL_OFFERS, TIMES_USERS_CLICKED_ADD_TIME, TO_EVENTS } from "../consts/hebrew";
import { PIE_TYPES } from "../consts/variables";

import GenericPieGraph from "../generic-components/GenericPieGraph";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";
import '../style/extraTimeClicks.scss';

const ExtraTimeClicks: React.FC<{ extraTimeInfo: { clicks: number, offers: number } }> = ({ extraTimeInfo }) => {
    const [clicks, setClicks] = useState<number>(0);
    const [offers, setOffers] = useState<number>(1);
    const [noData, setNoData] = useState<boolean>()

    useEffect(() => {
        setClicks(extraTimeInfo.clicks)
        setOffers(extraTimeInfo.offers)
        setNoData(extraTimeInfo.offers === 0)
    }, [extraTimeInfo])

    return (
        <WhiteBoxWrapper>
            <div style={{ width: '23vw', marginRight: 0 }}>
                <div className="stats-title">{EXTRA_TIME}</div>
                <div className="clicks-stats-container">
                    <div className="clicks-count">
                        <div className="number">{clicks}</div>
                        <div>{TIMES_USERS_CLICKED_ADD_TIME}</div>
                    </div>
                    <GenericPieGraph
                        data={[{ value: offers, color: '#E4F1F0' }, { value: clicks, color: '#95C1CE' }]}
                        type={PIE_TYPES.ONE_SLICE}
                        firstLineMiddle={`${Math.round(clicks / offers * 100)}%`}
                        secondLineMiddle={noData ? undefined : OUT_OF_ALL_OFFERS}
                        thirdLineMiddle={noData ? undefined : TO_EVENTS}
                        noData={noData}
                        style={{ width: '22vw', height: '22vh' }}
                    />
                </div>
            </div>
        </WhiteBoxWrapper>
    )
}

export default ExtraTimeClicks;