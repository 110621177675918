import { CircularProgress } from "@mui/material";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import FileActions from "./FileActions";
import { File } from "../consts/interfaces/SystemProfileContexts.interface"
import { ERROR_LOADING_FILE } from "../consts/hebrew";

const PreviewFilePDF: React.FC<{ file: File }> = ({ file }) => {
    const { url } = file
    return (
        <div className="wrapper-file">
            <div className="pdf-box">
                <Document className='pdf-item'
                    error={<div className="error-file">{ERROR_LOADING_FILE}</div>}
                    loading={<div className="error-file"><CircularProgress color="inherit" /></div>}
                    file={url}>
                    <Page pageIndex={0} renderTextLayer={false} />
                </Document>
                <FileActions file={file} />
            </div>
        </div>
    )
}
export default PreviewFilePDF