import React from "react";
import clsx from "clsx";
import '../style/downloadButton.scss';

interface ButtonProps {
  onClick: () => void;
  loading: boolean;
}

const DownloadButton: React.FC<ButtonProps> = ({ onClick, loading }) => {
  return (
    <button className="shadow-button" onClick={onClick}>
      <div className="button-content">
        <div className="text">הורדת דו"ח</div>
        <div className={clsx("download-icon", { "loading": loading })}>
          <img src="/icons-admin/download-arrow.svg" className="arrow" alt="" />
          <img src="/icons-admin/download-line.svg" className="line" alt="" />
        </div>
      </div>
    </button >
  );
};

export default DownloadButton;

