import { useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { AGENCIES_CANCELED_EVENTS } from "../consts/hebrew";
import GenericTextNumberRow from "../generic-components/GenericTextNumberRow";
import '../style/agenciesCanceledEvents.scss';


const AgenciesCanceledEvents = () => {
    const [data, setData] = useState<{ text: string, number: number }[]>([])

    useAsyncEffect(async () => {
        const data: { agencyName: string, count: number }[] = (await axios.get('/api/statistics/canceled-events-agencies')).data

        const formattedData = data.map(({ agencyName, count }) => ({ text: agencyName, number: count }))
        setData(formattedData)
    }, [])

    return (
        <div className="agencies-canceled-events-container">
            {data.length !== 0 &&
                <div>
                    <div className="title">{AGENCIES_CANCELED_EVENTS}</div>
                    {data.map(row =>
                        <GenericTextNumberRow text={row.text} number={row.number} />
                    )}
                </div>}
        </div>
    )
}

export default AgenciesCanceledEvents;