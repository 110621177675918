import { useMemo } from "react"
import ArrowSeparatedValues from "../generic-components/ArrowSeparatedValues"
import { NUM_OF_DONATED_TICKETS, NUM_OF_PURCHASED_TICKETS } from "../consts/hebrew"

interface ticketStatusTypesCountProps {
    donatedTicketCount: number,
    purchasedTicketsCount: number,
}

const TicketStatusTypesCountArrows: React.FC<ticketStatusTypesCountProps> = ({ donatedTicketCount, purchasedTicketsCount }) => {
    const ticketStatusTypes = useMemo(() => {
        return (
            [{
                number: donatedTicketCount,
                title: NUM_OF_DONATED_TICKETS
            },
            {
                number: purchasedTicketsCount,
                title: NUM_OF_PURCHASED_TICKETS
            }]
        )
    }, [donatedTicketCount, purchasedTicketsCount])

    return (
        <ArrowSeparatedValues
            arrayOfItems={ticketStatusTypes}
            style={{ width: '32vw' }}
            arrowSize={'big'}
        />
    )
}

export default TicketStatusTypesCountArrows;