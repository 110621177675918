import { useContext, useMemo } from "react";
import { NUM_OF_GIVING_AGENCIES, NUM_OF_RECEIVING_ORGANIZATIONS } from "../consts/hebrew";
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";

import ArrowSeparatedValues from "../generic-components/ArrowSeparatedValues";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";
import { StatisticsContext } from "../context/StatisticsContext";

const DonatingAgenciesReceivingOrgs: React.FC = () => {
    const { donatingReceivingCount } = useContext(StatisticsContext) as StatContextInterface;

    const donatingReceivingCountArray = useMemo(() => {
        return (
            [{
                number: donatingReceivingCount.donatingAgencies || 0,
                title: NUM_OF_GIVING_AGENCIES
            },
            {
                number: donatingReceivingCount.receivingOrganizations || 0,
                title: NUM_OF_RECEIVING_ORGANIZATIONS
            }]
        )
    }, [donatingReceivingCount])

    return (
        <WhiteBoxWrapper>
            <ArrowSeparatedValues
                arrayOfItems={donatingReceivingCountArray}
                style={{ width: '35vw', height: '20vh' }}
            />
        </WhiteBoxWrapper>
    )
}

export default DonatingAgenciesReceivingOrgs;