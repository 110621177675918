import { useEffect, useRef } from "react";

export type PubSub<T> = {
    subscribe(handler: (data: T) => void): void;
    unsubscribe(handler: (data: T) => void): void;
    publish(data: T): void;
}
export function usePubSubRegistry<T>(allowRepetitivePayload = true): PubSub<T> {
    const subscribers = useRef<Array<(data: T) => void>>([]);
    const lastSentPayload = useRef<T | Symbol>(Symbol());

    const isPayloadRepetitive = (payload: T) => !allowRepetitivePayload && lastSentPayload.current === payload;

    const subscribe = (handler: (data: T) => void) => subscribers.current.push(handler);
    const unsubscribe = (handler: (data: T) => void) => subscribers.current = subscribers.current.filter(h => h !== handler);
    const publish = (data: T) => {
        if (isPayloadRepetitive(data)) return;
        subscribers.current.forEach(handler => handler(data));
    }

    return { subscribe, unsubscribe, publish };
}

export function usePubSubHandler<T>(pubSub: PubSub<T>, handler: (data: T) => void) {
    useEffect(() => {
        pubSub.subscribe(handler);

        return () => pubSub.unsubscribe(handler);
    }, [handler, pubSub]);
}