import React, { useState } from "react";
import { IconButton, TableCell } from "@mui/material";

import { AcceptedTableCellProps } from "../consts/interfaces/TableCells.interface";
import { PopupRejectOrAcceptSystem } from "./popups/PopupRejectOrAcceptSystem";
import { selectedSystemInfo } from "../consts/interfaces/selectedSystem.interface";

const AcceptedTableCell: React.FC<AcceptedTableCellProps> = (props) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedSystem, setSelectedSystem] = useState<selectedSystemInfo>({ id: 0, name: '', systemType: '', isAccepted: true })

    const handleIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, approveClicked: boolean) => {
        e.stopPropagation();
        if (props.handleClickOnAcceptOrReject) {      //in the requests page
            props.handleClickOnAcceptOrReject(props.id, props.name, props.organizationOrAgency, approveClicked)
        }
        else {       //in the system's profile page
            setSelectedSystem({ id: props.id, name: props.name, systemType: props.organizationOrAgency, isAccepted: approveClicked })
            setPopupOpen(true)
        }
    }

    return (
        <>
            <TableCell align="center" style={{ borderBottom: props.inRequest === true ? "none" : '1px solid rgba(224, 224, 224, 1)', marginBottom: props.inRequest === true ? '-2vh' : '0vh' }}>
                <div style={{ display: "flex", height: "4.5vh", justifyContent: "center", alignItems: "center", flexDirection: props.inRequest ? "row-reverse" : "row" }}>
                    <IconButton onClick={(e) => handleIconClick(e, false)}>
                        <img src="/icons-admin/cancel.svg" style={{ height: "4vh" }} alt="deny" />
                    </IconButton>
                    <IconButton sx={{ width: "3.3vw" }} onClick={(e) => handleIconClick(e, true)}>
                        <img src="/icons-admin/check.svg" style={{ height: "4vh" }} alt="accept" />
                    </IconButton>
                </div>
            </TableCell>
            {props.handleClickOnAcceptOrReject ?
                undefined :
                <PopupRejectOrAcceptSystem isOpen={popupOpen} setIsOpen={setPopupOpen} selectedSystem={selectedSystem} />
            }
        </>
    );
}

export default AcceptedTableCell;