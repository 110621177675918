//this component returns an org or agency profile page header(only name and profile pic with the mini nav bar)
import { useContext, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router";
import { useOutletContext } from "react-router-dom";
import clsx from "clsx";

import { ContextType, ProfileAgencyInterface, ProfileOrganizationInterface } from "../consts/interfaces/ProfileProps.interface";
import { OrganizationEditedSocket } from "../consts/interfaces/OrganizationSocket.interface";
import { NAVBAR_OPTIONS } from "../consts/interfaces/HeaderSystemProfile.interface";
import { AgencyEditedSocket } from '../consts/interfaces/AgencySocket.interface';
import { SYSTEM_TYPE } from "../consts/interfaces/SpecificStatistics.interface";
import { SystemTypeContext, systemTypeContextProps } from "../context/SystemTypeContext";
import { useSocketListener } from '../hooks/socket';

import HeaderSystemProfile from "../components/HeaderSystemProfile";

import '../style/genericSystemProfile.scss';

const GenericSystemProfile: React.FC<{ pageType: NAVBAR_OPTIONS }> = ({ pageType }) => {
    const { systemType } = useContext(SystemTypeContext) as systemTypeContextProps
    const location = useLocation()
    const params = useParams();

    const [systemDetails, setSystemDetails] = useState<ProfileOrganizationInterface | ProfileAgencyInterface | null>(null)

    useSocketListener('organization-edited', (organizationEdited: OrganizationEditedSocket) => {
        if (organizationEdited && systemType === SYSTEM_TYPE.organization && organizationEdited.id === systemDetails?.id) {
            setSystemDetails(organizationEdited);
        }
    });

    useSocketListener('agency-edited', (agencyEdited: AgencyEditedSocket) => {
        if (agencyEdited && systemType === SYSTEM_TYPE.agency && agencyEdited.id === systemDetails?.id) {
            setSystemDetails(agencyEdited);
        }
    });

    return (
        params?.eventId ? <Outlet /> :
            <div className={clsx('other-pages', { 'profile-page': (location.pathname.split('/').length === 4) })}>
                <HeaderSystemProfile pageType={pageType} />
                <Outlet />
            </div>
    )
}
export default GenericSystemProfile;

export function useSystemInfo() {
    return useOutletContext<ContextType>();
}