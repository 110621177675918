import { useQuery } from "@tanstack/react-query";
import { fetchNumberOfPendingApprovalRequests } from "../../utils/api/pending-entity-approval-requests";
import { UseInlineQueryOptions } from "./react-query";

export function getQueryKeyForNumberOfPendingApprovalRequests(entityType: string) {
    return ['approval-requests', entityType, 'count'];
}
export function useNumberOfPendingApprovalRequestsQuery(
    entityType: string,
    options?: UseInlineQueryOptions<number>,
) {
    return useQuery(
        getQueryKeyForNumberOfPendingApprovalRequests(entityType),
        () => fetchNumberOfPendingApprovalRequests(entityType),
        options,
    );
}
