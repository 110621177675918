import { useContext, useMemo, useState } from "react";
import { useLocation } from 'react-router';
import axios from 'axios';
import { useAsyncEffect } from '@hilma/tools';
import 'moment/locale/he';

import { EventEditedSocket, EventOfferedSocket, TicketPurchasedSocket } from "../consts/interfaces/EventSocket.interface";
import { AgencyEvent, MinimalAgencyEvent, MinimalOrgEvent, OrgEvent } from "../consts/interfaces/MinimalEvent.interface";
import { ArchiveEventsProps } from '../consts/interfaces/ArchiveEvents.interface';
import { EVENTS_FILTER_KEYS } from "../consts/arrays";
import { _ERROR_MESSAGE } from "../consts/variables";
import { NO_PAST_EVENTS } from '../consts/hebrew';

import GenericSearchBar from "../generic-components/GenericSearchBar";
import { eventToDismantledDate } from '../functions/dateFunctions';
import { ErrorContext } from "../context/ErrorContext";

import EventsArchiveSkeleton from './EventsArchiveSkeleton';
import { useSocketListener } from '../hooks/socket';
import ArchiveItem from "./ArchiveItem";

import '../style/archiveEvents.scss';

const ArchiveEvents: React.FC<ArchiveEventsProps> = ({ isAgency }) => {
    const [initialAgencyEvents, setInitialAgencyEvents] = useState<MinimalAgencyEvent[]>([]);
    const [filteredAgencyEvent, setFilteredAgencyEvents] = useState<MinimalAgencyEvent[]>([]);

    const [filteredOrgEvents, setFilteredOrgEvents] = useState<Array<MinimalOrgEvent>>([]);
    const [initialOrgEvents, setInitialOrgEvents] = useState<MinimalOrgEvent[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { showError } = useContext(ErrorContext);
    const location = useLocation()

    const id = useMemo(() => {
        return location.pathname.split('/')[3];
    }, [location]);

    useAsyncEffect(async () => {
        try {
            if (isAgency) {
                const agencyEventsData: AgencyEvent[] = (await axios.get(`/api/agency/agency-events?agencyId=${id}`)).data;

                const eventsDetails: MinimalAgencyEvent[] = agencyEventsData.map((event) => {
                    const { day, time, date } = eventToDismantledDate(event.date)

                    return {
                        ...event,
                        day, time, date,
                        tickets: event.tickets.length,
                        ticketsPurchased: event.tickets.filter(ticket => ticket.status === 'purchased').length
                    }
                })

                setInitialAgencyEvents(eventsDetails);
                setIsLoading(false);

            } else {
                const orgEventsServer: OrgEvent[] = (await axios.get(`/api/organization/get-org-purchased-events?orgId=${id}`)).data;
                const eventsDetails: MinimalOrgEvent[] = orgEventsServer.map(event => {
                    const { day, time, date } = eventToDismantledDate(event.date)
                    return ({
                        ...event,
                        day, time, date,
                        tickets: event.ticketsPurchasedByOrg,
                    })
                })

                setInitialOrgEvents(eventsDetails);
                setIsLoading(false);
            }
        } catch (err) {
            showError(_ERROR_MESSAGE);
        }
    }, [id]);

    useSocketListener('event-added', (eventAdded: EventOfferedSocket) => {
        if (eventAdded && isAgency) {
            const { day, time, date } = eventToDismantledDate(eventAdded.date);
            const newEvent: MinimalAgencyEvent = {
                ...eventAdded,
                day,
                date,
                time,
                tickets: eventAdded.ticketNum,
                ticketsPurchased: 0,
            };
            setInitialAgencyEvents(prev => [...prev, newEvent]);
        }
    });


    useSocketListener('ticket-purchased', (ticketPurchased: TicketPurchasedSocket) => {
        if (ticketPurchased) {
            if (isAgency) {
                setInitialAgencyEvents((prev) =>
                    prev.map((event) =>
                        event.id === ticketPurchased.eventId
                            ? {
                                ...event,
                                ticketsPurchased: event.ticketsPurchased + ticketPurchased.amountPurchased
                            }
                            : event
                    )
                );
            } else {
                const { day, time, date } = eventToDismantledDate(ticketPurchased.date);

                const newEvent: MinimalOrgEvent = {
                    ...ticketPurchased,
                    id: ticketPurchased.eventId,
                    day,
                    date,
                    time,
                    tickets: ticketPurchased.amountPurchased
                };

                setInitialOrgEvents((prev) => [...prev, newEvent]);
            }
        }
    });

    useSocketListener('event-edited', (eventEdited: EventEditedSocket) => {
        if (eventEdited) {
            if (isAgency && filteredAgencyEvent) {
                setInitialAgencyEvents((prev) => {
                    const eventIndex = prev.findIndex((e) => e.id === eventEdited.id);
                    const copyPrev = [...prev];
                    copyPrev[eventIndex].eventName = eventEdited.eventName;
                    return copyPrev;
                });
            } else if (filteredOrgEvents) {
                setInitialOrgEvents((prev) => {
                    const eventIndex = prev.findIndex((e) => e.id === eventEdited.id);
                    const copyPrev = [...prev];
                    copyPrev[eventIndex].eventName = eventEdited.eventName;
                    return copyPrev;
                });
            }
        }
    });


    useSocketListener('remove-event', (eventId: number) => {
        if (eventId) {
            if (isAgency) {
                setInitialAgencyEvents((prev) => prev.filter((event) => event.id !== eventId));
            } else {
                setInitialOrgEvents((prev) => prev.filter((event) => event.id !== eventId));
            }
        }
    });


    return (
        <div>
            {isAgency ?
                filteredAgencyEvent && !isLoading ?
                    <div className='archive-events-page'>
                        <GenericSearchBar keysToSearch={EVENTS_FILTER_KEYS} setFilteredArr={setFilteredAgencyEvents} initialOptions={initialAgencyEvents} className="archive-list" />
                        {filteredAgencyEvent.length > 0 ?
                            <div className='archive-items'>
                                {filteredAgencyEvent.map((item, index) =>
                                    <ArchiveItem
                                        key={index}
                                        {...item}
                                        isAgency={true}
                                        eventId={item.id}
                                    />
                                )}
                            </div>
                            :
                            <div className="no-events-title">{NO_PAST_EVENTS}</div>
                        }
                    </div>
                    :
                    <EventsArchiveSkeleton />
                :
                filteredOrgEvents && !isLoading ?
                    <div className='archive-events-page'>
                        <GenericSearchBar keysToSearch={EVENTS_FILTER_KEYS} setFilteredArr={setFilteredOrgEvents} initialOptions={initialOrgEvents} className="archive-list" />
                        {filteredOrgEvents.length > 0 ?
                            <div className='archive-items'>
                                {filteredOrgEvents.map((item, index) =>
                                    <ArchiveItem
                                        key={index}
                                        {...item}
                                        isAgency={false}
                                        eventId={item.id}
                                    />
                                )}
                            </div>
                            :
                            <div className="no-events-title">{NO_PAST_EVENTS}</div>
                        }
                    </div>
                    :
                    <EventsArchiveSkeleton />
            }
        </div>
    )
}
export default ArchiveEvents;