import { FilesUploader } from '@hilma/fileshandler-client';
import { useMutation, useQuery } from "@tanstack/react-query";
import { SYSTEM_TYPE } from "../../consts/interfaces/SpecificStatistics.interface";
import { AgencyEditAbleFields, OrganizationEditAbleFields } from "../../consts/interfaces/SystemProfile.interface";
import { editAgencyProfile, editOrganizationProfile, fetchAgencyProfile, fetchOrganizationProfile } from "../../utils/api/system-profile";

export function getQueryKeyForSystemProfile(systemType: SYSTEM_TYPE, systemId: number) {
    return ['system', systemType, systemId];
};

export function useAgencyProfileQuery(agencyId: number) {
    return useQuery(
        getQueryKeyForSystemProfile(SYSTEM_TYPE.agency, agencyId),
        () => fetchAgencyProfile(agencyId),
    )
};

export function useOrganizationProfileQuery(organizationId: number) {
    return useQuery(
        getQueryKeyForSystemProfile(SYSTEM_TYPE.organization, organizationId),
        () => fetchOrganizationProfile(organizationId),
    )
};

export function useEditAgency(agencyId: number, filesUploader: FilesUploader) {
    return useMutation({
        mutationFn: (data: AgencyEditAbleFields) => editAgencyProfile(agencyId, data, filesUploader),
    })
}

export function useEditOrganization(organizationId: number, filesUploader: FilesUploader) {
    return useMutation({
        mutationFn: (data: OrganizationEditAbleFields) => editOrganizationProfile(organizationId, data, filesUploader),
    })
}
