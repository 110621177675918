import { Skeleton } from "@mui/material";

const EventsArchiveSkeleton: React.FC = () => {

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "2vh", width: "100%", boxSizing: "border-box" }}>
            <Skeleton variant="rectangular" width={"20vw"} height={"5vh"} sx={{ mb: "2vh", backgroundColor: "rgba(207, 207, 207, 0.4)" }} />
            <Skeleton variant="rectangular" height={"10vh"} sx={{ mb: "2vh", backgroundColor: "rgba(207, 207, 207, 0.4)" }} />
            <Skeleton variant="rectangular" height={"10vh"} sx={{ mb: "2vh", backgroundColor: "rgba(207, 207, 207, 0.4)" }} />
            <Skeleton variant="rectangular" height={"10vh"} sx={{ mb: "2vh", backgroundColor: "rgba(207, 207, 207, 0.4)" }} />
            <Skeleton variant="rectangular" height={"10vh"} sx={{ mb: "2vh", backgroundColor: "rgba(207, 207, 207, 0.4)" }} />
        </div>
    )
}

export default EventsArchiveSkeleton;