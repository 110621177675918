import { useContext, useEffect, useState } from "react";

import { EVENT_NUMBER, TICKET_NUMBER, TOP_RECEIVING_ORGANIZATIONS } from "../consts/hebrew";
import { BarGraphData } from "../consts/interfaces/GenericGraphBar.interface";
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";
import { StatisticsContext } from "../context/StatisticsContext";

import GenericBarGraph from "../generic-components/GenericBarGraph";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";

const TopDonatedOrgsGraph: React.FC = () => {
    const { top5DonatedOrgs } = useContext(StatisticsContext) as StatContextInterface
    const [data, setData] = useState<BarGraphData[]>([])

    useEffect(() => {
        const formattedData: BarGraphData[] = top5DonatedOrgs.map(organization => ({
            name: organization.organizationName,
            bar1Value: organization.ticketCount,
            bar2Value: organization.eventCount
        }))

        setData(formattedData)
    }, [top5DonatedOrgs])

    return (
        <WhiteBoxWrapper>
            <div className="stats-title">{TOP_RECEIVING_ORGANIZATIONS}</div>
            <GenericBarGraph data={data} bar1={TICKET_NUMBER} bar2={EVENT_NUMBER} style={{ width: '29vw', height: '40vh', marginTop: '3vh' }} />
        </WhiteBoxWrapper>
    )
}

export default TopDonatedOrgsGraph;