import React, { useMemo } from "react";
import { AgencyAddressCx, OrgConfigCx, OrgFilesCx, SystemContactsCx, EditSystemProfileCx, SystemProfileImageCx, SystemTermCx } from "../consts/interfaces/SystemProfileContexts.interface";
import { OrgsFilesProviderProps, } from "../consts/interfaces/systemProfileProviders.interface";

export const EditSystemProfileContext = React.createContext<EditSystemProfileCx | null>(null)

export const SystemProfileImageContext = React.createContext<SystemProfileImageCx | null>(null);

export const SystemTermContext = React.createContext<SystemTermCx | null>(null);

export const SystemContactsContext = React.createContext<SystemContactsCx | null>(null);

export const AgencyAddressContext = React.createContext<AgencyAddressCx | null>(null);

export const OrgConfigurationContext = React.createContext<OrgConfigCx | null>(null);

export const OrgFilesContext = React.createContext<OrgFilesCx | null>(null);
export const OrgFilesProvider: React.FC<OrgsFilesProviderProps> = ({ children,properManagement, expiration }) => {
    const filesCx = useMemo(() => {
        return ([{
            downloadName: 'properManagement.pdf',
            titleView: 'properManagement',
            type: 'properManagement',
            url: properManagement,
            expirationDate: expiration
        }])
    }, [ properManagement, expiration])

    return (
        <OrgFilesContext.Provider value={{
            filesCx
        }}>
            {children}
        </OrgFilesContext.Provider>
    )
};