import { useEffect, useState } from "react"
import ItemInSystemProfile from "./ItemInSystemProfile"
import { TermOfSystemProps } from "../consts/interfaces/TermOfSystem.interface"
import { PROFILE } from "../consts/variables"

const TermOfSystem: React.FC<TermOfSystemProps> = ({ termNameCx, termDateCx, isEdit }) => {
    const [termName, setTermName] = useState<string>(termNameCx)
    const [termDate, setTermDate] = useState<string>(termDateCx)

    return (
        <>
            <ItemInSystemProfile name={PROFILE.TERMS_NAME} data={termName} setData={setTermName} isEdit={isEdit} />
            <ItemInSystemProfile name={PROFILE.TERMS_DATE} data={termDate} setData={setTermDate} isEdit={isEdit} />
        </>
    )
}
export default TermOfSystem