import axios from "axios"

export const receivedOrganizations = async (systemId: string) => {
    return (await axios.get(`/api/statistics/received-from-specific?agencyId=${systemId}`)).data
}

export const ticketsDivisionInfo = async (systemId: string, filterDate?: Date) => {
    return (await axios.get(`/api/statistics/tickets-utilization?filterDate=${filterDate || ''}&agencyId=${systemId}`)).data
}

export const worthOfDonationInfo = async (systemId: string, filterDate?: Date) => {
    return (await axios.get(`/api/statistics/total-donation-worth?filterDate=${filterDate || ''}&agencyId=${systemId}`)).data
}

export const postToPurchaseInfo = async (systemId: string, filterDate?: Date) => {
    return (await axios.get(`/api/statistics/average-time-from-post-to-purchase?filterDate=${filterDate || ''}&agencyId=${systemId}`)).data
}

export const eventsOccurrenceInfo = async (systemId: string) => {
    return (await axios.get(`/api/statistics/events-occurrence?agencyId=${systemId}`)).data
}

export const getThankYouFeedbacksForAgencyInfo = async (systemId: string) => {
    return (await axios.get(`/api/statistics/thank-you-feedbacks-for-agency?agencyId=${systemId}`)).data
}