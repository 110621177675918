import React from "react";
import { SYSTEM_TYPE } from "../consts/interfaces/SpecificStatistics.interface";

export interface systemTypeContextProps {
    systemType: SYSTEM_TYPE
}

export const SystemTypeContext = React.createContext<systemTypeContextProps | null>(null);

export const SystemTypeProvider: React.FC<{ systemType: SYSTEM_TYPE }> = ({ children, systemType }) => {
    return (
        <SystemTypeContext.Provider value={{
            systemType
        }}>
            {children}
        </SystemTypeContext.Provider>
    )
}