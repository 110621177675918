import { useContext, useState } from 'react';
import { Skeleton } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { EVENTS, STATS_FILTER_OPTIONS, STATS_REPORT, TOTAL_GIVEN_FEEDBACKS } from '../consts/hebrew';
import { StatContextInterface } from '../consts/interfaces/StatisticsContext.interface';

import OrganizationDemographicsOrdered from '../components/OrganizationDemographicsOrdered';
import TicketsUtilizationDistribution from '../components/TicketsUtilizationDistribution';
import DonatingAgenciesReceivingOrgs from '../components/DonatingAgenciesReceivingOrgs';
import MostUserInvolved from '../components/statistics-components/MostUserInvolved';
import TopDonatingAgenciesGraph from '../components/TopDonatingAgenciesGraph';
import EventCategoriesOrdered from '../components/EventCategoriesOrdered';
import AgenciesAndOrgsCount from '../components/AgenciesAndOrgsCount';
import NotRelevantFeedbacks from '../components/NotRelevantFeedbacks';
import StatsFilterDropdown from '../components/StatsFilterDropdown';
import TopDonatedOrgsGraph from '../components/TopDonatedOrgsGraph';
import TotalFeedbacksGiven from '../components/TotalFeedbacksGiven';
import PopupThereIsRequest from '../components/popups/PopupThereIsRequest';
import TicketsUtilization from '../components/TicketsUtilization';
import TotalDonationWorth from '../components/TotalDonationWorth';
import EventsOccurrence from '../components/EventsOccurrence';
import ExtraTimeClicks from '../components/ExtraTimeClicks';
import DownloadButton from '../components/DownloadButton';
import EventTimeline from '../components/EventTimeline';
import NumEventsCard from '../components/NumEventsCard';

import { StatisticsContext } from '../context/StatisticsContext';
import WhiteBoxWrapper from '../generic-components/WhiteBoxWrapper';

import '../style/statistics.scss';


const Statistics: React.FC = () => {

  const [selectedFilter, setSelectedFilter] = useState<STATS_FILTER_OPTIONS>(STATS_FILTER_OPTIONS.ALL)
  const [reportLoading, setReportLoading] = useState<boolean>(false)
  const { countOfEvents, setFilterDateContext, ticketStatusTypesCount, extraTimeInfo, worthOfDonation, eventCategoriesOrdered, ticketUtilization, totalFeedbacks, eventsOccurrenceCount, filterDate } = useContext(StatisticsContext) as StatContextInterface
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const downloadReport = async () => {
    setReportLoading(true)
    const input = document.getElementById('statistics-report');
    if (!input) return;

    html2canvas(input, { backgroundColor: '#f6f7f5', scale: 2 })
      .then((canvas) => {
        const pdf = new jsPDF({
          unit: "px",
          format: [input.clientWidth, input.scrollHeight]
        })

        pdf.addImage(canvas, 'JPEG', 0, 0, input.clientWidth, input.scrollHeight);

        const today = (new Date()).toLocaleDateString("en-GB").replaceAll('/', '-')
        const filter = filterDate ? selectedFilter : ''

        pdf.save(`${STATS_REPORT} ${filter} ${today}.pdf`);
        setReportLoading(false)
      })
  }

  return (
    <div className='statistics-page'>
      <div className='buttons-container'>
        <DownloadButton onClick={downloadReport} loading={reportLoading} />
        <StatsFilterDropdown selected={selectedFilter} setSelected={setSelectedFilter} setFilterDateContext={setFilterDateContext} />
      </div>
      <PopupThereIsRequest />
      {isLoading ? //TODO: Remove once server is updated.
        // {!isLoading ?//TODO: Activate

        <div className="statistics-container" id='statistics-report'>
          <AgenciesAndOrgsCount />
          <NumEventsCard eventsAmount={countOfEvents} />
          <TotalDonationWorth donation={worthOfDonation} />

          <DonatingAgenciesReceivingOrgs />
          <EventsOccurrence occurredAmount={eventsOccurrenceCount.occurredAmount} canceledAmount={eventsOccurrenceCount.canceledAmount} style={{ width: '45vw' }} />

          <TicketsUtilization ticketStatusTypesCount={ticketStatusTypesCount} style={{ width: '45vw' }} />
          <TicketsUtilizationDistribution ticketUtilization={ticketUtilization} />

          <TopDonatingAgenciesGraph />
          <MostUserInvolved />
          <TopDonatedOrgsGraph />

          <EventCategoriesOrdered eventCategoriesOrdered={eventCategoriesOrdered} />
          <OrganizationDemographicsOrdered />
          <TotalFeedbacksGiven textUnder={TOTAL_GIVEN_FEEDBACKS} totalFeedbacks={totalFeedbacks} />

          <EventTimeline />

          <NotRelevantFeedbacks />
          <ExtraTimeClicks extraTimeInfo={extraTimeInfo} />
        </div>
        :
        <div className="statistics-container" id='statistics-report' style={{ display: 'flex' }}>
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<AgenciesAndOrgsCount />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<WhiteBoxWrapper><div>{EVENTS}</div></WhiteBoxWrapper>} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<TotalDonationWorth />} />

          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<DonatingAgenciesReceivingOrgs />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<EventsOccurrence canceledAmount={0} occurredAmount={0} style={{ width: '45vw' }} />} />

          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<TicketsUtilization ticketStatusTypesCount={ticketStatusTypesCount} style={{ width: '50vw' }} />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<TicketsUtilizationDistribution ticketUtilization={ticketUtilization} style={{ width: '30vw', marginRight: '0', marginTop: '-1vh' }} />} />

          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<TopDonatingAgenciesGraph />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<MostUserInvolved />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<TopDonatedOrgsGraph />} />

          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<EventCategoriesOrdered eventCategoriesOrdered={eventCategoriesOrdered} />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<OrganizationDemographicsOrdered />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<TotalFeedbacksGiven textUnder={TOTAL_GIVEN_FEEDBACKS} totalFeedbacks={totalFeedbacks} />} />

          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<EventTimeline />} />

          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<NotRelevantFeedbacks />} />
          <Skeleton variant='circular' sx={{ flex: 1, maxWidth: 'initial' }} children={<ExtraTimeClicks extraTimeInfo={extraTimeInfo} />} />
        </div>
      }
    </div >
  )
}
export default Statistics;