import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

import GenericYesNoPopup from "../../generic-components/GenericYesNoPopup";
import { useRequestNumber } from "../../context/RequestNumberContext";

import { selectedSystemInfo } from "../../consts/interfaces/selectedSystem.interface";
import GenericPopupInterface from "../../consts/interfaces/GenericPopupInterface";
import TableDetails from "../../consts/interfaces/TableDetails.interface";

interface PopupRejectOrAcceptSystemProps {
    isOpen: boolean;
    selectedSystem: selectedSystemInfo;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setTableData?: React.Dispatch<React.SetStateAction<TableDetails[]>>;
    setInit?: React.Dispatch<React.SetStateAction<TableDetails[]>>
}

export const PopupRejectOrAcceptSystem: React.FC<PopupRejectOrAcceptSystemProps> = ({ isOpen, setIsOpen, selectedSystem, setTableData, setInit }) => {
    const { id, name, systemType, isAccepted } = selectedSystem
    const { setRequestNumber } = useRequestNumber();
    const navigate = useNavigate();
    const systemTypeName = useMemo(() => systemType === 'organization' ? 'עמותת' : 'מוסד', [systemType]);

    const removeFromTable = useCallback((id: number) => {
        if (setTableData) {
            setTableData(prev => {
                return prev?.splice(prev.findIndex(item => item.id === id)) || null
            })
        }
        if (setInit) {
            setInit(prev => {
                return prev?.splice(prev.findIndex(item => item.id === id)) || null
            })
        }

        setRequestNumber((prev) => prev - 1)
    }, [])

    const handleApprove = useCallback(async (id: number) => {
        await axios.put(`/api/${systemType}/${id}/approve`);
        removeFromTable(id)
    }, [systemType, removeFromTable]);

    const handleDeny = useCallback(async (id: number) => {
        try {
            await axios.put(`/api/${systemType}/${id}/deny`);
            removeFromTable(id)
        } catch (e) {
            console.error(e);
        }
    }, [systemType, removeFromTable]);

    const popupDetails: Partial<GenericPopupInterface> = useMemo(() => {
        return {
            title: `בטוח שברצונך ${isAccepted ? 'לאשר' : 'לדחות'} את ${systemTypeName} ${name}?`,
            handleYes: () => {
                if (isAccepted) handleApprove(id);
                else handleDeny(id);
                setIsOpen(false);
                if (isAccepted) navigate(`/${systemType === 'agency' ? 'agencie' : systemType}s`);
                else navigate(`/${systemType === 'agency' ? 'agencie' : systemType}s/requests`)
            },
            handleClose: () => {
                setIsOpen(false);
            },
            type: "acceptOrDeny"
        }
    }, [systemType, name, isAccepted, id, handleApprove, handleDeny, systemTypeName]);

    return (
        <GenericYesNoPopup open={isOpen} {...popupDetails} approve={isAccepted} systemTypeName={systemTypeName} />
    )
}