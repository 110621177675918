import { useContext, useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { AgencyEditedSocket } from '../consts/interfaces/AgencySocket.interface';
import TableDetails from "../consts/interfaces/TableDetails.interface";
import { SYSTEM_FILTER_KEYS } from "../consts/arrays";
import { _ERROR_MESSAGE } from "../consts/variables";

import { updateElementById } from "../functions/array-functions";

import GenericSearchBar from "../generic-components/GenericSearchBar";
import GenericTable from "../generic-components/GenericTable";
import { ErrorContext } from "../context/ErrorContext";
import { useSocketListener } from '../hooks/socket';

const ListOfAgencies: React.FC = () => {

    const { showError } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState<boolean>(true); //for skeleton
    const [tableData, setTableData] = useState<Array<TableDetails>>([]);
    const [initTableData, setInitTableData] = useState<TableDetails[]>([])

    //gets minimal info about every agency to display in table
    useAsyncEffect(async () => {
        try {
            const agency = (await axios.get('/api/agency/agency-minimal-info')).data;
            const agencyNew: TableDetails[] = agency.map(({ agencyName, isActive, ...item }: any) => {
                return {
                    ...item,
                    systemName: agencyName,
                    activityStatus: isActive,
                }
            })
            setInitTableData(agencyNew);
            setTableData(agencyNew);
            setIsLoading(false);
        } catch (err) {
            showError(_ERROR_MESSAGE);
        }
    }, []);

    //if an agency has been edited it updates the list here with the new info
    useSocketListener('agency-edited', (agencyEdited: AgencyEditedSocket) => {
        if (agencyEdited) {
            const agencyEditedDetails = {
                contactName: agencyEdited.contacts[0].name,
                systemName: agencyEdited.agencyName,
                email: agencyEdited.contacts[0].email,
                picture: agencyEdited.profilePhoto,
                contactPhone: agencyEdited.contacts[0].phone,
                id: agencyEdited.id
            };

            setInitTableData(prev => updateElementById(prev, agencyEdited.id, agencyEditedDetails));
            setTableData(prev => updateElementById(prev, agencyEdited.id, agencyEditedDetails));
        }
    });


    return (
        <div className="wrapper-list">
            <GenericSearchBar keysToSearch={SYSTEM_FILTER_KEYS} setFilteredArr={setTableData} initialOptions={initTableData} />
            { 
               isLoading || tableData?.length !== 0 ?
                    <GenericTable data={tableData} accepted={false} isLoading={isLoading} />
                    : <div className="no-results">לא נמצאו מוסדות תרבות / ספורט מתאימים</div>
            }
        </div>
    )
}
export default ListOfAgencies;