//this component returns a generic loading of 3 dots that is used on a button 

import "../style/genericLoading.scss";

const GenericLoading: React.FC = () => {

    return (
        <div className="loading-circles-container">
            <div className="circle-1"></div>
            <div className="circle-2"></div>
            <div className="circle-3"></div>
        </div>
    )
}

export default GenericLoading;