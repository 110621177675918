import NotRelevantEventsPopupProps from "../../consts/interfaces/NotRelevantEventsPopup.interface";
import { REASONS_WHY_EVENT_NOT_RELEVANT } from "../../consts/hebrew";

import NotRelevantEvent from "../../generic-components/NotRelevantEvent";
import GenericPopup from "../../generic-components/GenericPopup";
import '../../style/notRelevantEventsPopup.scss';

const PopupNotRelevantEvents: React.FC<NotRelevantEventsPopupProps> = ({ events, open, handleClose }) => {

    return (
        <GenericPopup className="not-relevant-events-popup" title={REASONS_WHY_EVENT_NOT_RELEVANT} open={open} handleClose={handleClose}>
            <div className="events-container">
                {events.map((event, index) =>
                    <NotRelevantEvent key={index}{...event} />
                )}
            </div>
        </GenericPopup>
    )
}

export default PopupNotRelevantEvents;