import React, { useContext, useState } from "react";
import { useAsyncEffect } from "@hilma/tools";

import { ticketStatusTypesCount, eventsCategoriesOrdered, extraTimeInfo } from "../consts/interfaces/StatisticsContext.interface";
import { orgStatContext } from "../consts/interfaces/OrganizationStatisticsContnext.interface";
import { specificStatistics } from "../consts/interfaces/SpecificStatistics.interface";

import { SpecificStatisticsContext } from "./SpecificStatisticsContext";
import { eventsCategories, getExtraTime, getDonatedAndReceivedTickets } from "../utils/api/organization-statistics";

export const OrganizationStatisticsContext = React.createContext<orgStatContext | null>(null);

export const OrganizationStatisticsProvider: React.FC = ({ children }) => {
    const { systemId, filterDate } = useContext(SpecificStatisticsContext) as specificStatistics
    const [eventCategoriesOrdered, setEventsCategoriesOrdered] = useState<eventsCategoriesOrdered[]>([{
        count: 0,
        categoryName: ''
    }])
    const [extraTimeInfo, setExtraTimeInfo] = useState<extraTimeInfo>({
        clicks: 0,
        offers: 0
    })
    const [ticketStatusTypesCount, setTicketStatusTypesCount] = useState<ticketStatusTypesCount>({
        donatedTicketsCount: 0,
        purchasedTicketsCount: 0,
    })
    useAsyncEffect(async () => {
        if (systemId) {
            getEventsCategories()
            extraTime()
            donatedAndReceivedTickets()
        }
    }, [systemId, filterDate])

    //gets the 5 most common events categories ordered
    const getEventsCategories = async () => {
        const data = await eventsCategories(systemId, filterDate)
        setEventsCategoriesOrdered(data)
    }

    const extraTime = async () => {
        const data = await getExtraTime(systemId, filterDate)
        setExtraTimeInfo(data)
    }

    //gets the amount of donated and received tickets
    const donatedAndReceivedTickets = async () => {
        const ticketsCount = await getDonatedAndReceivedTickets(systemId, filterDate)
        setTicketStatusTypesCount(ticketsCount)
    }

    return (
        <OrganizationStatisticsContext.Provider value={{
            eventCategoriesOrdered,
            extraTimeInfo,
            ticketStatusTypesCount
        }}>
            {children}
        </OrganizationStatisticsContext.Provider>
    )
}