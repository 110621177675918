import { useContext } from "react";
import { IconButton } from "@mui/material"
import ProfileMiniNavbar from "./ProfileMiniNavbar";
import RequestMiniNavbar from "./RequestMiniNavbar";
import { SystemTypeContext, systemTypeContextProps } from "../context/SystemTypeContext";
import { SystemProfileImageContext } from "../context/SystemProfileContexts";
import { useNavigateToSystemPage } from "../functions/navigation";
import { SystemProfileImageCx } from "../consts/interfaces/SystemProfileContexts.interface";
import { NAVBAR_OPTIONS, navBarOptions } from "../consts/interfaces/HeaderSystemProfile.interface";

const HeaderSystemProfile: React.FC<{ pageType: NAVBAR_OPTIONS }> = ({ pageType }) => {
    const { systemType } = useContext(SystemTypeContext) as systemTypeContextProps
    const { systemNameCx } = useContext(SystemProfileImageContext) as SystemProfileImageCx
    const navigateToSystemPage = useNavigateToSystemPage(systemType)

    const navbarOptions: navBarOptions = {
        [NAVBAR_OPTIONS.request]: <RequestMiniNavbar />,
        [NAVBAR_OPTIONS.profile]: <ProfileMiniNavbar />
    }

    return (
        <>
            <div className='system-name'>
                <IconButton onClick={navigateToSystemPage} className='arrow-img'>
                    <img src='/icons-admin/arrow.svg' alt='back' />
                </IconButton>
                <div className='the-name'>{systemNameCx}</div>
            </div>
            {navbarOptions[pageType]}
        </>
    )
}
export default HeaderSystemProfile