import { useMemo } from "react";
import NotRelevantEventProps from "../consts/interfaces/NotRelevantEvent.interface";
import { eventToDismantledDate } from "../functions/dateFunctions";
import '../style/notRelevantEvent.scss';

const NotRelevantEvent: React.FC<NotRelevantEventProps> = ({ eventName, date, organizationName, organizationProfile, text }) => {

    const formattedDate = useMemo(() => eventToDismantledDate(date), [date])

    return (
        <div className="not-relevant-event">
            <span className="title">{eventName}</span>
            {formattedDate.day} | {formattedDate.date} | {formattedDate.time}

            <div className="organization-details">
                <img src={organizationProfile} alt="profile photo" />
                <div className="title">{organizationName}:</div>
                <div>{text}</div>
            </div>
        </div>
    )
}

export default NotRelevantEvent;