import clsx from 'clsx'
import { REMOTE_LOGIN_TEXTS } from '../../consts/hebrew'
import GenericButton from '../../generic-components/GenericButton'
import { useVerifyRemoteLoginCodeMutation } from '../../hooks/api/remote-login'
import styles from './verifyCodeButton.module.scss'
import { PubSub, usePubSubHandler } from '../../hooks/pubSub'
import { RefObject, useState } from 'react'

interface verifyCodeButtonProps {
    onInvalidCode?: () => void;
    codeValidityUpdatedPubSub: PubSub<boolean>;
    codeRef: RefObject<string>;
}
export function VerifyCodeButton({ onInvalidCode, codeValidityUpdatedPubSub, codeRef }: verifyCodeButtonProps) {
    const verifyCode = useVerifyRemoteLoginCodeMutation();
    const [isValidCode, setIsValidCode] = useState(false);

    const submitCode = () => {
        const code = codeRef.current;
        if (!code) return;

        verifyCode.mutateAsync(code, {
            onSuccess(response) {
                window.open(response.exchangeUrl, "_blank");
            },
            onError() {
                codeValidityUpdatedPubSub.publish(false);

            }
        });
    }

    usePubSubHandler(codeValidityUpdatedPubSub, (isValid) => {
        setIsValidCode(isValid);
        // if (isValid) return submitCode(); //TODO: enable this for sending the code when the user enter the last digit
    });

    return (
        <GenericButton disabled={!isValidCode} className={clsx('turquoise', styles.verifyCodeButton)} handleClick={submitCode}>{REMOTE_LOGIN_TEXTS.SEND_CODE_TEXT_BUTTON}</GenericButton>
    )
}
