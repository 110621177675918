import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import GenericInputProps from "../consts/interfaces/GenericInputProps.interface";
import "../style/genericInput.scss";

const GenericInput: React.FC<GenericInputProps> = (props) => {
    const Error = props.error;
    const OnBlur = props.onBlur;
    const Name = props.name;
    const OnChange = props.onChange;
    const Value = props.value;
    const isDisabled = props.disabled

    //state for changing eye from disabled to seen
    const [eye, setEye] = useState(false);
    const [eyeSrc, setEyeSrc] = useState('icons-admin/open-eye.svg');

    // let [validationReturn, setValidationReturn] = useState(true)
    const [value, setValue] = useState("");
    const [error, setError] = useState("");

    //sets valodationreturn as true/false depends on whether the value is ok or not. 
    useEffect(() => {
        setValue(Value || "");
    }, [Value]);

    useEffect(() => {
        setError(Error ? Error : "");
    }, [Error]);

    const onBlur = useCallback((e) => {
        if (OnBlur) OnBlur(e);
    }, [OnBlur]);

    const onChange = useCallback((e) => {
        if (OnChange) OnChange(e);
    }, [OnChange])

    const handleEye = () => {
        setEye(prev => !prev);
        if (eyeSrc === 'icons-admin/open-eye.svg') {
            setEyeSrc('icons-admin/close-eye.svg')
        } else setEyeSrc('icons-admin/open-eye.svg')
    }

    //sets value as the text written in the input box. 

    const handleEnter = (event: any) => {

        const form = event.target.form;
        if (form) {
            const index = [...form].indexOf(event.target);
            if (event.key.toLowerCase() === "enter" || event.key.toLowerCase() === "arrowdown") {
                if (form.elements[index + 1].className !== "") {
                    form.elements[index + 1].focus();
                    event.preventDefault();
                } else {
                    form.elements[form.elements.length - 1].focus()
                    event.preventDefault();
                }
            } else if (event.key.toLowerCase() === "arrowup" && index !== 0 && form.elements[index - 1].className !== "") {
                event.preventDefault();
                form.elements[index - 1].focus();
            }
        }
    }


    return (
        <div className={props.className ? props.className : ""}>
            <div className="input-container">
                <div className="input-title">
                    {props.title ? props.title.split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    }) : ""}
                </div>

                {/* if props type = text area, the input is text area. otherwise, input is text. */}
                {props.type === "textarea" ?
                    <textarea onKeyDown={(event) => { handleEnter(event) }} className="input-textarea" style={{ height: "12vh" }} placeholder={props.placeholder} value={value} onBlur={onBlur} onChange={onChange} disabled={isDisabled} />
                    : props.className === "categories-input" ?
                        <input name={props.name} className="category-input" type={props.type || 'text'} onKeyDown={(event) => { handleEnter(event) }} placeholder={props.placeholder} onBlur={onBlur} onChange={onChange} value={value} disabled={isDisabled} />
                        : props.name === 'password' ?
                            <div className="password-eye">
                                <input name={props.name} style={{ width: '28.4vw' }} type={!eye ? 'password' : 'text'} onKeyDown={(event) => { handleEnter(event) }} className="input" placeholder={props.placeholder} onBlur={onBlur} onChange={onChange} value={value} disabled={isDisabled} />
                                <div className={`eye`} onClick={handleEye}><img src={eyeSrc} alt="eye icon" /></div>
                            </div>
                            : <input name={props.name} type={props.type || 'text'} onKeyDown={(event) => { handleEnter(event) }} className={clsx("input", props.isSearch && "search")} placeholder={props.placeholder} onBlur={onBlur} onChange={onChange} value={value} disabled={isDisabled} />
                }
                <div className="input-error">
                    {error ? error : ""}
                </div>
            </div>
        </div>
    )
}

export default GenericInput;