import { useNavigate } from "react-router";
import { SYSTEM_TYPE } from "../consts/interfaces/SpecificStatistics.interface";

type navigationKeys = { [key in SYSTEM_TYPE]: string }

const navigations: navigationKeys = {
    agency: '/agencies',
    organization: '/organizations'
}

export const useNavigateToSystemPage = (systemType: SYSTEM_TYPE) => {
    const navigate = useNavigate()
    return () => { navigate(navigations[systemType]) };
} 