//this component receives props- TooltipProps
//it returns a generic tooltip (its used in add new event on address and in event page on generic tags - accessability) 

import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Fade, useMediaQuery } from '@mui/material';
import { styled } from '@mui/styles';

export interface TooltipPropsCustom extends TooltipProps {
    name: string;
}
const GenericToolTip: React.FC<TooltipPropsCustom> = (props) => {

    const isPhone = useMediaQuery('(max-width:768px)');
    const additionalStyle = props.style

    const StyledTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        '& .MuiTooltip-tooltip': {
            direction: "rtl",
            zIndex: '50',
            backgroundColor: "white",
            borderRadius: "0",
            color: "#41596b",
            textAlign: "right",
            font: "normal normal bold 2vh/2.5vh Assistant",
            boxShadow: "0 0.278vh 0.556vh #00000029",
            additionalStyle,
            marginTop: "0 !important", //important to override the margin-top of the tooltip
            ...(isPhone ? {
                // width: '8vh', //ask noa or nati
                fontSize: '12px',
                marginRight: "7vw"
            } : {
                padding: '1vh 3vh 1vh 3.5vh',
                marginRight: "2vw",
            })
        }
    }
    ));


    return (
        <>
            <StyledTooltip
                title={props.title}
                placement='bottom-end'
                TransitionComponent={Fade}
                enterTouchDelay={0}
                leaveTouchDelay={0}>
                <div>
                    {props.children}
                </div>
            </StyledTooltip>
        </>
    )
}
export default GenericToolTip