import { useEffect, useState } from "react";

import { ticketStatusTypesCount } from "../consts/interfaces/StatisticsContext.interface";
import { DataForPie } from "../consts/interfaces/GenericPieGraph.interface";
import { USED_AND_UTILIZATION } from "../consts/hebrew";
import { PIE_TYPES } from "../consts/variables";

import GenericPieGraph from "../generic-components/GenericPieGraph";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper"
import TicketStatusTypesCountArrows from "./TicketStatusTypesCountArrows"

import '../style/ticketsUtilization.scss'

const TicketsUtilization: React.FC<{ ticketStatusTypesCount: ticketStatusTypesCount, style?: { width: string } }> = ({ style, ticketStatusTypesCount }) => {
    const { donatedTicketsCount, purchasedTicketsCount } = ticketStatusTypesCount
    const [utilizationPieData, setUtilizationPieData] = useState<DataForPie[] | null>(null)
    const [percentOfUtilization, setPercentOfUtilization] = useState<number>(0)

    useEffect(() => {
        if (purchasedTicketsCount === null || donatedTicketsCount === null) return
        const percentOfTicketsUtilization = donatedTicketsCount !== 0 ? (purchasedTicketsCount * 100 / donatedTicketsCount) : 0
        const shortPercent = Math.round(percentOfTicketsUtilization)
        setPercentOfUtilization(shortPercent)
        setUtilizationPieData([
            {
                color: '#95C1CE',
                value: purchasedTicketsCount
            },
            {
                color: '#E4F1F0',
                value: (donatedTicketsCount || 1) - purchasedTicketsCount
            }
        ])
    }, [purchasedTicketsCount, donatedTicketsCount])

    return (
        <WhiteBoxWrapper>
            <div className="tickets-utilization-wrapper" style={style}>
                <TicketStatusTypesCountArrows
                    donatedTicketCount={donatedTicketsCount || 0}
                    purchasedTicketsCount={purchasedTicketsCount || 0} />

                <div className="border-in-middle"></div>

                <GenericPieGraph
                    data={utilizationPieData}
                    type={PIE_TYPES.ONE_SLICE}
                    firstLineMiddle={`${percentOfUtilization}%`}
                    secondLineMiddle={USED_AND_UTILIZATION}
                    style={{ width: '10vw', height: '23vh', marginRight: '2vw' }}
                />
            </div>
        </WhiteBoxWrapper>
    )
}

export default TicketsUtilization