import React, { FC } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import GenericPopup from "../../generic-components/GenericPopup";

interface GenericImagePopupProps {
    handleClose?: () => void,
    open: boolean,
    image?: string,
    title?: string;
    className?: string;
    children?: JSX.Element;
    imageStyles?: string;
    type?: string;
}
const GenericFullScreenImagePopup: FC<GenericImagePopupProps> = ({ image, handleClose, open }) => {

    return (
        <GenericPopup open={open} className="full-screen-popup full-screen" handleClose={handleClose}>
            <TransformWrapper
                // controll on the size of the image when you open it:
                initialScale={1}
            // initialPositionX={100}
            // initialPositionY={100}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <div className="tools">
                            <ZoomInIcon className="Zoom-icon" onClick={() => zoomIn()}></ZoomInIcon>
                            <ZoomOutIcon className="Zoom-icon" onClick={() => zoomOut()}></ZoomOutIcon>
                            {/* <button onClick={() => resetTransform()}>x</button> */}
                        </div>
                        <TransformComponent>
                            <div className="image-container-for-fullScreen">
                                <img src={image} alt="test" />
                            </div>

                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
        </GenericPopup>
    );
};

export default GenericFullScreenImagePopup;