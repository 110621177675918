import React from "react";
// @ts-ignore
import ApeandHead from 'react-append-head'; // typescript ignore
import { GaEventOptions } from "../consts/interfaces/GoogleAnalytics.interface";
export interface gaProps {
    gaEvent: (eventNames: string, options?: GaEventOptions) => void
    gaException: (name: string, description?: string | unknown, fatal?: boolean) => void
};
export const GoogleAnalyticsContext = React.createContext<gaProps>(null!);

const GoogleAnalyticsProvider: React.FC = ({ children }) => {
    const checkTestMode = () => document.location.href.includes('-t') // check if the url contains -t, if it does, it means that it is in test mode
    const googleAnalyticsTag = (checkTestMode) ? 'G-1WJCBTS04P' : 'G-5QCWWKS9SH';
    const isProduction = process.env.NODE_ENV === 'production';

    /**
     * method to send events to google analytics
     * @param eventName - the name of the event
     * @param options - the options of the event include event_category, event_label, value
     */
    const gaEvent = (eventName: string, options: GaEventOptions | {} = {}) => {
        isProduction && (window as any).gtag('event', eventName, options);
    }
    /**
     * method for google analytics exceptions
     * @param name - The name of the event.
     * @param description - The description of the event, if not provided, it will be the same as the name.
     * @param fatal - If the event is fatal or not. (default: false)
     */
    const gaException = (name: string, description: string | unknown = name, fatal: boolean = false) => {
        isProduction && (window as any).gtag('event', 'exception', {
            exception_label: name,
            description,
            fatal
        });
    }

    return (
        <GoogleAnalyticsContext.Provider value={{ gaEvent, gaException }}>
            {isProduction && <ApeandHead>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTag}`}></script>
                <script>
                    {`
                     window.dataLayer = window.dataLayer || [];
                     function gtag(){dataLayer.push(arguments);}
                     gtag('js', new Date());
                     gtag('config', '${googleAnalyticsTag}'${checkTestMode() ? ', {debug_mode: true}' : ''});
                    `}
                </script>
            </ApeandHead>}
            {children}
        </GoogleAnalyticsContext.Provider>
    );

}

export default GoogleAnalyticsProvider
