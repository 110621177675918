import { useParams } from 'react-router';
import { useContext, useEffect, useState } from 'react';

import { PROFILE } from "../consts/variables";
import { AGENCY_SUSPENDED, ORGANIZATION_SUSPENDED } from '../consts/hebrew';

import GenericSwitch from '../generic-components/GenericSwitch';
//when u click on a profile in the list of agencies or orgs it shows u the profile page with a navbar

import AgencyLocation from './AgencyLocation';
import HeaderSystemProfile from './HeaderSystemProfile';
import ItemInSystemProfile from './ItemInSystemProfile';
import OrgPdfFiles from './OrgPdfFiles';
import OrganizationConfiguration from './OrganizationConfiguration';
import ProfilePic from './ProfilePic';
import SystemContacts from './SystemContacts';
import TermOfSystem from './TermOfSystem';
import SystemName from './SystemName';

import { SystemTypeContext, systemTypeContextProps } from '../context/SystemTypeContext';
import { RemoteLogin } from './remote-login/RemoteLogin';

import { NAVBAR_OPTIONS } from '../consts/interfaces/HeaderSystemProfile.interface';
import { SYSTEM_TYPE } from '../consts/interfaces/SpecificStatistics.interface';
import { SystemContactsCx, SystemProfileImageCx, SystemTermCx } from '../consts/interfaces/SystemProfileContexts.interface';
import { AgencyAddressContext, OrgConfigurationContext, OrgFilesContext, SystemContactsContext, SystemProfileImageContext, SystemTermContext } from '../context/SystemProfileContexts';
import { putChangeOrgOrAgencyActive } from '../utils/api/systemActive';

import '../style/systemProfile.scss';

const SystemProfile: React.FC<{ pageType: NAVBAR_OPTIONS }> = ({ pageType }) => {
    const { systemType } = useContext(SystemTypeContext) as systemTypeContextProps
    const { profilePhotoCx, systemNameCx, isActive, imageCoordinatesCx } = useContext(SystemProfileImageContext) as SystemProfileImageCx
    const [isChecked, setIsChecked] = useState<boolean>(!isActive)
    const { contactsCx } = useContext(SystemContactsContext) as SystemContactsCx
    const { termDateCx, termNameCx } = useContext(SystemTermContext) as SystemTermCx
    const agencyAddress = useContext(AgencyAddressContext)
    const orgData = useContext(OrgConfigurationContext)
    const orgFiles = useContext(OrgFilesContext)
    const systemId = Number(useParams().id)

    const handelActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setIsChecked(prev => !prev);
        systemId && putChangeOrgOrAgencyActive(systemType, systemId, !checked)
    }

    useEffect(() => { // this is fix for state async issue @nati5
        setIsChecked(!isActive)
    }, [isActive])

    return (
        <>
            <div className='profile-box'>
                <ProfilePic profilePhoto={profilePhotoCx} imageCoordinates={imageCoordinatesCx} />
                <div className='array-map'>
                    <SystemName systemNameCx={systemNameCx} />
                    <TermOfSystem termDateCx={termDateCx} termNameCx={termNameCx} />
                    {orgData && pageType === 'profile' ?
                        <OrganizationConfiguration organizationData={orgData} /> : <></>}
                    {contactsCx &&
                        <SystemContacts contactsCx={contactsCx} />}
                    {agencyAddress?.locationCx &&
                        <AgencyLocation agencyLocation={agencyAddress} />}
                    {orgFiles?.filesCx &&
                        <OrgPdfFiles organizationFiles={orgFiles.filesCx} />}
                    {pageType === 'profile' && <GenericSwitch
                        onChange={handelActiveChange}
                        label={systemType === 'organization' ? ORGANIZATION_SUSPENDED : AGENCY_SUSPENDED}
                        checked={isChecked}
                    />}
                </div>
            </div>
            <RemoteLogin entityName={systemNameCx} />
        </>
    )
}

export default SystemProfile;