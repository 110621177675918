import { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from 'axios';
import { PrivateRoute, PublicOnlyRoute, useAuth, useIsAuthenticated } from '@hilma/auth';
import { SocketProvider } from '@hilma/socket.io-react';
import { useAsyncEffect } from '@hilma/tools';

import ListOfEventsEnterLive from './components/ListOfEventsEnterLive';
import ListOfOrganizations from "./components/ListOfOrganizations";
import SpecificStatistics from './components/SpecificStatistics';
import ListOfAgencies from "./components/ListOfAgencies";
import ArchiveEvents from './components/ArchiveEvents';
import SystemProfile from './components/SystemProfile';
import Requests from "./components/Requests";

import GenericSystemProfile from './generic-components/GenericSystemProfile';
import AdminHeader from './generic-components/AdminHeader';
import Feedbacks from './generic-components/GenericFeedbacks';

import { AgencyProfileProvider, OrgProfileProvider } from './context/SystemProfileProviders';
import { SpecificStatisticsProvider } from './context/SpecificStatisticsContext';
import { RequestNumberProvider } from './context/RequestNumberContext';
import { StatisticsProvider } from './context/StatisticsContext';
import { UserContext } from './context/UserContext';

import EnterLivePage from './pages/EnterLivePage';
import ForgotPassword from './pages/ForgotPassword';
import LogIn from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import Statistics from './pages/Statistics';
import UpdatePassword from './pages/UpdatePassword';
import SystemInformationPage from './pages/SystemInformationPage';
import TicketOrder from './components/event-page-copy/TicketOrder';

import { NAVBAR_OPTIONS } from './consts/interfaces/HeaderSystemProfile.interface';
import { SYSTEM_TYPE } from './consts/interfaces/SpecificStatistics.interface';
import { ConfigContextProvider } from './context/ConfigContext';

import './App.css';
function App() {

  const isAuthenticated = useIsAuthenticated();

  const { loggingIn } = useContext(UserContext);

  useAsyncEffect(async () => {
    if (isAuthenticated) {
      try {
        const details = await axios.get('/api/contact/get-current-user')
        if (details.data.organization === null && details.data.agency === null) {
          loggingIn(details.data.name, "city", details.data.city, details.data.username)
        }
      } catch (error) {
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.addEventListener('error', e => {
      // mui have introduced a bug with their masonry component which causes the resize observer to exceed the loop limit
      // here is a temporary hack to ignore the error for the resize observers
      // once a while check the mui library to see if they have fixed it, and when possible, remove this hack
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
        return true;
      }
    });
  }, []);


  const auth = useAuth()
  return (
    <ConfigContextProvider>
      <SocketProvider options={{ reconnectionAttempts: 2, query: { token: auth.getAccessToken() } }}>
        <RequestNumberProvider>
            <div className="App">
              <BrowserRouter>
                <AdminHeader />
                <Routes>
                  <Route index path="/statistics" element={<PrivateRoute component={<StatisticsProvider><Statistics /></StatisticsProvider>} componentName="Statistics" redirectPath="/" />} />
                  <Route path="agencies" element={<PrivateRoute component={<SystemInformationPage systemType={SYSTEM_TYPE.agency} />} componentName="Agencies" />}>
                    <Route index element={<ListOfAgencies />} />
                    <Route path='requests' element={<Requests />} />
                    <Route path="request-profile" element={<AgencyProfileProvider><GenericSystemProfile pageType={NAVBAR_OPTIONS.request} /></AgencyProfileProvider>} >
                      <Route path=':id' element={<SystemProfile pageType={NAVBAR_OPTIONS.request} />} />
                    </Route>
                    <Route path='profile'>
                      <Route path=":id" element={<AgencyProfileProvider><GenericSystemProfile pageType={NAVBAR_OPTIONS.profile} /></AgencyProfileProvider>} >
                        <Route index element={<SystemProfile pageType={NAVBAR_OPTIONS.profile} />} />
                        <Route path='statistics' element={<SpecificStatisticsProvider> <SpecificStatistics /></SpecificStatisticsProvider>} />
                        <Route path='archive'>
                          <Route index element={<ArchiveEvents isAgency={true} />} />
                          <Route path=':eventId' element={<Feedbacks />} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path="organizations" element={<PrivateRoute component={<SystemInformationPage systemType={SYSTEM_TYPE.organization} />} componentName="Organizations" />}>
                    <Route index element={<ListOfOrganizations />} />
                    <Route path='requests' element={<Requests />} />
                    <Route path="request-profile" element={<OrgProfileProvider><GenericSystemProfile pageType={NAVBAR_OPTIONS.request} /></OrgProfileProvider>} >
                      <Route path=':id' element={<SystemProfile pageType={NAVBAR_OPTIONS.request} />} />
                    </Route>
                    <Route path='profile'>
                      <Route path=':id' element={<OrgProfileProvider><GenericSystemProfile pageType={NAVBAR_OPTIONS.profile} /></OrgProfileProvider>}>
                        <Route path='' element={<SystemProfile pageType={NAVBAR_OPTIONS.profile} />} />
                        <Route path='statistics' element={<SpecificStatisticsProvider> <SpecificStatistics /></SpecificStatisticsProvider>} />
                        <Route path='archive' element={<ArchiveEvents isAgency={false} />} />
                      </Route>
                    </Route>
                  </Route >
                  <Route path='live' element={<PrivateRoute component={EnterLivePage} componentName="EnterLive" />}>
                    <Route path='' element={<ListOfEventsEnterLive eventsType={'events'} />} />
                    <Route path='archive-events' element={<ListOfEventsEnterLive eventsType={'archive-events'} />} />
                  </Route>
                  <Route path='/event/:id' element={<PrivateRoute component={TicketOrder} componentName="EventPage" />} />

                  <Route path='/' element={<PublicOnlyRoute component={LogIn} componentName={'LogIn'} redirectPath='/statistics' />} />
                  <Route path='*' element={<PageNotFound />} />
                  <Route path="forgot-password" element={<PublicOnlyRoute component={ForgotPassword} componentName={'ForgotPassword'} />} />
                  <Route path="update-password" >
                    <Route path=":token" element={<UpdatePassword />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </div>
        </RequestNumberProvider>
      </SocketProvider >
    </ConfigContextProvider >
  );
}

export default App;
