import { useEffect, useState } from "react"
import { CANCELED_EVENTS, CANCELED_EVENTS_AMOUNT, EVENTS_OCCURRENCE, MORE_DETAILS, OCCURRED_EVENTS, OCCURRED_EVENTS_AMOUNT } from "../consts/hebrew"
import { PIE_TYPES } from "../consts/variables"

import PopupCanceledEventsDetails from "./popups/PopupCanceledEventsDetails"
import BottomTextArrow from "../generic-components/BottomTextArrow"
import GenericPieGraph from "../generic-components/GenericPieGraph"
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper"

interface eventsStatisticsProps {
    name: string;
    value: number;
    tooltip: string;
    color: string;
}

const EventsOccurrence: React.FC<{ occurredAmount: number, canceledAmount: number, style: React.CSSProperties }> = ({ occurredAmount, canceledAmount, style }) => {
    const [eventsStatistics, setEventsStatistics] = useState<eventsStatisticsProps[] | null>(null)
    const [popup, setPopup] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>()

    useEffect(() => {
        const getEventsOccurrenceStatistics = async () => {
            setEventsStatistics([{
                name: OCCURRED_EVENTS,
                value: occurredAmount || 0,
                tooltip: `${occurredAmount} ${OCCURRED_EVENTS_AMOUNT}`,
                color: '#95C1CE',
            },
            {
                name: CANCELED_EVENTS,
                value: canceledAmount || 0,
                tooltip: `${canceledAmount} ${CANCELED_EVENTS_AMOUNT}`,
                color: '#0C414F'
            }])

            const eventSum = (occurredAmount || 0) + (canceledAmount || 0);
            setNoData(!eventSum)
        }

        getEventsOccurrenceStatistics()
    }, [occurredAmount, canceledAmount])


    return (
        <WhiteBoxWrapper>
            <h1 className='stats-title'>{EVENTS_OCCURRENCE}</h1>
            <GenericPieGraph
                data={eventsStatistics}
                type={PIE_TYPES.FULL_PIE}
                isLegend={true}
                tooltip={true}
                noData={noData}
                style={{ width: '45vw', height: '20vh', marginTop: '2vh', ...style }}
            />

            {!noData && eventsStatistics?.[1].value !== 0 &&
                <BottomTextArrow bottomText={MORE_DETAILS} onBottomTextClick={() => setPopup(true)} />}

            <PopupCanceledEventsDetails open={popup} handleClose={() => setPopup(false)} />
        </WhiteBoxWrapper>
    )
}

export default EventsOccurrence