import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { LiveEventBoxInterface } from "../consts/interfaces/LiveEventBox.interface";
import { EVENT_STATUS } from "../consts/variables";
import { EVENT_TIME_STATUS } from "../consts/hebrew";

import { buildTimeString, calculateTimeDifference, eventToDismantledDate } from "../functions/dateFunctions";

import PopupDetailsOfTicketsEvent from "./popups/PopupDetailsOfTicketEvent";
import GenericEventCover from "../generic-components/GenericEventCover";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";
import GenericButton from "../generic-components/GenericButton";
import { useEventOffersQuery } from "../hooks/api/event";
import AgencyEventFlow from "./AgencyEventFlow";

import "../style/liveEventBox.scss";

const LiveEventBox: React.FC<LiveEventBoxInterface> = ({ eventId, date, timeLeft, purchasedTickets, totalTickets, img, artist, orgsOffered, orgsPurchased, publishedAt, agencyName, timerDuration, imageCoordinates, eventType, eventStatus }) => {
    const { data } = useEventOffersQuery(eventId);

    const navigate = useNavigate();

    const [eventTimeLeft, setEventTimeLeft] = useState<string>()
    const [timeExpired, setTimeExpired] = useState<string>()
    const [detailsTicketsPopup, setDetailsTicketsPopup] = useState<boolean>(false)

    const formattedDate = useMemo(() => eventToDismantledDate(date), [date])

    const orgsThatPurchasedTicketsByCoupon = useMemo(() => data?.filter((org: { countOfPurchasedTickets: number | null; }) => org.countOfPurchasedTickets), [data])

    useEffect(() => {
        if (!timeLeft) return;

        const eventExpirationDate = new Date(date)
        eventExpirationDate.setHours(eventExpirationDate.getHours() - 4) // 4 hours before the event it is no longer offered

        const eventTimer = setInterval(() => {
            const millisecondsLeft = calculateTimeDifference(eventExpirationDate.toString())
            setEventTimeLeft(buildTimeString(millisecondsLeft))
        }, 1000)

        return () => clearInterval(eventTimer)
    }, [timeLeft, date])

    useEffect(() => {
        if (!timeLeft) return;

        const TimeInterval = setInterval(() => {
            const timeDifference = calculateTimeDifference(timeLeft)
            if (timeDifference <= 0) {
                clearInterval(TimeInterval);
            }
            else setTimeExpired(buildTimeString(timeDifference));
        }, 1000);

        return () => clearInterval(TimeInterval);
    }, [timeLeft])

    const handleOpenTicketDetailsPopup: React.MouseEventHandler = (e) => {
        e.stopPropagation()
        setDetailsTicketsPopup(true)
    }

    const handleCloseTicketDetailsPopup = (event: object) => {
        (event as React.MouseEvent).stopPropagation();
        setDetailsTicketsPopup(false)
    }

    return (
        <WhiteBoxWrapper>
            <div className="live-box">
                <div className="live-box-event-details" onClick={() => navigate(`/event/${eventId}`)}>
                    <div className="live-box-img">
                        <GenericEventCover src={img} className="live-event-cover" imageCoordinates={imageCoordinates} />
                    </div>
                    <div className="live-box-text">
                        <div className="live-box-name bold">
                            {artist}
                        </div>

                        <div className="live-box-date">
                            {formattedDate.day + " | " + formattedDate.date + " | " + formattedDate.time}
                        </div>
                    </div>
                    <div className="live-box-time-left">
                        {timeLeft ?
                            <div>
                                {EVENT_TIME_STATUS.LEFT_TIME_FOR_PURCHASING}
                                <div className="bold">{eventTimeLeft}</div>
                            </div> :
                            <div className="bold">{eventStatus && EVENT_STATUS[eventStatus].text}</div>
                        }
                    </div>
                    <div className="live-box-tickets">
                        {!timeLeft ?
                            <div>
                                <span className="bold">{orgsOffered + " עמותות "}</span>
                                קיבלו את ההצעה
                                <br />
                                <span className="bold">{orgsPurchased + " עמותות "}</span>
                                עמותות משכו כרטיסים
                            </div>
                            : <></>
                        }
                        <span className="bold">{(totalTickets - purchasedTickets) + "/" + totalTickets + " כרטיסים "}</span>
                        זמינים לרכישה
                    </div>
                    {eventType === 'coupon' && orgsThatPurchasedTicketsByCoupon?.length > 0 ?
                        <>
                            <GenericButton className="live-box-tickets-details-btn" handleClick={handleOpenTicketDetailsPopup}>
                                פירוט מימוש כרטיסים
                            </GenericButton>
                            <PopupDetailsOfTicketsEvent
                                open={detailsTicketsPopup}
                                handleClose={handleCloseTicketDetailsPopup}
                                artistName={artist}
                                agencyName={agencyName}
                                tableData={orgsThatPurchasedTicketsByCoupon}
                                eventId={eventId}
                            /></>
                        : null
                    }
                </div>
                <div className="live-box-event-map">
                    <AgencyEventFlow
                        eventId={eventId}
                        eventStatus={eventStatus}
                        timeLeft={timeExpired}
                        publishedAt={publishedAt}
                        agencyName={agencyName}
                        timerDuration={timerDuration}
                        expirationDate={timeLeft}
                    />
                </div>
            </div>
        </WhiteBoxWrapper>
    )
}

export default LiveEventBox;