import { Component, ComponentType, useContext, useMemo, useState } from "react";
import { RemoteLoginButton } from "./RemoteLoginButton";
import { SystemTypeContext } from "../../context/SystemTypeContext";
import { useParams } from "react-router";
import { featureFlagComponent } from "../../utils/featureFlagComponent";
import PopupAdminRemoteLogin from "./PopupAdminRemoteLogin";
import { UserContext } from "../../context/UserContext";

interface RemoteLoginProps {
  entityName: string
}

export const RemoteLogin = featureFlagComponent(
  'remote_login',
  function RemoteLogin(props: RemoteLoginProps) {

    const systemType = useContext(SystemTypeContext);
    const { id: stringProfileId } = useParams();
    const profileId = useMemo(() => Number(stringProfileId || ''), [stringProfileId]);
    const [isPopupOpen, setIsPopupRequestOpen] = useState(false);
    const openPopup = () => setIsPopupRequestOpen(true);
    const closePopup = () => setIsPopupRequestOpen(false);
    const { userName } = useContext(UserContext);
    const userEmail = userName;

    return (
      <>
        <RemoteLoginButton onClick={openPopup} entityType={systemType?.systemType || ''} />
        <PopupAdminRemoteLogin
          handleClose={closePopup}
          open={isPopupOpen}
          adminEmail={userEmail}
          entityName={props.entityName}
          entityType={systemType?.systemType || ''}
          entityId={profileId} />
      </>
    )
  }
);
