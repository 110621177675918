import axios from "axios";
import { ticketsActuallyPurchased } from "../../consts/interfaces/PopupDetailsOfTicketsEvent.interface";

export async function fetchEventOffersById(eventId: number) {
    const response = await axios.get(`/api/event/${eventId}/offers`);
    return response.data;
}

export async function fetchEventsEnterLive() {
    const response = await axios.get('/api/event/enter-live');
    return response.data;
}

export async function createTicketsActuallyPurchased(ticketsData: ticketsActuallyPurchased[]) {
    const response = await axios.post('/api/event/tickets-actually-purchased-using-coupon', ticketsData);
    return response.data;
}