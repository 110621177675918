import { CANCEL_EVENT_DETAILS } from "../../consts/hebrew"

import CanceledEventsDescription from "../CanceledEventsDescription"
import GenericPopup from "../../generic-components/GenericPopup"
import AgenciesCanceledEvents from "../AgenciesCanceledEvents"
import CancelEventReasons from "../CancelEventReasons"

import '../../style/canceledEventsDetailsPopup.scss';

const PopupCanceledEventsDetails: React.FC<{ open: boolean, handleClose: () => void }> = ({ open, handleClose }) => {

    return (
        <GenericPopup title={CANCEL_EVENT_DETAILS} className="canceled-events-details-popup" open={open} handleClose={handleClose}>
            <div className="details-container">
                <div className="right-side">
                    <CancelEventReasons />
                    <CanceledEventsDescription />
                </div>
                <div className="seperator"> </div>
                <AgenciesCanceledEvents />
            </div>
        </GenericPopup>
    )
}

export default PopupCanceledEventsDetails;