import clsx from "clsx";
import GenericButton from "../../generic-components/GenericButton";
import { useRequestRemoteLoginCodeMutation } from "../../hooks/api/remote-login";
import { REMOTE_LOGIN_TEXTS } from "../../consts/hebrew";
import styles from './sendRemoteLoginCodeButton.module.scss'

interface SendRemoteLoginCodeButtonProps {
    entityType: string;
    entityId: number;
    onCodeSent(): void;
}
export function SendRemoteLoginCodeButton(props: SendRemoteLoginCodeButtonProps) {
    const requestRemoteLoginCodeMutation = useRequestRemoteLoginCodeMutation({
        onSuccess() {
            props.onCodeSent();
        },
    });

    const handleClick = () => {
        if (requestRemoteLoginCodeMutation.isLoading) return;
        requestRemoteLoginCodeMutation.mutateAsync({
            entityType: props.entityType,
            entityId: props.entityId,
        });
    }

    return (
        <GenericButton
            className={clsx('turquoise', styles.sendCodeButton)}
            handleClick={handleClick}
            disabled={requestRemoteLoginCodeMutation.isLoading}>
            {REMOTE_LOGIN_TEXTS.VERIFY_CODE_TEXT_BUTTON}
        </GenericButton>
    )
}