import React from 'react';
import { IconButton, Modal } from '@mui/material';
import clsx from 'clsx';

import { GenericPopupProps } from '../consts/interfaces/GenericPopup.interface';
import GenericButton from './GenericButton';
import '../style/genericPopup.scss';

const GenericPopup: React.FC<GenericPopupProps> = (props) => {
  const isIos = /iPhone|iPad|iPod|/i.test(navigator.userAgent);

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown
    >
      <div style={isIos ? { paddingBottom: "2vh", outline: "none" } : { paddingBottom: "2vh" }} className={props.isFeedback ? "popup-with-img" : `the-popup ${props.className || ''}`}>
        <div className='the-header'>
          {props.imageStyles === "edit" ? [] :
            props.type ?
              <div className="thumbs-up-popup">
                <IconButton sx={{ width: "8vh", height: "8vh" }} onClick={props.handleClose}>
                  <span className='close-button-thumbs-up'>×</span>
                </IconButton>
                <div className="title-and-thumbs-up">
                  <div className='the-title-thumbs-up'>
                    {props.title}
                  </div>
                  <div className="animation-thumbs-up">
                    <img src="/icons/thumbs-up.png" alt="thumbs up" className="thumbs-up" />
                  </div>
                </div>
              </div>
              :
              <div className='close-button-container'>
                <IconButton sx={{ width: "8vh", height: "8vh", }} onClick={props.handleClose}>
                  <span className='close-button-text'>×</span>
                </IconButton>
              </div>
          }
        </div>
        <div className='flex-center'>
          {props.isFeedback && props.image ?
            <div className='photo-popup'>
              <img src={props.image} className="the-img" alt="" />
            </div> :
            props.image && <><img className={clsx('the-icon', props.imageStyles)} src={props.image} alt="" />

              {props.LabelIcon && <div className='titleImageIconWaitingStyle'>{props.LabelIcon}</div>}
            </>
          }
          {props.title && !props.type &&
            <div className={clsx('the-title', props.titleClassname)} >{props.title}</div>}

          {props.children}
          {props.button &&
            <div className='the-button'>
              <GenericButton disabled={props.button.disabled} className={`yellow ${props.isSmall ? 'small' : 'big'} `} handleClick={props.button.handleClick}>{props.button.text}</GenericButton>
            </div>}
        </div>
      </div>
    </Modal>
  )
}

export default GenericPopup;