//this component gets as props - GenericTableProps
//it returns  a generic table that shows the orgs or agencys
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { selectedSystemInfo } from "../consts/interfaces/selectedSystem.interface";
import { SYSTEM_TYPE } from "../consts/interfaces/SpecificStatistics.interface";
import { GenericTableProps } from "../consts/interfaces/GenericTable.interface";
import TableDetails from "../consts/interfaces/TableDetails.interface";

import GenericToolTip from "./GenericToolTip";
import GenericProfilePic from "./GenericProfilePic";
import { headCells1, headCells2 } from "./GenericTableHeadCells";
import AcceptedTableCell from "../components/AcceptedTableCell";
import ActivityStatus from "../components/ActivityStatus";
import {
    SystemTypeContext,
    systemTypeContextProps,
} from "../context/SystemTypeContext";
import { PopupRejectOrAcceptSystem } from "../components/popups/PopupRejectOrAcceptSystem";

const GenericTable: React.FC<GenericTableProps> = (props) => {
    const { systemType } = useContext(
        SystemTypeContext
    ) as systemTypeContextProps;
    const navigate = useNavigate();
    const location = useLocation();
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedSystem, setSelectedSystem] = useState<selectedSystemInfo>({
        id: 0,
        name: "",
        systemType: "",
        isAccepted: true,
    });

    const systemName = useMemo(() => {
        if (systemType === SYSTEM_TYPE.organization) return " העמותה";
        return " המוסד";
    }, [systemType]);

    const details: TableDetails[] = useMemo(() => props.data, [props.data]);

    const systemTypeLink = useMemo(() => {
        return location.pathname.split("/")[1];
    }, [location.pathname]);

    //on click of a specific row redirects to the org or agency profile
    const handleProfile = (
        _e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
        id: number
    ) => {
        if (location.pathname.split("/")[2] === "requests") {
            navigate(`/${systemTypeLink}/request-profile/${id}`);
        } else {
            navigate(`${location.pathname}/profile/${id}`);
        }
    };

    const handleClickOnAcceptOrReject = (
        id: number,
        name: string,
        systemType: string,
        isAccepted: boolean
    ) => {
        setSelectedSystem({ id, name, systemType, isAccepted });
        setPopupOpen(true);
    };

    //mui table styles
    const tableStyle = {
        width: "85vw",
        ".MuiTableHead-root": {
            ".MuiTableCell-root": {
                fontWeight: "600",
                color: "#41596B",
                backgroundColor: "#ECECF2",
                fontSize: "2.5vh",
                "&:first-child": {
                    borderRadius: "0 20px 0 0",
                },
                "&:last-child": {
                    borderRadius: "20px 0 0 0",
                },
            },
        },

        ".MuiTableBody-root": {
            backgroundColor: "white",

            ".forScroll::-webkit-scrollbar": {
                width: "0.2vw",
            },
            ".forScroll::-webkit-scrollbar-track": {
                width: "0.2vw",
            },
            ".forScroll::-webkit-scrollbar-thumb": {
                bgcolor: "#ECECF2",
            },

            ".MuiTableRow-root": {
                cursor: "pointer",
            },
        },

        ".MuiTableRow-root": {
            display: "table",
            width: "85vw",
            tableLayout: "fixed",
            ".MuiTableCell-root": {
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontSize: "2vh",
                fontFamily: "unset",
            },
        },

        ".MuiTableCell-root.first-time": {
            width: "1%",
            color: "#fd003c",
        },
    };

    return (
        <TableContainer sx={{ marginRight: "5vw" }}>
            <Table stickyHeader sx={tableStyle}>
                <TableHead>
                    <TableRow>
                        {props.accepted
                            ? headCells1.map((headCell) => (
                                  <TableCell key={headCell.id} align="center">
                                      {headCell.label === "שם"
                                          ? `${headCell.label}${systemName}`
                                          : headCell.label}
                                  </TableCell>
                              ))
                            : headCells2.map((headCell) => (
                                  <TableCell key={headCell.id} align="center">
                                      {headCell.label === "שם"
                                          ? `${headCell.label}${systemName}`
                                          : headCell.label}
                                  </TableCell>
                              ))}
                    </TableRow>
                </TableHead>
                {!props.isLoading ? (
                    <TableBody>
                        <div
                            className={"forScroll"}
                            style={{ maxHeight: "50vh", overflowY: "scroll" }}
                        >
                            {details.map((tableCell) => (
                                <TableRow
                                    key={tableCell.id}
                                    onClick={(e) => {
                                        handleProfile(e, tableCell.id);
                                    }}
                                >
                                    <TableCell
                                        align="center"
                                        sx={{
                                            padding: "12px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "6vh",
                                                height: "6vh",
                                                backgroundColor: "#F1F1F3",
                                                borderRadius: "50%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <GenericProfilePic
                                                src={tableCell.picture || ""}
                                                imageCoordinates={
                                                    tableCell.imageCoordinates
                                                }
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {tableCell.systemName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {tableCell.contactName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {tableCell.contactPhone}
                                    </TableCell>
                                    <TableCell
                                        className="email-cell"
                                        align="center"
                                    >
                                        {tableCell.email}
                                    </TableCell>
                                    {systemType ===
                                        SYSTEM_TYPE.organization && (
                                        <TableCell className="first-time">
                                            {tableCell.firstTime && (
                                                <GenericToolTip
                                                    name="first time"
                                                    title="העמותה לא עדכנה פרטים"
                                                >
                                                    <ErrorOutlineOutlinedIcon />
                                                </GenericToolTip>
                                            )}
                                        </TableCell>
                                    )}
                                    {props.accepted && props.setInit ? (
                                        <AcceptedTableCell
                                            setInit={props.setInit}
                                            setTableData={props.setTableData}
                                            id={tableCell.id}
                                            organizationOrAgency={systemType}
                                            name={tableCell.systemName}
                                            handleClickOnAcceptOrReject={
                                                handleClickOnAcceptOrReject
                                            }
                                        />
                                    ) : (
                                        <ActivityStatus
                                            activityStatus={
                                                tableCell.activityStatus
                                            }
                                        />
                                    )}
                                </TableRow>
                            ))}
                            <PopupRejectOrAcceptSystem
                                isOpen={popupOpen}
                                setIsOpen={setPopupOpen}
                                selectedSystem={selectedSystem}
                                setTableData={props.setTableData}
                                setInit={props.setInit}
                            />
                        </div>
                    </TableBody>
                ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Skeleton
                            variant="rectangular"
                            width={"85vw"}
                            height={"10vh"}
                            sx={{
                                mb: "0.2vh",
                                backgroundColor: "rgba(207, 207, 207, 0.4)",
                            }}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={"85vw"}
                            height={"10vh"}
                            sx={{
                                mb: "0.2vh",
                                backgroundColor: "rgba(207, 207, 207, 0.4)",
                            }}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={"85vw"}
                            height={"10vh"}
                            sx={{
                                mb: "0.2vh",
                                backgroundColor: "rgba(207, 207, 207, 0.4)",
                            }}
                        />
                    </div>
                )}
            </Table>
        </TableContainer>
    );
};

export default GenericTable;
