import axios from "axios";

export interface RequestCodeParams {
  entityType: string;
  entityId: number;
}
export async function requestCode(params: RequestCodeParams) {
  const response = await axios.post('/api/admin/request-remote-login', params);
  if (response.status === 204) return;

  throw new Error('Unexpectd Response');
}

export async function verifyCode(code: string) {
  const response = await axios.post('/api/admin/verify-remote-login', {code});
  if (response.status === 201) return response.data;

  throw new Error('Unexpectd Response');
}