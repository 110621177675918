import { useContext } from "react"

import { agencyStatContext } from "../../consts/interfaces/AgencyStatisticsContext.interface"
import { specificStatistics } from "../../consts/interfaces/SpecificStatistics.interface"
import { FROM_POST_TO_PURCHASE } from "../../consts/hebrew"

import { AgencyStatisticsContext } from "../../context/AgencyStatisticsContext"
import { SpecificStatisticsContext } from "../../context/SpecificStatisticsContext"

import TicketsUtilizationDistribution from "../TicketsUtilizationDistribution"
import WhiteBoxWrapper from "../../generic-components/WhiteBoxWrapper"
import AverageTimeTillUseFromPost from "../AverageTimeTillUseFromPost"
import ReceivedFromSpecific from "./ReceivedFromSpecific"
import AgencyFeedbacksStat from "../AgencyFeedbacksStat"
import TotalDonationWorth from "../TotalDonationWorth"
import EventsOccurrence from "../EventsOccurrence"
import NumEventsCard from "../NumEventsCard"

import '../../style/statistics.scss';


const AgencyStatistics = () => {
    const { worthOfAgencyDonation, ticketUtilization, averageTimeFromPost, eventsOccurrenceCount } = useContext(AgencyStatisticsContext) as agencyStatContext
    const { countOfEvents, totalFeedbacks } = useContext(SpecificStatisticsContext) as specificStatistics

    return (
        <div className="statistics-container" id='agencies-statistics'>
            <NumEventsCard eventsAmount={countOfEvents} style={{ width: '25vw' }} />
            <ReceivedFromSpecific style={{ width: '25vw' }} />
            <TotalDonationWorth donation={worthOfAgencyDonation} />
            <TicketsUtilizationDistribution ticketUtilization={ticketUtilization} legendFar={true} style={{ width: '40vw' }} />
            <WhiteBoxWrapper>
                <AverageTimeTillUseFromPost textUnder={FROM_POST_TO_PURCHASE} averageTimeFromPost={averageTimeFromPost} style={{ width: '20vw' }} />
            </WhiteBoxWrapper>
            <EventsOccurrence canceledAmount={eventsOccurrenceCount.canceledAmount} occurredAmount={eventsOccurrenceCount.occurredAmount} style={{ width: '52vw' }} />
            <AgencyFeedbacksStat totalFeedbacks={totalFeedbacks} />
        </div>
    )
}
export default AgencyStatistics