import { useContext, useEffect, useState } from "react"
import ItemInSystemProfile from "./ItemInSystemProfile"
import { turnArrToString } from "../functions/array-functions"
import { OrganizationConfigurationProps } from "../consts/interfaces/OrgConfiguration.interface"
import { PROFILE } from "../consts/variables"
import { EditSystemProfileContext } from "../context/SystemProfileContexts"

const OrganizationConfiguration: React.FC<OrganizationConfigurationProps> = ({ organizationData, }) => {
    const { isEditMode, saveDataByIdentifier } = useContext(EditSystemProfileContext) || {}

    const { ageRangeCx, accessibilityCx, participantsNumCx } = organizationData
    const [participantsNum, setParticipantsNumCx] = useState<string>('')
    const [ageRange, setAgeRange] = useState<string>('')
    const [accessibility, setAccessibility] = useState<string>('')

    useEffect(() => {
        setParticipantsNumCx(`${participantsNumCx}`)
    }, [participantsNumCx, isEditMode])

    useEffect(() => {
        setAgeRange(turnArrToString(ageRangeCx))
    }, [ageRangeCx])

    useEffect(() => {
        setAccessibility(turnArrToString(accessibilityCx))
    }, [accessibilityCx])

    return (
        <>
            {participantsNumCx !== 0 && <ItemInSystemProfile name={PROFILE.NUM_OF_PARTICIPANTS} data={participantsNum} setData={setParticipantsNumCx} isEdit={isEditMode} saveDataByIdentifier={saveDataByIdentifier} id="participantNum" />}
            {ageRangeCx.length !== 0 && <ItemInSystemProfile name={PROFILE.AGE_RANGE} data={ageRange} setData={setAgeRange} />}
            {accessibilityCx.length !== 0 && <ItemInSystemProfile name={PROFILE.ACCESSIBIITIES} data={accessibility} setData={setAccessibility} />}
        </>
    )
}
export default OrganizationConfiguration 