import { VALID_NAME, VALID_LINK, VALID_EMAIL, VALID_NUM, VALID_PHONE_NUMBER, VALID_TEXT, VALID_CODE } from "../consts/regexes";

export const textValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_TEXT.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const passwordValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (value.length < 6 || value.length > 12) {
        return "סיסמא חייבת להיות בין 6-12 תווים";
    } else if (!VALID_TEXT.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const nameValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_NAME.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const phoneNumValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_PHONE_NUMBER.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const numValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_NUM.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const emailValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_EMAIL.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const codeValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_CODE.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const fileValidation = (connectedError: string, flag: string) => {
    // the first argument is connected error, if its exist it will be returned and should be displayed
    // the second argument is the flag (it will flag if the file is connected or not), if not it will return the appropriate error
    // if the error condition is not met it will return an empty string (valid file)
    if (connectedError) {  // there are error messages
        return connectedError;
    } else if (!flag) { // no files in the array
        return "יש למלא שדה זה ";
    }
    //otherwise the file is valid
    return "";
}

export const seatGroupFileValidation = (value: string, link: string) => {
    if (value === "לא תקין") {  // there are error messages
        return "לא תקיו";
    } else if (link) { //otherwise the file is valid
        return "";
    }
    // no files in the array
    return "יש למלא שדה זה";
}


export const linkValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_LINK.test(value)) {
        return "לא תקין";
    }
    return "";

}


