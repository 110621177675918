import axios from "axios"

export const eventsCategories = async (systemId: string, filterDate?: Date) => {
    return (await axios.get(`/api/statistics/event-categories?filterDate=${filterDate || ''}&organizationId=${systemId}`)).data
}

export const getExtraTime = async (systemId: string, filterDate?: Date) => {
    return (await axios.get(`/api/statistics/extra-time-data?filterDate=${filterDate || ''}&organizationId=${systemId}`)).data
}

export const getDonatedAndReceivedTickets = async (systemId: string, filterDate?: Date) => {
    return (await axios.get(`/api/statistics/donated-and-received-tickets-count?filterDate=${filterDate || ''}&organizationId=${systemId}`)).data
}
