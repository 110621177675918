import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { RequestCodeParams, requestCode, verifyCode } from "../../utils/api/remote-login";

export function useRequestRemoteLoginCodeMutation(options?: UseMutationOptions<void, unknown, RequestCodeParams, unknown>) {
  return useMutation(['remote-login', 'code', 'request'], requestCode, options);
}

type verifyCodeResponseType = {
  exchangeUrl: string,
} 
export function useVerifyRemoteLoginCodeMutation(options?: UseMutationOptions<verifyCodeResponseType, unknown, string, unknown>) {
  return useMutation(['remote-login', 'code', 'verify'], verifyCode, options);
}