import { ItemInSystemProfileProps } from "../consts/interfaces/ItemInSystemProfile.interface"
import GenericInput from "../generic-components/GenericInput"

const ItemInSystemProfile: React.FC<ItemInSystemProfileProps> = ({ name, data, setData, isEdit, saveDataByIdentifier, id }) => {
    return (
        <div className='profile-item'>
            {name && <div className='profile-item-name'>{name}</div>}
            <GenericInput
                className='profile-item-data'
                value={data}
                onChange={(e) => setData(e.target.value)}
                disabled={!isEdit}
                onBlur={(e) => id && saveDataByIdentifier?.(id, e.target.value)}
            />
        </div>
    )
}

export default ItemInSystemProfile