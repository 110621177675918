import { useState } from 'react';
import axios from 'axios';
import { useAsyncEffect } from '@hilma/tools';

import { Accessibility, SeatGroupAccessibility } from '../../consts/interfaces/EventTypes';
import { GenericSeatGroupEnum } from '../../consts/hebrew';

import '../../style/genericSeatGroup.scss';

interface SeatGroupProps {
    index: number
    numOfTickets: number
    accessibilities: SeatGroupAccessibility[]
    isOrder?: boolean
    id: number
    hearingAids?: number
}
const GenericSeatGroup: React.FC<SeatGroupProps> = ({ numOfTickets, index, accessibilities, hearingAids }) => {

    const [seatgroupAccessibilities, setSeatgroupAccessibilities] = useState<SeatGroupAccessibility[]>([])

    useAsyncEffect(async () => {
        const allAccessibilities: Accessibility[] = await (await axios.get(`/api/accessibility/all`)).data;

        const usedAccessibilities = allAccessibilities.filter(accessibility =>
            (accessibility.id === 2 && hearingAids) ? true :
                accessibilities.find(seatAccessibility => seatAccessibility.accessibility.id === accessibility.id)
        )

        setSeatgroupAccessibilities(usedAccessibilities.map(accessibility => ({ accessibility, count: 0 })))
    }, [])

    return (
        <div className='whole-seatgroup'>
            <div className="seat-group-container">
                <div className='seat-group-number-container'>
                    <div className='seat-group-number'>{GenericSeatGroupEnum.SEAT_GROUP} {index + 1}</div>
                </div>
                <div className='number-of-tickets'>{numOfTickets} {GenericSeatGroupEnum.TICKETS}</div>

                <div className='accessibility-container-big'>
                    {seatgroupAccessibilities.length !== 0 &&
                        <div className='accessibility-container'>
                            <div className='accessibilty-title'>{GenericSeatGroupEnum.ACCESSIBILITY}</div>
                            {seatgroupAccessibilities.map(({ accessibility }, index) => {
                                if (accessibility.info === GenericSeatGroupEnum.REGULAR_SEATS) {
                                    return <img key={index} className={'seat-group-image'} src={'/icons-admin/accessibility/no-accessibility.svg'} alt={accessibility.info} />
                                } else {
                                    return <img key={index} className={accessibility.icon === "/image/fLbmjD5aGrcXDbJTjyFPqoTvACxREcWp.png" ? "eye-icon" : 'seat-group-image'} src={"/icons-admin" + accessibility.icon} alt={accessibility.info} />
                                }
                            })}
                        </div>
                    }
                </div>
                <br />
            </div>
        </div>
    )
}
export default GenericSeatGroup;
