import React from 'react';

import { GaveMostTicketsInterface, userTypeEnum } from '../../consts/interfaces/GaveMostTicket.interface';
import { MostInvolvedEnum, NO_DATA } from '../../consts/hebrew';
import GenericProfilePic from '../../generic-components/GenericProfilePic';
import WhiteBoxWrapper from '../../generic-components/WhiteBoxWrapper';

import '../../style/mostUserInvolved.scss'

export const GaveMostTickets: React.FC<GaveMostTicketsInterface> = ({ side, agencyOrOrg }) => {
    return (
        <WhiteBoxWrapper>
            <div className='image-text-flex'>
                <GenericProfilePic src={agencyOrOrg?.profile || ''}
                    imageCoordinates={agencyOrOrg?.imageCoordinates}
                    className="gave-most-ticket-logo" />
                <div className='gave-most-ticket-description'>
                    <p className='gave-most-ticket-text'>

                        {side === userTypeEnum.agency
                            ? MostInvolvedEnum.AGENCY
                            : MostInvolvedEnum.ORGANIZATION
                        }
                    </p>
                    <p className='gave-most-ticket-selected-user'>
                        {agencyOrOrg?.name || NO_DATA}
                    </p>
                </div>
            </div>
        </WhiteBoxWrapper>
    );
};
