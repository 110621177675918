import React from "react";
import ArrowSeparatedValuesProps from "../consts/interfaces/ArrowSeparatedValues.interface";
import '../style/arrowSeparatedValues.scss';

const ArrowSeparatedValues: React.FC<ArrowSeparatedValuesProps> = ({ arrayOfItems, className, style, arrowSize }) => {

    return (
        <div className={"arrow-separated-values-container " + (className || "")} style={style}>
            {arrayOfItems.map((item, index) => {
                return (
                    <React.Fragment key={item.title}>
                        <div className="value-container">
                            <div className="num-value">
                                {item.number}
                            </div>
                            <div className="value-title">
                                {item.title}
                            </div>
                        </div>
                        {index < arrayOfItems.length - 1 ?
                            <div className="arrow-div">
                                <img style={{ width: arrowSize === 'small' ? '2vw' : '3vw' }} src='/icons-admin/arrow.svg' />
                            </div>
                            : undefined}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default ArrowSeparatedValues;