import { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { genericPieGraphProps, optionsForPie } from '../consts/interfaces/GenericPieGraph.interface';
import { NO_DATA } from '../consts/hebrew';
import { PIE_TYPES, vh, vw } from '../consts/variables';
import '../style/genericPieGraph.scss';

const GenericPieGraph: React.FC<genericPieGraphProps> = ({
    data,
    tooltip,
    type,
    outerRadios,
    firstLineMiddle,
    secondLineMiddle,
    thirdLineMiddle,
    isLegend,
    legendFar,
    textOnHover,
    textUnderPie,
    textUnderPieOnHover,
    noData,
    style
}) => {

    const [options, setOptions] = useState<optionsForPie>()
    const [total, setTotal] = useState<number>(1)
    const [valueInMiddle, setValueInMiddle] = useState<string | number>('')
    const [textUnder, setTextUnder] = useState<string | number>('')

    const costumStyle: React.CSSProperties = {
        WebkitTextFillColor: 'black',
        direction: 'rtl',
        fontSize: '2.3vh',
        marginLeft: legendFar ? -vw * 5 : 0
    }

    useEffect(() => {
        firstLineMiddle !== undefined && setValueInMiddle(`${firstLineMiddle}`)
    }, [firstLineMiddle])

    useEffect(() => {
        textUnderPie !== undefined && setTextUnder(textUnderPie)
    }, [textUnderPie])

    useEffect(() => {
        noData &&
            setValueInMiddle(NO_DATA)
    }, [noData])

    useEffect(() => {
        if (data !== null) {
            const sum: number = data?.reduce((sum, element) => sum + element.value, 0);
            setTotal(sum)
        }
    }, [data])

    useEffect(() => {
        setPieOptions(noData ? PIE_TYPES.ONE_SLICE : type)
    }, [valueInMiddle, type, noData])

    const setPieOptions = (type: PIE_TYPES) => {
        switch (type) {
            case PIE_TYPES.EMPTY_PIE:
                setOptions({
                    innerRadius: '75%',
                    stroke: 'none',   //removes spaces between the parts
                    label: renderCustomizedLabel,
                    labelLine: false
                })
                break;
            case PIE_TYPES.ONE_SLICE:
                setOptions({
                    innerRadius: '80%',
                    cornerRadius: 12,
                    label: renderCustomizedLabel,
                    labelLine: false,
                    blendStroke: true
                })
                break;
            case PIE_TYPES.FULL_PIE:
                setOptions({
                    stroke: 'none'
                })
        }
    }

    const changeTextWhenHover = () => {
        textOnHover && setValueInMiddle(textOnHover)
        textUnderPieOnHover && setTextUnder(textUnderPieOnHover)
    }

    const changeTextAfterHover = () => {
        textOnHover && firstLineMiddle && setValueInMiddle(firstLineMiddle)
        textUnderPieOnHover && textUnderPie && setTextUnder(textUnderPie)
    }

    const renderCustomizedLabel = (props: any) => {
        const { cx, cy } = props
        return (
            <>
                <text fontWeight={'700'} opacity={valueInMiddle.toString() === firstLineMiddle?.toString() ? 1 : 0.3} fontSize={noData ? '2.2vh' : secondLineMiddle ? '3.5vh' : '4vh'}
                    x={cx} y={secondLineMiddle ? '44%' : cy} fill="#41596b" textAnchor="middle" dominantBaseline="middle" fontFamily='Assistant, sans-serif'>
                    {valueInMiddle}
                </text>
                <text fontSize={thirdLineMiddle ? '1.8vh' : '2.4vh'} x={cx} y={'58%'} fill="#41596b" textAnchor="middle" dominantBaseline="middle" fontFamily='Assistant, sans-serif'>
                    {secondLineMiddle}
                </text>
                {thirdLineMiddle &&
                    <text fontSize={'1.8vh'} x={cx} y={'68%'} fill="#41596b" textAnchor="middle" dominantBaseline="middle" fontFamily='Assistant, sans-serif'>
                        {thirdLineMiddle}
                    </text>}
            </>
        );
    };

    const CustomTooltip = (props: any) => {
        const { active, payload } = props;
        if (active && payload && payload.length && total) {
            const precent = Number((payload[0]?.value) * 100) / total
            const shortPrecent = `${precent}`.slice(0, 5)
            return (
                <div className="custom-tooltip">
                    <p className='precent'>{`${shortPrecent}%`}</p>
                    <p>{`${payload[0].payload.tooltip}`}</p>
                </div>
            );
        }
        return null;
    };

    const tooltipContent = (props: any) => {
        const { active, payload, label } = props;
        return (
            <CustomTooltip style={{ outline: 'none' }} active={active} payload={payload} label={label} />
        );
    };

    return (
        <div className='wrapper-pie' style={{ width: 'fit-content', height: '100%', ...(style && { ...style }) }}
            onMouseEnter={changeTextWhenHover}
            onMouseLeave={changeTextAfterHover}>
            {data &&
                <ResponsiveContainer height={'100%'} width={'100%'}>
                    <PieChart>
                        {tooltip !== undefined && tooltip && !noData &&
                            <Tooltip allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{ outline: 'none' }} content={tooltipContent} />}
                        <Pie
                            data={noData ? data.map((entry, index) => index === 0 ? ({ ...entry, value: 1 }) : entry) : data}
                            dataKey="value"
                            startAngle={90}
                            endAngle={480}
                            outerRadius={outerRadios || '100%'}
                            {...options}
                        >
                            {data.map((entry, index) => (
                                <Cell style={{ outline: 'none' }}
                                    key={`sector-${index}`}
                                    fill={noData ? "#e4f1f0" : entry.color}
                                />
                            ))}
                        </Pie>
                        {isLegend &&
                            <Legend
                                payload={data.map(entry => ({
                                    value: entry.name,
                                    color: entry.color,
                                    type: "square"
                                }))}
                                iconSize={vh * 1.2}
                                iconType="square"
                                wrapperStyle={costumStyle}
                                verticalAlign={'middle'}
                                align={'left'}
                                key={'value'}
                                width={vh * 38}
                                formatter={(value) => <span className="legend-label">{value}</span>}
                            />}
                    </PieChart>
                </ResponsiveContainer>
            }
            {textUnder &&
                <p className='text-under-pie'>{textUnder}</p>
            }
        </div >
    )
}

export default GenericPieGraph