import clsx from 'clsx';
import BarSeparatedValuesProps from '../consts/interfaces/BarSeparatedValues.interface';
import '../style/barSeparatedValues.scss';

const BarSeparatedValues: React.FC<BarSeparatedValuesProps> = ({ num1, num2, num1title, num2title, className, style }) => {

    return (
        <div className={clsx("bar-separated-values-container", className)} style={style}>
            <div className="value-container">
                <div className="num-value">
                    {num1}
                </div>
                <div className="value-title">
                    {num1title}
                </div>
            </div>

            <div className="value-container">
                <div className="num-value">
                    {num2}
                </div>
                <div className="value-title">
                    {num2title}
                </div>
            </div>
        </div>
    )
}

export default BarSeparatedValues;