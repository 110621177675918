import { useContext, useEffect, useState } from "react"
import ItemInSystemProfile from "./ItemInSystemProfile"
import { SystemTypeContext, systemTypeContextProps } from "../context/SystemTypeContext"
import { PROFILE } from "../consts/variables"
import { SYSTEM_TYPE } from "../consts/interfaces/SpecificStatistics.interface"
import { EditSystemProfileContext } from "../context/SystemProfileContexts"

const SystemName: React.FC<{ systemNameCx: string }> = ({ systemNameCx, }) => {
    const { systemType } = useContext(SystemTypeContext) as systemTypeContextProps
    const { isEditMode, saveDataByIdentifier } = useContext(EditSystemProfileContext) || {}
    const [systemName, setSystemName] = useState<string>(systemNameCx)

    useEffect(() => {
        setSystemName(systemNameCx)
    }, [isEditMode, systemNameCx])

    return (
        <ItemInSystemProfile
            name={systemType === SYSTEM_TYPE.organization ? PROFILE.NAME_OF_ORG : PROFILE.NAME_OF_AGENCY}
            data={systemName}
            setData={setSystemName}
            saveDataByIdentifier={saveDataByIdentifier}
            isEdit={isEditMode}
            id={systemType === SYSTEM_TYPE.organization ? "organizationName" : "agencyName"}
        />
    )
}
export default SystemName