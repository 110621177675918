import React, { useContext, useEffect, useState } from "react";
import { useAsyncEffect } from "@hilma/tools";

import { averageTimeOfPurchase, eventsOccurrence, FeedbacksPerEvent, ticketUtilizationInterface } from "../consts/interfaces/StatisticsContext.interface";
import { agencyStatContext } from "../consts/interfaces/AgencyStatisticsContext.interface";
import { specificStatistics } from "../consts/interfaces/SpecificStatistics.interface";

import { eventsOccurrenceInfo, postToPurchaseInfo, receivedOrganizations, ticketsDivisionInfo, worthOfDonationInfo } from "../utils/api/agency-statistics";
import { SpecificStatisticsContext } from "./SpecificStatisticsContext";
import { useThankYouFeedbacksForAgencyInfo } from "../hooks/api/feedback";

export const AgencyStatisticsContext = React.createContext<agencyStatContext | null>(null);

export const AgencyStatisticsProvider: React.FC = ({ children }) => {
    const { filterDate, systemId } = useContext(SpecificStatisticsContext) as specificStatistics
    const [receivedOrgsFromSpecific, setReceivedOrgsFromSpecific] = useState<number>(0)
    const [worthOfAgencyDonation, setWorthOfAgencyDonation] = useState<number>(0)
    const [ticketUtilization, setTicketUtilization] = useState<ticketUtilizationInterface>({
        allPurchased: 0,
        notPurchased: 0,
        aboveHalf: 0,
        underHalf: 0,
        totalEvents: 0
    })
    const [averageTimeFromPost, setAverageTimeFromPost] = useState<averageTimeOfPurchase>({
        hours: 0,
        minutes: 0
    })
    const [eventsOccurrenceCount, setEventsOccurrenceCount] = useState<eventsOccurrence>({
        canceledAmount: 0,
        occurredAmount: 0
    })
    const [feedbacksOfAgency, setFeedbacksOfAgency] = useState<FeedbacksPerEvent[]>([
        {
            name: "",
            date: "",
            feedbacks: [
                {
                    feedbackId: 0,
                    feedbackText: "",
                    images: [""],
                    profilePhoto: "",
                    organizationName: "",
                }
            ]
        }
    ]);

    useAsyncEffect(async () => {
        if (systemId) {
            getReceivedOrganizations()
            getWorthOfDonation()
            getTicketsDivision()
            postToPurchase()
            ThankYouFeedbacksForAgency()
            eventsOccurrence()


        }
    }, [systemId, filterDate])

    // get the amount of how many organizations received tickets from this agency
    const getReceivedOrganizations = async () => {
        const received = await receivedOrganizations(systemId)
        setReceivedOrgsFromSpecific(received)
    }
    const getTicketsDivision = async () => {
        const ticketsDivision = await ticketsDivisionInfo(systemId, filterDate)
        setTicketUtilization(ticketsDivision)
    }

    //gets the total donation worth 
    const getWorthOfDonation = async () => {
        const worthOfDonation = await worthOfDonationInfo(systemId, filterDate)
        setWorthOfAgencyDonation(worthOfDonation)
    }

    //gets the average time from post to purchase
    const postToPurchase = async () => {
        const data = await postToPurchaseInfo(systemId, filterDate)
        setAverageTimeFromPost(data)
    }

    //gets the amount of how many events were canceled and how many were occurred
    const eventsOccurrence = async () => {
        const canceledAndOccurredEvents = await eventsOccurrenceInfo(systemId)
        setEventsOccurrenceCount(canceledAndOccurredEvents)
    }
    const ThankYouFeedbacksForAgency = async () => {
        const data = await useThankYouFeedbacksForAgencyInfo(systemId)
        setFeedbacksOfAgency(data)
    }
    return (
        <AgencyStatisticsContext.Provider value={{
            receivedOrgsFromSpecific,
            feedbacksOfAgency,
            worthOfAgencyDonation,
            ticketUtilization,
            averageTimeFromPost,
            eventsOccurrenceCount
        }}>
            {children}
        </AgencyStatisticsContext.Provider>
    )
}
