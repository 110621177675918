import { useContext, useEffect, useState } from "react"

import { HorizontalGraphData } from "../consts/interfaces/GenericHorizontalGraph.interface"
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface"
import { ORGANIZATION_TYPES, TO_ALL_ORGANIZATION_TYPES } from "../consts/hebrew"
import { vw } from "../consts/variables"

import { StatisticsContext } from "../context/StatisticsContext"
import GenericHorizontalGraph from "../generic-components/GenericHorizontalGraph"
import BottomTextArrow from "../generic-components/BottomTextArrow"
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper"
import PopupOrganizationDemographics from "./popups/PopupOrganizationDemographics"


const OrganizationDemographicsOrdered: React.FC = () => {
    const { orgDemographicsOrdered } = useContext(StatisticsContext) as StatContextInterface

    const [data, setData] = useState<HorizontalGraphData>([])
    const [popup, setPopup] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>()

    useEffect(() => {
        const formattedData: HorizontalGraphData = orgDemographicsOrdered.map(({ demographicName, count }) => ({ name: demographicName, value: count }))
        setData(formattedData)

        setNoData(formattedData[0].value === 0)
    }, [orgDemographicsOrdered])

    return (
        <WhiteBoxWrapper>
            <div className="stats-title">{ORGANIZATION_TYPES}</div>
            <GenericHorizontalGraph
                data={data.slice(0, 5)}
                style={{ width: '30vw', height: '30vh' }}
                filledBars={noData}
                labelsWidth={9 * vw}
            />
            {!noData &&
                <BottomTextArrow bottomText={TO_ALL_ORGANIZATION_TYPES} onBottomTextClick={() => setPopup(true)} />}

            <PopupOrganizationDemographics demographics={data} open={popup} handleClose={() => setPopup(false)} />
        </WhiteBoxWrapper>
    )
}


export default OrganizationDemographicsOrdered;