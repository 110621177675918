import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchEventOffersById, fetchEventsEnterLive, createTicketsActuallyPurchased } from "../../utils/api/event";

export function getQueryKeyForEventOffers(eventId: number) {
    return ['events', eventId, 'offers'];
}
export function useEventOffersQuery(eventId: number) {
    return useQuery(
        getQueryKeyForEventOffers(eventId),
        () => fetchEventOffersById(eventId),
    );
}
export function useEventsEnterLiveQuery() {
    return useQuery(['eventsEnterLive'], fetchEventsEnterLive);
}

export function useCreateTicketActuallyPurchasedMutation() {
    return useMutation(createTicketsActuallyPurchased);
}