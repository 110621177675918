
export interface specificStatistics {
    filterDate?: Date,
    systemId: string,
    systemType: SYSTEM_TYPE,
    countOfEvents: number,
    totalFeedbacks: number,
    setFilterDateContext: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

export enum SYSTEM_TYPE {
    agency = 'agency',
    organization = 'organization'
}
export interface orgTickets {
    receivedCount: number,
    purchasedCount: number
}