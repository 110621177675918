import WhiteBoxWrapper from "./WhiteBoxWrapper";
import BottomTextArrow from "./BottomTextArrow";
import { maxFeedbackHeartsCount } from '../consts/variables';
import '../style/genericFeedbackStats.scss';

const GenericFeedbackStats: React.FC<{ feedbackCount: number, text: string, style?: React.CSSProperties, bottomText?: string, onBottomTextClick?: React.MouseEventHandler<HTMLDivElement> }> = ({ feedbackCount, text, style, bottomText, onBottomTextClick }) => {

    return (
        <WhiteBoxWrapper>
            <div className={"feedback-stats"} style={style}>
                <div className="hearts-container">
                    {Array(maxFeedbackHeartsCount).fill(1).map((_, index) =>
                        <img
                            key={index}
                            src={index < feedbackCount ? '/icons-admin/heart.svg' : '/icons-admin/heart-gray.svg'}
                            alt={index < feedbackCount ? 'Filled Heart' : 'Gray Heart'}
                        />)}
                </div>
                <div className="description">
                    <div className="count">
                        {feedbackCount}
                    </div>
                    <div className="text">
                        {text}
                    </div>

                    {(bottomText && onBottomTextClick && feedbackCount !== 0) &&
                        <BottomTextArrow bottomText={bottomText} onBottomTextClick={onBottomTextClick} />
                    }
                </div>
            </div>
        </WhiteBoxWrapper>
    )
}

export default GenericFeedbackStats;