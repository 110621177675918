import { useMemo } from 'react';
import clsx from "clsx";
import GenericProfilePicProps from '../consts/interfaces/GenericProfilePic.interface';
import '../style/genericEventCover.scss';

const GenericEventCover: React.FC<GenericProfilePicProps> = ({ src, imageCoordinates, className, style }) => {
    const imageStyle = useMemo((): React.CSSProperties => (src && imageCoordinates ? {
        position: 'absolute',
        width: `${100 * 100 / imageCoordinates.width}%`, // makes the img bigger so the visible part is smaller
        left: `${-imageCoordinates.x * 100 / imageCoordinates.width}%`, // moves the img left so the cropped part moves right, proportionate to the img width
        top: `${-imageCoordinates.y * 100 / imageCoordinates.height}%` // moves the img up so the cropped part moves down, proportionate to the img height
    } : {}), [src, imageCoordinates])

    return (
        <div className={clsx("event-cover-wrapper", className)} style={style}>
            <img
                style={imageStyle}
                src={src}
                alt="event-cover-image"
            />
        </div>
    )
}
export default GenericEventCover;