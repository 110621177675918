import { IconButton, Modal } from "@mui/material";
import GenericPopupInterface from "../consts/interfaces/GenericPopupInterface";
import GenericButton from "./GenericButton";
import '../style/genericYesNoPopup.scss';

const GenericYesNoPopup: React.FC<GenericPopupInterface> = (props) => {

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown
    >

      <div className={props.image ? "popup-with-img-yes-no" : 'the-popup-yes-no'}>
        <div className='flex-center'>

          {props.image ?
            <>
              <IconButton sx={{ width: "8vh", height: "8vh" }} onClick={props.handleClose}>
                <span className='close-button-text'>×</span>
              </IconButton>
              <div className='photo-popup'>
                <img src={props.image} className="the-img" alt="" />
              </div>
            </>
            :
            props.type && props.type !== "acceptOrDeny" ?
              <div className="thumbs-up-popup">
                <IconButton sx={{ width: "8vh", height: "8vh" }} onClick={props.handleClose}>
                  <span className='close-button-thumbs-up'>×</span>
                </IconButton>
                <div className="title-and-thumbs-up">
                  <div className='the-title-thumbs-up'>
                    {props.title}
                  </div>
                  <div className="animation-thumbs-up">
                    <img src="/icons-admin/thumbs-up.png" alt="thumbs up" className="thumbs-up" />
                  </div>
                </div>
              </div>
              :
              <>
                <div className='the-title'>
                  {props.title}
                </div>
                <div className="yes-or-no-buttons">
                  <GenericButton className={"turquoise small"} handleClick={props.handleYes} name={"delete"}>כן</GenericButton>
                  <GenericButton className={"yellow small"} handleClick={props.handleClose} name={"dont delete"}>לא</GenericButton>
                </div>
              </>
          }

        </div>
      </div>

    </Modal>
  )
}

export default GenericYesNoPopup;
