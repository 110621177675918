import { useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import NotRelevantEventProps from "../consts/interfaces/NotRelevantEvent.interface";
import { OTHER } from "../consts/hebrew";

import NotRelevantEvent from "../generic-components/NotRelevantEvent";

import '../style/canceledEventsDescription.scss';


const CanceledEventsDescription = () => {
    const [data, setData] = useState<NotRelevantEventProps[]>([])

    useAsyncEffect(async () => {
        const data: { eventName: string, date: string, agencyName: string, text: string, profilePhoto: string }[] = (await axios.get('/api/statistics/cancelation-other-reasons')).data
        const formattedData: NotRelevantEventProps[] = data.map((item) => ({
            ...item,
            organizationName: item.agencyName,
            organizationProfile: item.profilePhoto
        }))

        setData(formattedData)
    }, [])


    return (
        <div className="canceled-events-description">
            {data.length !== 0 &&
                <div>
                    <div className="canceled-desc-title">{OTHER}</div>
                    {data.map(event =>
                        <NotRelevantEvent {...event} />
                    )}
                </div>}
        </div>
    )
}

export default CanceledEventsDescription;