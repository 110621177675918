import { useMemo } from "react";

import { averageTimeOfPurchase, daysIndicatorProps, timeIndicatorProps } from "../consts/interfaces/StatisticsContext.interface";
import { AVERAGE_TIME_USE_SINCE_POST, DAYS, HOURS, HOW_LONG_ENTER_TOOK_TO_FILL_THE_LINE, MINUTES } from "../consts/hebrew";
import GenericInfoToolTip from "../generic-components/GenericInfoToolTip";
import '../style/averageTimeFromPost.scss';

const AverageTimeTillUseFromPost: React.FC<{
    averageTimeFromPost: averageTimeOfPurchase
    textUnder?: string
    style?: { width: string }
    setStages?: React.Dispatch<React.SetStateAction<{
        text: string;
        color?: string | undefined;
    }[]>>,
}> = ({ setStages, textUnder, averageTimeFromPost, style }) => {

    const days = useMemo(() => {
        return (Math.floor(averageTimeFromPost.hours / 24))
    }, [averageTimeFromPost.hours]);

    const onMouseEnter = () => {
        if (!setStages) return

        const hoverColor = "#FDC100";
        setStages && setStages(prev =>
            prev.map((stage, index) => index === 3 ? stage : ({ ...stage, color: hoverColor })))

    }

    const onMouseLeave = () => {
        setStages?.(prev => prev.map(({ text }) => ({ text })))
    }

    return (
        <div className="average-time-from-post" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={style}>
            {days === 0 ?
                <TimeIndicator className={setStages ? 'time' : 'time-specific'}
                    minutes={{ value: averageTimeFromPost.minutes, label: MINUTES }}
                    hours={{ value: averageTimeFromPost.hours, label: HOURS }} />
                :
                <DaysIndicator value={days} label={DAYS} />}
            <div className="description">
                {textUnder || AVERAGE_TIME_USE_SINCE_POST}
                {!textUnder && <GenericInfoToolTip text={HOW_LONG_ENTER_TOOK_TO_FILL_THE_LINE} />}
            </div>
        </div>
    )
}

export default AverageTimeTillUseFromPost;

const DaysIndicator: React.FC<daysIndicatorProps> = ({ label, value }) => {
    return (
        <div className="days-number">{value} <span className="days">{label}</span></div>
    )
}

const TimeIndicator: React.FC<timeIndicatorProps> = ({ hours, minutes, className }) => {
    return (
        <div className={className}>
            <div className="time-unit">
                <div className="number">
                    {minutes.value}
                </div>
                <div className="text">
                    {minutes.label}
                </div>
            </div>
            :
            <div className="time-unit">
                <div className="number">
                    {hours.value}
                </div>
                <div className="text">
                    {hours.label}
                </div>
            </div>
        </div>
    )
}