import { useEffect, useState } from "react";
import { HorizontalGraphData } from "../consts/interfaces/GenericHorizontalGraph.interface";
import { eventsCategoriesOrdered } from "../consts/interfaces/StatisticsContext.interface";
import { EVENT_TYPES, TO_ALL_EVENT_CATEGORIES } from "../consts/hebrew";

import GenericHorizontalGraph from "../generic-components/GenericHorizontalGraph";
import BottomTextArrow from "../generic-components/BottomTextArrow";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";
import PopupEventCategories from "./popups/PopupEventCategories";

const EventCategoriesOrdered: React.FC<{ eventCategoriesOrdered: eventsCategoriesOrdered[] }> = ({ eventCategoriesOrdered }) => {
    const [data, setData] = useState<HorizontalGraphData>([])
    const [popup, setPopup] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>()

    useEffect(() => {
        const formattedData: HorizontalGraphData = eventCategoriesOrdered.map(({ categoryName, count }) => ({ name: categoryName, value: count }))
        setData(formattedData)

        setNoData(formattedData[0]?.value === 0 || !formattedData)
    }, [eventCategoriesOrdered])

    return (
        <WhiteBoxWrapper>
            <div className="stats-title">{EVENT_TYPES} </div>
            <GenericHorizontalGraph
                data={data.slice(0, 5)}
                style={{ width: '30vw', height: '30vh' }}
                filledBars={noData}
            />

            {!noData &&
                <BottomTextArrow bottomText={TO_ALL_EVENT_CATEGORIES} onBottomTextClick={() => setPopup(true)} />}

            <PopupEventCategories categories={data} open={popup} handleClose={() => setPopup(false)} />
        </WhiteBoxWrapper>
    )
}

export default EventCategoriesOrdered;