import { useContext } from "react";
import { AVERAGE_NUMBER_ORGANIZATIONS_OFFERED, HOW_MANY_ORGANIZATIONS_THE_EVENT_PASSED } from "../consts/hebrew";
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";
import { StatisticsContext } from "../context/StatisticsContext";
import GenericInfoToolTip from "../generic-components/GenericInfoToolTip";
import '../style/averageOfferedOrganizations.scss';

const AverageOfferedOrganizations: React.FC<{
    setStages: React.Dispatch<React.SetStateAction<{
        text: string;
        color?: string | undefined;
    }[]>>,
}> = ({ setStages }) => {
    const { averageOfferedOrgs } = useContext(StatisticsContext) as StatContextInterface

    const onMouseEnter = () => {
        const hoverColor = "#FF5A5A";

        setStages(prev =>
            prev.map((stage, index) => index === 1 ? ({ ...stage, color: hoverColor }) : stage))
    }

    const onMouseLeave = () => {
        setStages(prev => prev.map(({ text }) => ({ text })))
    }

    return (
        <div className="average-offered-organizations" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="number">
                {Math.round(averageOfferedOrgs)}
            </div>
            <div className="description">
                {AVERAGE_NUMBER_ORGANIZATIONS_OFFERED}
                <GenericInfoToolTip text={HOW_MANY_ORGANIZATIONS_THE_EVENT_PASSED} />
            </div>
        </div>
    )
}

export default AverageOfferedOrganizations;