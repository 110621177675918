import clsx from "clsx"
import { TableCell } from "@mui/material"
import { activityStatusProps } from "../consts/interfaces/ActivityStatus.interface"
import { ACTIVE, SUSPENDED } from "../consts/hebrew"
import "../style/activityStatus.scss"

const ActivityStatus: React.FC<activityStatusProps> = ({ activityStatus }) => {

    return (
        <TableCell align="center" >
            <div className={clsx("status", activityStatus ? "active" : "suspended")} >
                {activityStatus ? ACTIVE : SUSPENDED}
            </div>
        </TableCell>
    )
}
export default ActivityStatus