import React, { useMemo } from 'react';
import { Bar, BarChart, Cell, LabelProps, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import GenericHorizontalGraphProps from '../consts/interfaces/GenericHorizontalGraph.interface';
import { vh, vw } from '../consts/variables';

import '../style/genericHorizontalGraph.scss';

const GenericHorizontalGraph: React.FC<GenericHorizontalGraphProps> = ({ data, className, style, filledBars, labelsWidth, onClick, onClickText }) => {
    const colors = useMemo(() =>
        (filledBars) ?
            ['#F6D465', '#95C1CE', '#EDA424', '#08605F', '#D64045'] :
            ['#EDA424', '#08605F', '#F6D465', '#95C1CE', '#D64045']
        , [filledBars])

    const renderCustomizedLabel = (props: LabelProps) => {
        const { value, y } = props

        return (
            <text fill="#41596B" opacity='50%' fontWeight='500' y={Number(y) + 1.8 * vh} textAnchor='end' x={vw}>
                {value}
            </text>
        );
    };

    return (
        <div className={"bar-chart-container " + className} style={style}>
            <div className='bar-chart-wrapper'>
                <ResponsiveContainer height='100%' width='100%'>
                    <BarChart
                        data={filledBars ? data : data.sort((a, b) => b.value - a.value)}
                        layout="vertical"
                        margin={{ left: 1.5 * vw }}
                    >
                        <YAxis type="category" dataKey="name" width={labelsWidth || 4.3 * vw}
                            orientation='right' stroke='transparent'
                            tick={props =>
                                <foreignObject
                                    width={labelsWidth || 80}
                                    x={props.x}
                                    y={props.y}
                                    overflow='visible'
                                >
                                    <div
                                        style={{
                                            ...(onClickText?.includes(props.payload.value) && {
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }),
                                            translate: '0 -50%',
                                            textAlign: "left",
                                        }}
                                        className="category-label"
                                        onClick={onClickText?.includes(props.payload.value) ? onClick : undefined}
                                    >
                                        {props.payload.value}
                                    </div>
                                </foreignObject>
                            }
                        />
                        <XAxis type="number" hide reversed />

                        <Bar
                            dataKey="value"
                            label={filledBars ? renderCustomizedLabel : { position: 'right', fill: "#41596B", opacity: '50%', fontWeight: '500' }}
                            barSize={2.5 * vh}
                            radius={15}
                            background={filledBars && { fill: "#EBEDEF", radius: 15 }}
                            isAnimationActive={false}
                        >
                            {data.map((_, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div >
        </div >
    )
}

export default GenericHorizontalGraph;