import { useContext, useEffect, useState } from "react";

import { EVENT_START, OFFER_ORGANIZATIONS, POST_EVENT, TICKETS_USE } from "../consts/hebrew";
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";
import { StatisticsContext } from "../context/StatisticsContext";

import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";
import ArrowsTimeline from "../generic-components/ArrowsTimeline";

import AverageTimeTillUseFromOffer from "./AverageTimeTillUseFromOffer";
import AverageOfferedOrganizations from "./AverageOfferedOrganizations";
import AverageTimePostBeforeEvent from "./AverageTimePostBeforeEvent";
import AverageTimeTillUseFromPost from "./AverageTimeTillUseFromPost";

import '../style/eventTimeline.scss';

const EventTimeline: React.FC = () => {
    const { averageTimeFromPost } = useContext(StatisticsContext) as StatContextInterface

    const [stages, setStages] = useState<{ text: string, color?: string }[]>([])

    useEffect(() => {
        const stages = [{ text: POST_EVENT }, { text: OFFER_ORGANIZATIONS }, { text: TICKETS_USE }, { text: EVENT_START }]

        setStages(stages)
    }, [])

    return (
        <WhiteBoxWrapper>
            <div className="event-timeline">
                <div className="timeline-container">
                    {stages.map(stage =>
                        <ArrowsTimeline key={stage.text} text={stage.text} hoverColor={stage.color} />)}
                </div>

                <div className="details-container">
                    <AverageTimeTillUseFromPost setStages={setStages} averageTimeFromPost={averageTimeFromPost} />
                    <hr />
                    <AverageOfferedOrganizations setStages={setStages} />
                    <hr />
                    <AverageTimeTillUseFromOffer setStages={setStages} />
                    <hr />
                    <AverageTimePostBeforeEvent setStages={setStages} />
                </div>
            </div>
        </WhiteBoxWrapper>
    )
}

export default EventTimeline;