import { useEffect, useState } from 'react';
import { IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { PopupDetailsOfTicketsEventProps, ticketsActuallyPurchased } from '../../consts/interfaces/PopupDetailsOfTicketsEvent.interface';
import { OrgOfferProfile } from '../../consts/interfaces/AgencyEventFlow.interface';

import { headCellsTicketsDetailsPopup } from '../../generic-components/GenericTableHeadCells';
import GenericButton from '../../generic-components/GenericButton';
import { useCreateTicketActuallyPurchasedMutation } from '../../hooks/api/event';

import '../../style/popupDetailsOfTicketEvent.scss'

const PopupDetailsOfTicketsEvent: React.FC<PopupDetailsOfTicketsEventProps> = ({ open, handleClose, artistName, agencyName, tableData, eventId }) => {
    const { mutate, isLoading } = useCreateTicketActuallyPurchasedMutation();

    const [ticketsActuallyPurchased, setTicketsActuallyPurchased] = useState<ticketsActuallyPurchased[]>([])
    const [localTableData, setLocalTableData] = useState<OrgOfferProfile[]>([]);


    useEffect(() => {
        setLocalTableData(tableData);
    }, [tableData]);

    const handleChangeTicketsActuallyPurchased = (e: React.ChangeEvent<HTMLInputElement>, orgId?: number) => {
        const { value } = e.target;
        const updatedArray = ticketsActuallyPurchased.map((item: ticketsActuallyPurchased) =>
            item.orgId === orgId ? { ...item, numTicketsActuallyPurchased: Number(value) } : item
        );

        const isOrgIdPresent = ticketsActuallyPurchased.some((item: ticketsActuallyPurchased) => item.orgId === orgId);
        if (!isOrgIdPresent) {
            updatedArray.push({ orgId, numTicketsActuallyPurchased: Number(value), eventId });
        }

        setTicketsActuallyPurchased(updatedArray);

        const updatedLocalTableData: OrgOfferProfile[] = localTableData.map((item: OrgOfferProfile) =>
            item.id === orgId ? { ...item, ticketsWereActuallyPurchasedUsingCoupon: Number(value) } : item
        );
        setLocalTableData(updatedLocalTableData);
    };

    const handleUpdateTicketsActuallyPurchasedByAdmin = (e: object) => {
        if (ticketsActuallyPurchased) {
            mutate(ticketsActuallyPurchased);
        }
        handleClose(e);
    };
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown
            >
                <div className='details-ticket-event-live' >
                    <div className='header-ticket-event-popup'>
                        <div className='close-button'>
                            <IconButton sx={{ width: "10vh", height: "10vh", }} onClick={handleClose}>
                                <span className='close-button-text-popup-details-tickets'>×</span>
                            </IconButton>
                        </div>
                        <div className='title-ticket-event-popup'>
                            <h3 className='title'>פירוט מימוש כרטיסים</h3>
                            <div className='sub-title-ticket-event-popup'>
                                <p><span>שם המוסד</span><span className='artist-name'>{artistName}</span></p>
                                <span className='sub-title-divider'>|</span>
                                <p><span>שם האירוע</span><span className='agency-name'>{agencyName}</span></p>
                            </div>
                        </div>
                    </div>
                    <Table stickyHeader className='table'>
                        <TableHead className='table-head'>
                            <TableRow>
                                {headCellsTicketsDetailsPopup.map((headCell) => (
                                    <TableCell className='table-head-row' key={headCell.id} align="center" >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {localTableData?.map((tableCell: OrgOfferProfile) => (
                                <TableRow key={tableCell.id} className='table-body-row'>
                                    <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                                        <input
                                            className='table-body-cell-input'
                                            onChange={(e) => handleChangeTicketsActuallyPurchased(e, tableCell.id)}
                                            type="number"
                                            value={tableCell?.ticketsWereActuallyPurchasedUsingCoupon}
                                            placeholder='שדה טקסט' />
                                    </TableCell>
                                    <TableCell align="center">{tableCell?.countOfPurchasedTickets}</TableCell>
                                    <TableCell align="center" className='table-body-cell-name-img'>
                                        {tableCell.name}
                                        <div className='img-box'>
                                            <img
                                                src={tableCell.profilePhoto}
                                                style={{ height: "90%" }}
                                                alt='profilePic'
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className='save-btn-popup-tickets-details'>
                        <GenericButton disabled={isLoading} className='yellow btn-save' handleClick={handleUpdateTicketsActuallyPurchasedByAdmin}>
                            שמירה
                        </GenericButton>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PopupDetailsOfTicketsEvent;
