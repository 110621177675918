import { useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { GenericGraphBarProps } from "../consts/interfaces/GenericGraphBar.interface";
import BarGraphTooltip from "./BarGraphTooltip";
import { NO_DATA } from "../consts/hebrew";
import { vw } from "../consts/variables";

import '../style/genericBarGraph.scss';

const GenericBarGraph: React.FC<GenericGraphBarProps> = ({ data, bar1, bar2, className, style }) => {

    const [activeCategory, setActiveCategory] = useState<number>()

    const formatLongTick = (value: string) => {
        let maxTickWidth;
        switch (data.length) {
            case 5:
                maxTickWidth = 10
                break;
            case 4:
                maxTickWidth = 13
                break;
            case 3:
                maxTickWidth = 17
                break;
            case 2:
                maxTickWidth = 24
                break;
            default:
                maxTickWidth = 50
                break;
        }
        return value.length > maxTickWidth ? value.slice(0, maxTickWidth - 1) + '...' : value
    }

    return !data || data.length === 0 ?
        <div className="no-data">{NO_DATA}</div>
        :
        <div className={"bar-chart-vertical " + className} style={{ width: '100%', height: '100%', ...(style && { ...style }) }}>
            <ResponsiveContainer height={'100%'} width={'100%'}>
                <BarChart data={data} barCategoryGap="5%" barGap="5%" barSize={1.7 * vw}
                    onMouseMove={state => {
                        (state.isTooltipActive) ?
                            setActiveCategory(state.activeTooltipIndex) : setActiveCategory(undefined)
                    }}
                >
                    <CartesianGrid vertical={false} stroke="#F0F0F0" />
                    <XAxis dataKey="name"
                        axisLine={{ stroke: '#F0F0F0' }}
                        tickLine={{ stroke: 'transparent' }}
                        stroke="#555555"
                        fontFamily={'Assistant, sans-serif'}
                        interval={0}
                        tick={{ direction: 'rtl' }}
                        tickFormatter={formatLongTick}
                    />
                    <YAxis
                        axisLine={{ stroke: '#F0F0F0' }}
                        tickLine={{ stroke: 'transparent' }}
                        type="number"
                        tickCount={8}
                        fontFamily={'Assistant, sans-serif'}
                    />
                    <Tooltip
                        cursor={{ fill: 'transparent' }}
                        wrapperStyle={{ outline: "none" }}
                        content={<BarGraphTooltip />} />

                    <Legend iconType="square" iconSize={10} wrapperStyle={{ whiteSpace: 'nowrap', direction: 'rtl' }}
                        formatter={(value) => <span className="legend-label">{value}</span>}
                    />
                    <Bar dataKey='bar1Value' name={bar1} fill="#95C1CE">
                        {data.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={activeCategory === index ? "#7da1ab" : "#95C1CE"} />
                        ))}
                    </Bar>
                    <Bar dataKey='bar2Value' name={bar2} fill="#FDC100">
                        {data.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={activeCategory === index ? "#b47d1e" : "#EDA424"} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
}

export default GenericBarGraph;