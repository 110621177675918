import React from "react";
import { ButtonBase } from "@mui/material";
import { GenericButtonProps } from "../consts/interfaces/GenericButton.interface";
import GenericLoading from "./GenericLoading";
import "../style/genericButton.scss";

const GenericButton: React.FC<GenericButtonProps> = (props) => {
  const handleKeyPress = (event: any) => {
    const form = event.target.form;
    if (event.key.toLowerCase() === "arrowup") {
      form.elements[0].focus();
    }
  }

  return (
    <ButtonBase
      disabled={props.disabled || props.serverLoading}
      className={`generic-button ${props.className ? props.className : ""} ${props.disabled ? "disabled" : ""}`}
      onKeyDown={(event) => { handleKeyPress(event) }}
      onClick={props.handleClick}
    >
      <div className={`${props.serverLoading ? 'loading-container' : 'text-on-button'}`}>
        {props.serverLoading ? <GenericLoading /> : props.children}
      </div>
    </ButtonBase>
  );
}


export default GenericButton;

