import { useContext, useState } from "react";
import { useFiles } from '@hilma/fileshandler-client'
import { Skeleton } from "@mui/material";
import { useAgencyProfileQuery, useEditAgency, useEditOrganization, useOrganizationProfileQuery } from "../hooks/api/system-profile";
import { AgencyAddressContext, OrgConfigurationContext, OrgFilesProvider, SystemContactsContext, EditSystemProfileContext, SystemProfileImageContext, SystemTermContext } from "./SystemProfileContexts";
import { CommonProfileProviderProps, EditSystemProfileProviderProps } from "../consts/interfaces/systemProfileProviders.interface";
import { BothEditAbleFields, SaveSystemDataByIdentifier } from "../consts/interfaces/SystemProfile.interface";
import { useSystemInfo } from "../generic-components/GenericSystemProfile";
import { CONFIRM_DISCARD_CHANGES } from "../consts/hebrew";
import GenericYesNoPopup from "../generic-components/GenericYesNoPopup";
import { ErrorContext } from "./ErrorContext";
import { msgStatus } from "../consts/variables";

export const CommonProfileProvider: React.FC<CommonProfileProviderProps> = ({ children, profilePhoto, imageCoordinates, systemName, termsDate, termsName, contacts, editMutation, refetch, filesUploader, isActive }) => {
    return (
        <EditSystemProfileProvider editMutation={editMutation} refetch={refetch} filesUploader={filesUploader}>
            <SystemProfileImageContext.Provider value={{ profilePhotoCx: profilePhoto, systemNameCx: systemName, imageCoordinatesCx: imageCoordinates, isActive }}>
                <SystemTermContext.Provider value={{ termNameCx: termsName, termDateCx: termsDate }}>
                    <SystemContactsContext.Provider value={{ contactsCx: contacts }}>
                        {children}
                    </SystemContactsContext.Provider>
                </SystemTermContext.Provider>
            </SystemProfileImageContext.Provider>
        </EditSystemProfileProvider>
    )
};

export const OrgProfileProvider: React.FC = ({ children }) => {
    const { systemId } = useSystemInfo()
    const { data, isSuccess, isLoading, error, refetch } = useOrganizationProfileQuery(systemId);

    const filesUploader = useFiles()
    const editOrganizationMutation = useEditOrganization(systemId, filesUploader)

    if (isLoading || !isSuccess) {
        return <Skeleton variant="rectangular" width={"90vw"} height={"65vh"} sx={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '3vh', borderRadius: '5px' }} />;
    }

    if (error || data === undefined) {
        return <div>Error...</div>;
    }

    const { profilePhoto, imageCoordinates, termsDate, termsName, systemName, contacts, participantNum, ageRange, accessibilities, properManagement, expiration, isActive } = data
    return (
        <CommonProfileProvider editMutation={editOrganizationMutation} refetch={refetch} filesUploader={filesUploader} profilePhoto={profilePhoto} imageCoordinates={imageCoordinates} systemName={systemName} termsDate={termsDate} termsName={termsName} contacts={contacts} isActive={isActive}>
            <OrgConfigurationContext.Provider value={{ participantsNumCx: participantNum, ageRangeCx: ageRange, accessibilityCx: accessibilities }}>
                <OrgFilesProvider expiration={expiration} properManagement={properManagement}>
                    {children}
                </OrgFilesProvider>
            </OrgConfigurationContext.Provider>
        </CommonProfileProvider>
    );
};

export const AgencyProfileProvider: React.FC = ({ children }) => {
    const { systemId } = useSystemInfo()
    const { data, isSuccess, isLoading, error, refetch } = useAgencyProfileQuery(systemId);

    const filesUploader = useFiles()
    const editAgencyMutation = useEditAgency(systemId, filesUploader)

    if (isLoading || !isSuccess) {
        return <Skeleton variant="rectangular" width={"90vw"} height={"65vh"} sx={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '3vh', borderRadius: '5px' }} />;
    }

    if (error || data === undefined) {
        return <div>Error...</div>;
    }

    const { termsDate, systemName, navigation, contacts, halls, location, termsName, profilePhoto, imageCoordinates, isActive } = data
    return (
        <CommonProfileProvider editMutation={editAgencyMutation} refetch={refetch} filesUploader={filesUploader} profilePhoto={profilePhoto} imageCoordinates={imageCoordinates} termsDate={termsDate} termsName={termsName} systemName={systemName} contacts={contacts} isActive={isActive}>
            <AgencyAddressContext.Provider value={{ locationCx: location, navigationCx: navigation, hallsCx: halls.map((hall) => hall.hallName) }}>
                {children}
            </AgencyAddressContext.Provider>
        </CommonProfileProvider>
    );
};

const EditSystemProfileProvider: React.FC<EditSystemProfileProviderProps> = ({ children, editMutation, refetch, filesUploader }) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [systemProfileData, setSystemProfileData] = useState<BothEditAbleFields>({})

    const [confirmCancelChangesPopup, setConfirmCancelChangesPopup] = useState<boolean>(false);

    const { showError } = useContext(ErrorContext)

    const saveDataByIdentifier: SaveSystemDataByIdentifier = (id, value) => {
        setSystemProfileData(prev => ({ ...prev, [id]: value }))
    }
    const handleEditClicked = () => {
        setIsEditMode(true)
    }
    const handleCancelClick = () => {
        setConfirmCancelChangesPopup(true)
    }
    const cancelEdit = () => {
        setIsEditMode(false)
        setConfirmCancelChangesPopup(false)
    }
    const handleSaveChanges = () => {
        setIsEditMode(false)
        if (Object.values(systemProfileData).every(value => value === undefined)) return;

        editMutation.mutateAsync({ ...systemProfileData })
            .then(() => {
                refetch()
            })
            .catch(err => {
                showError(msgStatus(err.status, err.data.phoneError))
            })
    }

    return (
        <EditSystemProfileContext.Provider value={{ isEditMode, handleEditClicked, handleSaveChanges, handleCancelClick, saveDataByIdentifier, systemProfileData, filesUploader }}>
            {children}
            <GenericYesNoPopup
                open={confirmCancelChangesPopup}
                handleClose={() => setConfirmCancelChangesPopup(false)}
                handleYes={cancelEdit}
                title={CONFIRM_DISCARD_CHANGES}
            />
        </EditSystemProfileContext.Provider>
    )
}