import { ChangeEvent, useCallback, useEffect, useState } from "react";
import clsx from "clsx"
import GenericInput from "./GenericInput";
import { GenericSearchBarProps } from "../consts/interfaces/GenericSearchBar.interface";
import { SEARCH } from "../consts/hebrew";
import '../style/searchBar.scss'

const GenericSearchBar: React.FC<GenericSearchBarProps> = ({ initialOptions, setFilteredArr, keysToSearch, className }) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const sortArray = useCallback((arr: any, search: string) => {
        const filteredArray = keysToSearch ?
            arr.filter((item: any) => Object.keys(item).some(key => keysToSearch.includes(key) && item[key].includes(search)))
            :
            arr.filter((item: any) => Object.values(item).some(value => String(value).includes(search)));
        return filteredArray
    }, [keysToSearch])

    useEffect(() => {
        if (!initialOptions) return

        setTimeout(() => {
            if (!searchValue) return
            const filteredArray = sortArray(initialOptions, ignoreWhiteSpaces(searchValue))
            setFilteredArr(filteredArray)
        }, 300)
    }, [searchValue, initialOptions, sortArray])

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchValue(e.currentTarget.value);
    }

    const ignoreWhiteSpaces = (str: string) => (str.replace(/  +/g, ' ')).trim()

    return (
        <div className={clsx("search-container", className)}>
            <GenericInput value={searchValue} placeholder={SEARCH} isSearch={true} onChange={handleChange} />
            <label htmlFor='search'>
                <img className='search-icon' src="/icons-admin/search.png" alt="search" />
            </label>
        </div>
    )
}
export default GenericSearchBar