import React, { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAsyncEffect } from "@hilma/tools";

import { SYSTEM_TYPE, specificStatistics } from "../consts/interfaces/SpecificStatistics.interface";
import { amountOfEvents, totalFeedbacksInfo } from "../utils/api/specific-statistics";
import { SystemTypeContext, systemTypeContextProps } from "./SystemTypeContext";

export const SpecificStatisticsContext = React.createContext<specificStatistics | null>(null);

export const SpecificStatisticsProvider: React.FC = ({ children }) => {
    const { systemType } = useContext(SystemTypeContext) as systemTypeContextProps
    const location = useLocation();
    const [filterDate, setFilterDateContext] = useState<Date>()
    const [countOfEvents, setCountOfEvents] = useState<number>(0);
    const [totalFeedbacks, setTotalFeedbacks] = useState<number>(0)

    const systemId = useMemo(() => {
        return (location.pathname.split('/')[3]);
    }, [location]);

    useAsyncEffect(async () => {
        if (systemType && systemId) {
            if (systemType === SYSTEM_TYPE.organization) {
                getTotalFeedbacks('orgId')
                getAmountOfEvents('orgId')
            }
            else {
                getTotalFeedbacks('agencyId')
                getAmountOfEvents('agencyId')
            }
        }
    }, [systemType, systemId, filterDate])

    const getAmountOfEvents = async (type: string) => {
        const eventsAmount = await amountOfEvents(type, systemId, filterDate)
        setCountOfEvents(eventsAmount)
    }

    //gets the amount of total feedbacks
    const getTotalFeedbacks = async (type: string) => {
        const data = await totalFeedbacksInfo(type, systemId)
        setTotalFeedbacks(data)
    }

    return (
        <SpecificStatisticsContext.Provider value={{
            filterDate,
            systemId,
            systemType: systemType,
            countOfEvents,
            totalFeedbacks,
            setFilterDateContext
        }}>
            {children}
        </SpecificStatisticsContext.Provider>
    )
}