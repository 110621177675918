import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useIsAuthenticated, useLogout } from "@hilma/auth";
import { useMediaQuery } from "@mui/material";
import { UserContext } from "../context/UserContext";
import { DROPDOWN_MENU } from "../consts/hebrew";
import { navbarOptions } from "../consts/arrays";
import { DropDownElements } from "../consts/interfaces/GenericDropDown.interface";
import GenericDropDown from "./GenericDropDown";

import '../style/adminHeader.scss';

const AdminHeader: React.FC = () => {

    const { loggingIn } = useContext(UserContext);
    const isAuthenticated = useIsAuthenticated();
    const logout = useLogout();
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [showDropdown, setShowDropdown] = useState(false);
    const dropDownElement = React.useRef<HTMLDivElement>(null);
    const location = useLocation()
    const path = location.pathname.split('/')[1];

    const dropDownItems: DropDownElements[] = [
        { label: DROPDOWN_MENU.LOGOUT, limg: "/icons-admin/log-out.svg", onClick: () => { loggingIn("", "", 0, ""); navigate(''); logout(); } }
    ]

    const toggleDropdown = (e: MouseEvent) => {
        const { current } = dropDownElement;
        if (current && current.contains(e.target as Node)) {
            setShowDropdown(!showDropdown);
        } else {
            setShowDropdown(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", toggleDropdown);
        return () => {
            document.removeEventListener("click", toggleDropdown);
        };
    }, [showDropdown]);

    return (
        <header className="header">
            {(!isMobile || !isAuthenticated) &&
                <div className="img-container">
                    <Link to='/' children={<img src="/icons-admin/new-logo.svg" alt="enter logo" />} />
                </div>
            }
            {isAuthenticated &&
                <>
                    <nav className="navbar">
                        {navbarOptions.map(({ name, href }) => <Link to={href} children={name} className={clsx('link', { 'active': href === '/' + path })} key={href} />)}
                    </nav>
                    <div ref={dropDownElement} className="img-container">
                        <img src="/icons-admin/profile-user-white.svg" alt="default user" />
                        <GenericDropDown isOpen={showDropdown} elements={dropDownItems} className='profile-dropdown' />
                    </div>
                </>
            }
        </header >
    )
}

export default AdminHeader;