import axios from "axios"

export const totalAgenciesAndOrgs = async (filterDate?: Date) => {
    return await (await axios.get(`/api/statistics/agencies-and-organizations-count?filterDate=${filterDate || ''}`)).data
}

export const amountOfEvents = async (filterDate?: Date) => {
    return await (await axios.get(`/api/statistics/count-of-events?filterDate=${filterDate || ''}`)).data
}

export const worthOfTotalDonation = async (filterDate?: Date) => {
    return await (await axios.get(`/api/statistics/total-donation-worth?filterDate=${filterDate || ''}`)).data
}

export const donatedAndReceivingCount = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/donating-agencies-receiving-organizations-count?filterDate=${filterDate || ''}`)).data
}

export const eventsOccurrenceData = async () => {
    return (await axios.get(`/api/statistics/events-occurrence`)).data
}

export const donatedAndReceivedTickets = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/donated-and-received-tickets-count?filterDate=${filterDate || ''}`)).data
}

export const ticketsDivision = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/tickets-utilization?filterDate=${filterDate || ''}`)).data
}

export const topDonatingAgenciesInfo = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/top-donating-agencies?filterDate=${filterDate || ''}`)).data
}

export const mostInvolvedUsers = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/most-involved-users?filterDate=${filterDate || ''}`)).data;
}

export const evetsNotRelevantFeedbacks = async () => {
    return (await axios.get(`/api/statistics/not-relevant-feedbacks`)).data
}

export const timeTillEvent = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/average-time-till-event?filterDate=${filterDate || ''}`)).data
}

export const topOrganization = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/top-receiving-organizations?filterDate=${filterDate || ''}`)).data
}

export const getEventsCategories = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/event-categories?filterDate=${filterDate || ''}`)).data
}

export const eventsCategories = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/event-categories?filterDate=${filterDate || ''}`)).data
}

export const orgsDemographics = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/organization-demographics?filterDate=${filterDate || ''}`)).data
}
export const totalFeedbacksCount = async () => {
    return (await axios.get('/api/statistics/total-given-feedbacks')).data
}

export const postToPurchaseInfo = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/average-time-from-post-to-purchase?filterDate=${filterDate || ''}`)).data
}

export const extraTime = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/extra-time-data?filterDate=${filterDate || ''}`)).data
}

export const totalOffers = async () => {
    return await (await axios.get(`/api/statistics/all-events-offers`)).data
}

export const offerToPurchase = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/average-time-from-offer-to-purchase?filterDate=${filterDate || ''}`)).data
}

export const avgOfferedOrgs = async (filterDate?: Date) => {
    return (await axios.get(`/api/statistics/average-offered-organizations-number?filterDate=${filterDate || ''}`)).data
}

export const getNotRelevantReasons = async (filterDate?: Date) => {
    return (await axios.get(`api/statistics/not-relevant-reasons?filterDate=${filterDate || ''}`)).data
}