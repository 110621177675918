import React from 'react';
import { EVENTS } from '../consts/hebrew';
import WhiteBoxWrapper from '../generic-components/WhiteBoxWrapper';
import '../style/NumEventsCard.scss';

const NumEventsCard: React.FC<{ eventsAmount: number, style?: { width: string }, title?: string }> = ({ style, eventsAmount, title }) => {
    return (
        <WhiteBoxWrapper>
            <div className='stats-title'>{title}</div>
            <div className='event-num-container'>
                <div className='num-of-events' style={style}>
                    {eventsAmount}
                </div>
                <div className='event-num-description'>{EVENTS}</div>
            </div>
        </WhiteBoxWrapper>
    )
}
export default NumEventsCard;