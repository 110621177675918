import { useContext, useEffect, useState } from "react"
import { Contact } from "../consts/interfaces/Contact.interface"
import { EditSystemProfileContext } from "../context/SystemProfileContexts"
import { PROFILE } from "../consts/variables"
import ItemInSystemProfile from "./ItemInSystemProfile"

const SystemContacts: React.FC<{ contactsCx: Contact[] }> = ({ contactsCx }) => {
    const { isEditMode, saveDataByIdentifier } = useContext(EditSystemProfileContext) || {}
    const [contacts, setContacts] = useState<Contact[]>(Array.from(contactsCx))

    useEffect(() => {
        setContacts(Array.from(contactsCx))
    }, [contactsCx, isEditMode])

    const editContact = (index: number, key: keyof Contact, value: string) => {
        return contacts.map((contact, ind) => ind === index ? ({ ...contact, [key]: value }) : contact)
    }

    const saveContacts = (index: number, key: keyof Contact, value: string) => {
        const newContacts = editContact(index, key, value)
        saveDataByIdentifier?.("contacts", newContacts)
    }

    return (
        <div className='profile-array'>
            <div className='profile-item'>
                <div className='profile-item-name'>{PROFILE.CONTACTS}</div>
                <div className='profile-item-contacts'>
                    {contacts.length !== 0 &&
                        contacts.map((contact, index) => {
                            return (
                                <div key={contact.id} className='profile-item-contact-data'>
                                    <ItemInSystemProfile data={contact.name} setData={(value) => setContacts(editContact(index, "name", value))} isEdit={isEditMode} saveDataByIdentifier={(_, value) => saveContacts(index, "name", value as string)} id="contacts" />
                                    <ItemInSystemProfile data={contact.phone} setData={(value) => setContacts(editContact(index, "phone", value))} isEdit={isEditMode} saveDataByIdentifier={(_, value) => saveContacts(index, "phone", value as string)} id="contacts" />
                                    <ItemInSystemProfile data={contact.email} setData={(value) => setContacts(editContact(index, "email", value))} isEdit={isEditMode} saveDataByIdentifier={(_, value) => saveContacts(index, "email", value as string)} id="contacts" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default SystemContacts