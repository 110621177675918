import { useState } from "react";
import { FEEDBACKS_AGENCY_RECEIVED, TO_ALL_FEEDBACKS } from "../consts/hebrew";
import GenericFeedbackStats from "../generic-components/GenericFeedbackStats";
import PopupAgencyFeedback from "./popups/PopupAgencyFeedback";

const AgencyFeedbacksStat: React.FC<{ totalFeedbacks: number }> = ({ totalFeedbacks }) => {
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

    const handleClose = () => {
        setIsPopupOpen(false)
    }

    return (
        <>
            <PopupAgencyFeedback isPopupOpen={isPopupOpen} handleClose={handleClose} />
            <GenericFeedbackStats
                feedbackCount={totalFeedbacks || 0}
                text={FEEDBACKS_AGENCY_RECEIVED}
                bottomText={TO_ALL_FEEDBACKS}
                onBottomTextClick={() => setIsPopupOpen(true)}
                style={{ width: '27vw', maxHeight: '34vh' }}

            />
        </>
    )
}

export default AgencyFeedbacksStat;