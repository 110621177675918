import axios from "axios";
import { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { _ERROR_MESSAGE } from "../consts/variables";
import { ErrorContext } from "../context/ErrorContext";
import { passwordValidation } from "../functions/validations";
import GenericButton from "../generic-components/GenericButton";
import GenericInput from "../generic-components/GenericInput";

import "../style/login.scss";

const UpdatePassword: React.FC = () => {
    const [passwordError1, setPasswordError1] = useState<string>("");
    const [password1, setPassword1] = useState<string>("");
    const [passwordError2, setPasswordError2] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [serverLoading, setServerLoading] = useState(false)

    const { showError } = useContext(ErrorContext)
    const location = useLocation();
    const navigate = useNavigate()

    const token = useMemo(() => {
        return location.pathname.split('/')[2];
    }, [location]);

    //function that checks if all the input is valid and if it is moves on to the next page
    const handleClick = async () => {

        const passwordVal1 = passwordValidation(password1);
        const passwordVal2 = passwordValidation(password2);

        if (!passwordVal1 && !passwordVal2 && (password2 === password1)) {
            try {
                setServerLoading(true)
                const pass = await axios.post("/api/contact/save-new-password", {
                    token: token,
                    password: password1,
                });
                setServerLoading(false)
                if (pass) {
                    navigate('/');
                } else {
                    alert("סיסמה שגויה, אנא בדוק את הסיסמה והכנס שוב.")
                }
            } catch {
                showError(_ERROR_MESSAGE)
                setServerLoading(false)
            }
        } else {
            setPasswordError1(passwordVal1);
            setPasswordError2(passwordVal2);
        }
    }

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "password1":
                setPasswordError1(passwordValidation(e.target.value));
                break;
            case "password2":
                setPasswordError2(passwordValidation(e.target.value));
                break;
            default:
                break;
        }
    }

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "password1":
                setPassword1(e.target.value);
                break;
            case "password2":
                setPassword2(e.target.value);
                break;
            default:
                break;
        }
    }

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-first-title"> אנטר </div>
                <div className="login-second-title">עדכון סיסמה</div>
                <GenericInput
                    title=""
                    type='password'
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    name="password1"
                    error={passwordError1}
                    value={password1}
                    placeholder="סיסמה חדשה"
                />

                <GenericInput
                    title=""
                    type='password'
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    name="password2"
                    error={passwordError2}
                    value={password2}
                    placeholder="אימות סיסמה"
                />

                <GenericButton className="darkBlue big" handleClick={handleClick} serverLoading={serverLoading}>עדכן</GenericButton>
            </div>
        </div>
    )
}

export default UpdatePassword;