import { useContext, useEffect, useState } from "react";

import { EVENT_NUMBER, TICKET_NUMBER, TOP_DONATING_AGENCIES } from "../consts/hebrew";
import { BarGraphData } from "../consts/interfaces/GenericGraphBar.interface";
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";
import { StatisticsContext } from "../context/StatisticsContext";

import GenericBarGraph from "../generic-components/GenericBarGraph";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";

const TopDonatingAgenciesGraph: React.FC = () => {
    const { top5DonatingAgencies } = useContext(StatisticsContext) as StatContextInterface;
    const [data, setData] = useState<BarGraphData[]>([])

    useEffect(() => {
        const formattedData: BarGraphData[] = top5DonatingAgencies.map(agency => ({
            name: agency.agencyName,
            bar1Value: agency.ticketCount,
            bar2Value: agency.eventCount
        }))

        setData(formattedData)
    }, [top5DonatingAgencies])

    return (
        <WhiteBoxWrapper>
            <div className="stats-title">{TOP_DONATING_AGENCIES}</div>
            <GenericBarGraph data={data} bar1={TICKET_NUMBER} bar2={EVENT_NUMBER} style={{ width: '28vw', height: '40vh', marginTop: '3vh' }} />
        </WhiteBoxWrapper>
    )
}

export default TopDonatingAgenciesGraph;