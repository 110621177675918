import '../style/arrowsTimeline.scss';

const ArrowsTimeline: React.FC<{ text?: string, hoverColor?: string }> = ({ text, hoverColor }) => {

    return (
        <div className='arrow-container'>
            <div className='main-part' style={hoverColor ? { backgroundColor: hoverColor } : {}} >
                <div className='text' style={hoverColor ? { color: "#FFFFFF" } : {}} >{text}</div>
            </div>
            <div className='triangle' style={hoverColor ? { borderRightColor: hoverColor } : {}} />
        </div>
    )
}

export default ArrowsTimeline;