import { TooltipProps } from "recharts";
import '../style/barGraphTooltip.scss';

const BarGraphTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active) {
        return (
            <div className="bar-chart-tooltip">
                <div className="label">
                    {label}
                </div>
                <div className="value">
                    {payload?.[0].value} {payload?.[0].name}
                </div>
                <div className="value">
                    {payload?.[1].value} {payload?.[1].name}
                </div>
            </div>
        )
    }

    return null;
};

export default BarGraphTooltip;