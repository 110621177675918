import { Area } from "./GenericProfilePic.interface"

export interface GaveMostTicketsInterface {
    side: userTypeEnum,
    agencyOrOrg?: agencyOrOrg
}

export interface agencyOrOrg {
    name: string,
    profile: string,
    imageCoordinates?: Area
}

export enum userTypeEnum {
    agency = "agency",
    organization = "organization"
}
export interface mostUserInvolvedUserServerResponse {
    agencyInfo: agencyOrOrg,
    organizationInfo: agencyOrOrg
}


