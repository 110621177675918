import React from 'react'
import { REMOTE_LOGIN_TEXTS } from '../../consts/hebrew'
import styles from './resetlink.module.scss'
import { useRequestRemoteLoginCodeMutation } from '../../hooks/api/remote-login';

interface ResetLinkProps {
  entityType: string;
  entityId: number;
}
export default function ResetLink(props: ResetLinkProps) {
  const requestRemoteLoginCodeMutation = useRequestRemoteLoginCodeMutation();
  const resetLink = () => {
    requestRemoteLoginCodeMutation.mutateAsync({
      entityId: props.entityId,
      entityType: props.entityType,
    });
  }
  return (
    <button className={styles.resetCodeButton} onClick={resetLink}>{REMOTE_LOGIN_TEXTS.POPUP_SEND_CODE_AGAIN}</button>
  )
}
