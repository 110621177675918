import { useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { HorizontalGraphData } from "../consts/interfaces/GenericHorizontalGraph.interface";
import { REASONS_TO_CANCEL_EVENT } from "../consts/hebrew";
import { vw } from "../consts/variables";

import GenericHorizontalGraph from "../generic-components/GenericHorizontalGraph";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";

import '../style/cancelEventReasons.scss';

const CancelEventReasons = () => {
    const [data, setData] = useState<HorizontalGraphData>([])

    useAsyncEffect(async () => {
        const data: { text: string, count: number }[] = (await axios.get('/api/statistics/cancelation-reasons')).data

        const formattedData: HorizontalGraphData = data.map(({ text, count }) => ({ name: text, value: count }))
        setData(formattedData)
    }, [])

    return (
        <div className="cancel-event-reasons-container">
            {data.length !== 0 &&
                <>
                    <div className="title">{REASONS_TO_CANCEL_EVENT}</div>
                    <WhiteBoxWrapper>
                        <GenericHorizontalGraph
                            data={data}
                            style={{ width: '31vw', height: '35vh' }}
                            filledBars
                            labelsWidth={20 * vw}
                        />
                    </WhiteBoxWrapper>
                </>
            }
        </div>
    )
}

export default CancelEventReasons;