//date function that gets a string of date and returns day, date and time

import moment from "moment";

export function eventToDismantledDate(originalDate: string) {
    const momentDate = moment(originalDate);
    const day = "יום " + momentDate.format("dddd")
    const date = momentDate.format("DD.MM.YYYY");
    const time = momentDate.format("HH:mm");
    return { day, date, time };
}


export const formatDate = (orignialDate: string) => {
    const date = new Date(orignialDate)

    return date.toLocaleDateString("en-GB").replaceAll('/', '-')
}

export const calculateTimeDifference = (expirationDate?: string) => {
    return Math.max(new Date(expirationDate || 0).getTime() - Date.now(), 0)
}

const padTime = (num: number) => num.toString().padStart(2, '0');

export const buildTimeString = (ms: number) => {
    const hh = padTime(Math.floor(ms / (1000 * 60 * 60)));
    const mm = padTime(Math.floor(ms / (1000 * 60)) % 60);
    const ss = padTime(Math.floor(ms / 1000) % 60);
    return `${hh}:${mm}:${ss}`;
};
