import { useContext, useEffect, useState } from "react";

import { AVERAGE_TIME_POST_BEFORE_EVENT, DAYS, IS_TICKETS_DONATION_DONE_AHEAD } from "../consts/hebrew";
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";

import GenericInfoToolTip from "../generic-components/GenericInfoToolTip";
import { StatisticsContext } from "../context/StatisticsContext";
import '../style/averageTimePostBeforeEvent.scss';

const AverageTimePostBeforeEvent: React.FC<{
    setStages: React.Dispatch<React.SetStateAction<{
        text: string;
        color?: string | undefined;
    }[]>>,
}> = ({ setStages }) => {

    const { avgTimeTillEvent } = useContext(StatisticsContext) as StatContextInterface
    const [days, setDays] = useState<number>(0);

    useEffect(() => {
        setDays(Math.round(avgTimeTillEvent * 10) / 10)
    }, [avgTimeTillEvent])

    const onMouseEnter = () => {
        const hoverColor = "#4DD194";

        setStages(prev =>
            prev.map(stage => ({ ...stage, color: hoverColor })))
    }

    const onMouseLeave = () => {
        setStages(prev => prev.map(({ text }) => ({ text })))
    }

    return (
        <div className="average-time-post" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="days">
                {days}
                <span>{DAYS}</span>
            </div>

            <div className="description">
                {AVERAGE_TIME_POST_BEFORE_EVENT}
                <GenericInfoToolTip text={IS_TICKETS_DONATION_DONE_AHEAD} />
            </div>
        </div>
    )
}

export default AverageTimePostBeforeEvent;