import GenericTextNumberRowProps from "../consts/interfaces/GenericTextNumberRow.interface";
import '../style/genericTextNumber.scss';

const GenericTextNumberRow: React.FC<GenericTextNumberRowProps> = ({ text, number, width }) => {

    return (
        <div className="text-number-row" style={{ width }}>
            <div className="text">
                {text}
            </div>

            <div className="number">
                {number}
            </div>
        </div>
    )
}

export default GenericTextNumberRow;