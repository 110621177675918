//this component returns the agencies pages
import { Outlet } from "react-router";
import GenericMiniNavbar from "../components/MiniNavbar";
import { SYSTEM_TYPE } from "../consts/interfaces/SpecificStatistics.interface";
import { SystemTypeProvider } from "../context/SystemTypeContext";
import { useIdFromParams } from '../hooks/idFromParams';

const SystemInformationPage: React.FC<{ systemType: SYSTEM_TYPE }> = ({ systemType }) => {
    const id = useIdFromParams()

    return (
        <SystemTypeProvider systemType={systemType}>
            <div className='agency-page'>
                <GenericMiniNavbar />
                <Outlet context={{ systemId: id }} />
            </div>
        </SystemTypeProvider>
    )
}

export default SystemInformationPage;
