import "../../style/yellowBanner.scss"

export interface YellowBannerProps {
    tickets: number
    border?: boolean
    isEvent?: boolean
}
const YellowBanner: React.FC<YellowBannerProps> = ({ isEvent, tickets }) => {

    return (
        <div className={`yellow-banner-wrapper  ${isEvent ? 'isEvent' : ""}`}>
            <div className="yellow-banner-container">
                <div className={tickets > 99 ? 'yellow-banner-number-small' : 'yellow-banner-number'} >{tickets}</div>
                <div className="yellow-banner-text">כרטיסים הוזמנו</div>
            </div>
            <div className="triangle"></div>
        </div>
    )
}

export default YellowBanner