import React, { useMemo, useState } from "react";
import { useAsyncEffect } from "@hilma/tools";
import { StatContextInterface, agenciesOrgsCounter, averageTimeOfPurchase, ticketStatusTypesCount, donatingReceiving, eventsCategoriesOrdered, eventsOccurrence, extraTimeInfo, feedbackType, orgDemographicsOrdered, ticketUtilizationInterface, topAgencies, topOrgs } from "../consts/interfaces/StatisticsContext.interface";
import { mostUserInvolvedUserServerResponse } from "../consts/interfaces/GaveMostTicket.interface";
import NotRelevantEventProps from "../consts/interfaces/NotRelevantEvent.interface";

import { amountOfEvents, avgOfferedOrgs, donatedAndReceivedTickets, donatedAndReceivingCount, eventsCategories, eventsOccurrenceData, evetsNotRelevantFeedbacks, extraTime, getNotRelevantReasons, mostInvolvedUsers, offerToPurchase, orgsDemographics, postToPurchaseInfo, ticketsDivision, timeTillEvent, topDonatingAgenciesInfo, topOrganization, totalAgenciesAndOrgs, totalFeedbacksCount, totalOffers, worthOfTotalDonation } from "../utils/api/general-statistics";
import { NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS } from "../consts/hebrew";

export const StatisticsContext = React.createContext<StatContextInterface | null>(null);

export const StatisticsProvider: React.FC = ({ children }) => {
    const [filterDate, setFilterDateContext] = useState<Date>()
    const [agenciesOrgsCounter, setAgenciesOrgsCounter] = useState<agenciesOrgsCounter>({
        agenciesCount: 0,
        organizationsCount: 0
    })
    const [countOfEvents, setCountOfEvents] = useState<number>(0);
    const [worthOfDonation, setWorthOfDonation] = useState<number>(0)
    const [donatingReceivingCount, setDonatingReceivingCount] = useState<donatingReceiving>({
        donatingAgencies: 0,
        receivingOrganizations: 0
    })
    const [eventsOccurrenceCount, setEventsOccurrenceCount] = useState<eventsOccurrence>({
        canceledAmount: 0,
        occurredAmount: 0
    })
    const [ticketStatusTypesCount, setTicketStatusTypesCount] = useState<ticketStatusTypesCount>({
        donatedTicketsCount: 0,
        purchasedTicketsCount: 0,
    })
    const [ticketUtilization, setTicketUtilization] = useState<ticketUtilizationInterface>({
        allPurchased: 0,
        notPurchased: 0,
        aboveHalf: 0,
        underHalf: 0,
        totalEvents: 0
    })
    const [top5DonatingAgencies, setTop5DonatingAgencies] = useState<topAgencies[]>([{
        agencyName: '',
        eventCount: 0,
        ticketCount: 0
    }])
    const [usersMostInvolved, setUsersMostInvolved] = useState<mostUserInvolvedUserServerResponse | null>(null)
    const [top5DonatedOrgs, setTop5DonatedOrgs] = useState<topOrgs[]>([{
        organizationName: '',
        eventCount: 0,
        ticketCount: 0
    }])
    const [eventCategoriesOrdered, setEventsCategoriesOrdered] = useState<eventsCategoriesOrdered[]>([{
        count: 0,
        categoryName: ''
    }])
    const [orgDemographicsOrdered, setOrgDemographicsOrdered] = useState<orgDemographicsOrdered[]>([{
        count: 0,
        demographicName: ''
    }])
    const [totalFeedbacks, setTotalFeedbacks] = useState<number>(0)
    const [averageTimeFromPost, setAverageTimeFromPost] = useState<averageTimeOfPurchase>({
        hours: 0,
        minutes: 0
    })
    const [averageOfferedOrgs, setAverageOfferedOrgs] = useState<number>(0)

    const [avgTimeFromOffer, setAvgTimeFromOffer] = useState<averageTimeOfPurchase>({
        hours: 0,
        minutes: 0
    })
    const [avgTimeTillEvent, setAvgTimeTillEvent] = useState<number>(0)
    const [notRelevantFeedbacks, setNotRelevantFeedbacks] = useState<feedbackType[]>([{
        feedbackText: '',
        amount: 0
    }])
    const [allEventsOffers, setAllEventsOffers] = useState<number>(0)
    const [extraTimeInfo, setExtraTimeInfo] = useState<extraTimeInfo>({
        clicks: 0,
        offers: 0
    })
    const [notRelevantFeedbackReasons, setNotRelevantFeedbackReasons] = useState<NotRelevantEventProps[]>([])


    useAsyncEffect(async () => {
        getTotalAgenciesAndOrgs()
        getWorthOfDonation()
        donatedAgenciesReceivingOrgs()
        getDonatedAndReceivedTickets()
        getTicketsDivision()
        getTopDonatingAgencies()
        getMostInvolvedUsers()
        getTopOrganization()
        getEventsCategories()
        getOrgsDemographics()
        postToPurchase()
        getAvgOfferedOrgs()
        getOfferToPurchase()
        getTimeTillEvent()
        getExtraTime()
        getAmountOfEvents()
        getNotRelevantFeedbacks()
    }, [filterDate])

    useAsyncEffect(async () => {
        eventsOccurrence()
        getTotalFeedbacks()
        notRelevantFeedbacksInfo()
        getTotalOffers()
    }, [])

    //gets the amount of total agencies and organizations from server
    const getTotalAgenciesAndOrgs = async () => {
        const agenciesAndOrgsCount = await totalAgenciesAndOrgs(filterDate)
        setAgenciesOrgsCounter(agenciesAndOrgsCount)
    }

    const getAmountOfEvents = async () => {
        const eventsAmount = await amountOfEvents(filterDate)
        setCountOfEvents(eventsAmount)
    }

    //gets the total donation worth 
    const getWorthOfDonation = async () => {
        const worthOfDonation = await worthOfTotalDonation(filterDate)
        setWorthOfDonation(worthOfDonation)
    }

    //gets the amount of agencies which donated and organizations which received
    const donatedAgenciesReceivingOrgs = async () => {
        const donatingAndReceiving = await donatedAndReceivingCount(filterDate)
        setDonatingReceivingCount(donatingAndReceiving)
    }

    //gets the amount of how many events were canceled and how many were occured
    const eventsOccurrence = async () => {
        const canceledAndOccuredEvents: eventsOccurrence = await eventsOccurrenceData()
        setEventsOccurrenceCount(canceledAndOccuredEvents)
    }

    //gets the amount of donated and received tickets
    const getDonatedAndReceivedTickets = async () => {
        const ticketsCount = await donatedAndReceivedTickets(filterDate)
        setTicketStatusTypesCount(ticketsCount)
    }

    //gets division of all tickets
    const getTicketsDivision = async () => {
        const ticketsDivisionCount = await ticketsDivision(filterDate)
        setTicketUtilization(ticketsDivisionCount)
    }

    //gets the top 5 donating agencies
    const getTopDonatingAgencies = async () => {
        const top5DonatingAgencies = await topDonatingAgenciesInfo(filterDate)
        setTop5DonatingAgencies(top5DonatingAgencies)
    }

    //gets the details of the agency which donated the most tickests and the organization which participated in most events
    const getMostInvolvedUsers = async () => {
        const response = await mostInvolvedUsers(filterDate)
        setUsersMostInvolved(response)
    }

    //gets the top 5 organizations which participate in most events
    const getTopOrganization = async () => {
        const top5DonatedOrgs = await topOrganization(filterDate)
        setTop5DonatedOrgs(top5DonatedOrgs)
    }

    //gets the 5 most common events categories ordered
    const getEventsCategories = async () => {
        const data = await eventsCategories(filterDate)
        setEventsCategoriesOrdered(data)
    }

    //gets the 5 most common organizations' demographics
    const getOrgsDemographics = async () => {
        const data = await orgsDemographics(filterDate)
        setOrgDemographicsOrdered(data)
    }

    //gets the amount of total feedbacks
    const getTotalFeedbacks = async () => {
        const data = await totalFeedbacksCount()
        setTotalFeedbacks(data)
    }

    //gets the average time from post to purchase
    const postToPurchase = async () => {
        const data = await postToPurchaseInfo(filterDate)
        setAverageTimeFromPost(data)

    }

    //gets the average of offered organizations
    const getAvgOfferedOrgs = async () => {
        const data = await avgOfferedOrgs(filterDate)
        setAverageOfferedOrgs(data)
    }

    //gets the average time from offer to purchase
    const getOfferToPurchase = async () => {
        const data = await offerToPurchase(filterDate)
        setAvgTimeFromOffer(data)
    }

    // gets the average time till event
    const getTimeTillEvent = async () => {
        const data = await timeTillEvent(filterDate)
        setAvgTimeTillEvent(data)
    }

    //gets the not relevant feedbacks info
    const notRelevantFeedbacksInfo = async () => {
        const data = await evetsNotRelevantFeedbacks()
        setNotRelevantFeedbacks(data)
    }

    //gets the total amount of events' offers  (every time an organization gets an offer)
    const getTotalOffers = async () => {
        const allEventsOffers = await totalOffers()
        setAllEventsOffers(allEventsOffers)
    }

    const getExtraTime = async () => {
        const data = await extraTime(filterDate)
        setExtraTimeInfo(data)
    }
    const notRelevantFeedbackReasonsToSoon = useMemo(() => {
        return notRelevantFeedbackReasons.filter((a) => a.text === NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS.TOO_SOON);
      }, [notRelevantFeedbackReasons]);
    
      const notRelevantFeedbackReasonsElse = useMemo(() => {
        return notRelevantFeedbackReasons.filter((a) => {
          return a.text !== NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS.NO_MATCH && a.text !== NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS.TIME_NOT_GOOD && a.text !== NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS.TOO_SOON && a.text !== '';
        });
      }, [notRelevantFeedbackReasons]);
    
      const notRelevantFeedbackReasonsNoMatch = useMemo(() => {
        return notRelevantFeedbackReasons.filter((a) => a.text === NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS.NO_MATCH);
      }, [notRelevantFeedbackReasons]);
    
      const notRelevantFeedbackReasonsTimeNotGood = useMemo(() => {
        return notRelevantFeedbackReasons.filter((a) => a.text === NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS.TIME_NOT_GOOD);
      }, [notRelevantFeedbackReasons]);
    

    const getNotRelevantFeedbacks = async () => {
        const data = await getNotRelevantReasons(filterDate)
        setNotRelevantFeedbackReasons(data)
    }

    return (
        <StatisticsContext.Provider value={{
            notRelevantFeedbackReasonsToSoon,
            notRelevantFeedbackReasonsElse,
            notRelevantFeedbackReasonsNoMatch,
            notRelevantFeedbackReasonsTimeNotGood,
            filterDate,
            agenciesOrgsCounter,
            countOfEvents,
            worthOfDonation,
            donatingReceivingCount,
            eventsOccurrenceCount,
            ticketStatusTypesCount,
            ticketUtilization,
            top5DonatingAgencies,
            usersMostInvolved,
            top5DonatedOrgs,
            eventCategoriesOrdered,
            orgDemographicsOrdered,
            totalFeedbacks,
            averageTimeFromPost,
            averageOfferedOrgs,
            avgTimeFromOffer,
            avgTimeTillEvent,
            notRelevantFeedbacks,
            allEventsOffers,
            extraTimeInfo,
            notRelevantFeedbackReasons,
            setFilterDateContext,
        }}>
            {children}
        </StatisticsContext.Provider>
    );
}