import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from '@hilma/auth';
import { SocketProvider } from '@hilma/socket.io-react';

import { RequestNumberProvider } from './context/RequestNumberContext';
import GoogleAnalyticsProvider from './context/googleAnalytics';
import UserProvider from './context/UserContext';
import ErrorProvider from './context/ErrorContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import reportWebVitals from './reportWebVitals';
import App from './App';

import './index.css';

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GoogleAnalyticsProvider>
        <AuthProvider accessTokenCookie='kololo' logoutOnUnauthorized={true}>
          <ErrorProvider>
            <UserProvider>
              <SocketProvider options={{ reconnectionAttempts: 2 }}>
                <RequestNumberProvider>
                  <App />
                </RequestNumberProvider>
              </SocketProvider>
            </UserProvider>
          </ErrorProvider>
        </AuthProvider>
      </GoogleAnalyticsProvider>

      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
