import { useNavigate } from "react-router-dom";
import "../style/pageNotFound.scss";

const PageNotFound: React.FC = () => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate("/")
    }

    return (
        <div className='page-not-found-page'>
            <div className="four-o-four">
                <div className="four">4</div>
                <img src="/icons-admin/emptyTicket.svg" className="tickets" alt="tickets" />
                <div className="four">4</div >
            </div>
            <div className="text">
                Page Not Found
            </div>
            <div className='text return-button' onClick={handleClick}>
                חזור לדף הראשי
            </div>
        </div >
    )
}

export default PageNotFound;