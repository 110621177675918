import axios from "axios"

export const putChangeOrgOrAgencyActive = async (systemType: string, systemId: number, flag?: Boolean) => {
    return (await axios.put(`/api/${systemType}/change-active/${systemId}`, {
        value: flag
    })).data
}

export const changeTheButtonToCheckedIfNeeded = async (systemType: string, systemId: number) => {
    return (await axios.get(`/api/${systemType}/check-if-active/${systemId}`))
}

