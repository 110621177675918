import { useContext } from 'react';

import { StatContextInterface } from '../../consts/interfaces/StatisticsContext.interface';
import { userTypeEnum } from '../../consts/interfaces/GaveMostTicket.interface';

import { StatisticsContext } from '../../context/StatisticsContext';
import { GaveMostTickets } from './GaveMostTickets';

import '../../style/mostUserInvolved.scss';

const MostUserInvolved: React.FC = () => {
    const { usersMostInvolved } = useContext(StatisticsContext) as StatContextInterface
    return (
        <div className='most-involved-user-container'>
            <GaveMostTickets side={userTypeEnum.agency} agencyOrOrg={usersMostInvolved?.agencyInfo} />
            <GaveMostTickets side={userTypeEnum.organization} agencyOrOrg={usersMostInvolved?.organizationInfo} />
        </div >
    )
}

export default MostUserInvolved