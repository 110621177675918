import { CSSProperties } from "react";

export const _ERROR_MESSAGE = 'אירעה שגיאה בטעינת הנתונים';
export const _ERROR_DATE_PAST = 'התאריך שבחרת כבר עבר'
export const _ERROR_SEND_MESSAGE_ = 'אירעה שגיאה בשמירת הנתונים';
export const _FILE_LIMIT = 5000
export const _FILE_TOO_BIG = '5MB קובץ גדול מדי. אנא העלו קובץ עד';
export const SEARCH = 'חיפוש'
export const AGENCIES = 'מוסדות'
export const INVALID_FILE_TYPE = "סוג הקובץ אינו תקין";

export enum PIE_TYPES {
    FULL_PIE = 'full_pie',
    EMPTY_PIE = 'empty_pie',
    ONE_SLICE = 'one_slice'
}

export enum REQUEST {
    NO_REQUESTS_OF_AGENCY = 'אין בקשות לאישור מוסדות',
    NO_REQUESTS_OF_ORG = 'אין בקשות לאישור עמותות',
    NO_AGENCY_REQUESTS_WITH_THIS_NAME = 'אין בקשות לאישור מוסדות בשם זה',
    NO_ORG_REQUEST_WITH_THIS_NAME = 'אין בקשות לאישור עמותות בשם זה'
}

export enum LOGIN {
    USER_DOES_NOT_EXIST = 'איימיל זה אינו קיים',
    WRONG_PASS = 'סיסמה שגויה',
    WAS_ERROR_TRY_AGAIN_LATER = 'אירעה שגיאה. נסו שוב מאוחר יותר',
    ENTER = 'אנטר',
    ADMIN = 'מערכת ניהול',
    EMAIL = 'אימייל',
    PASSWORD = 'סיסמה',
    LOGIN = 'התחבר',
    FORGOT_PASS = 'שכחתי סיסמה'
}

export enum PROFILE {
    NAME_OF_ORG = 'שם עמותה',
    NAME_OF_AGENCY = 'שם מוסד',
    NUM_OF_PARTICIPANTS = 'מספר חניכים',
    AGE_RANGE = 'טווח גילאים',
    TYPE_OF_ORG = 'סוג עמותה',
    ACCESSIBIITIES = 'נגישויות',
    THERE_ARE_NOT = 'אין',
    ADDRESS = 'כתובת:',
    WAYS_TO_ARRIVE = 'דרכי הגעה בתחבורה ציבורית:',
    HALL = 'שם האולם:',
    NAME_OF_PLACE = 'שם אולם/מיקום:',
    ORG_PROFILE = 'פרופיל העמותה',
    AGENCY_PROFILE = 'פרופיל המוסד',
    CONTACTS = 'אנשי קשר',
    NAME = 'שם',
    PHONE = 'טלפון',
    EMAIL = 'מייל',
    PROFILE = 'פרופיל',
    APPROVAL = 'אישור חוזה חתום',
    DOWNLOAD = 'הורדה',
    PROPER_MANAGEMENT = 'ניהול תקין',
    VALIDITY = 'תוקף',
    TERMS_NAME = 'שם האדם שחתם על החוזה',
    TERMS_DATE = 'תאריך חתימה על החוזה',
    PLACE = "מיקום"

}

export enum RemoteLoginPopupStages {
    Approve,
    VerifyCode,
}

export const vw = window.innerWidth / 100
export const vh = window.innerHeight / 100

export const maxFeedbackHeartsCount = 50;

export const codeInputProps: {
    inputStyle: CSSProperties,
    inputStyleInvalid: CSSProperties
} = {
    inputStyle: {
        fontFamily: 'Assistant',
        margin: '0 6px',
        width: "40px",
        height: "48px",
        borderRadius: '10px',
        border: '0px',
        fontSize: '22px',
        backgroundColor: '#E6E6E6',
        textAlign: 'center',
        transition: 'ease-in-out 0.5s',
    },
    inputStyleInvalid: {
        fontFamily: 'Assistant',
        margin: '0 6px',
        width: "40px",
        height: "48px",
        borderRadius: '10px',
        fontSize: '22px',
        border: '1px solid #FF0000 ',
        backgroundColor: '#E6E6E6',
        textAlign: 'center',
        transition: 'ease-in-out 0.5s',
    }
}

export const EVENT_STATUS = {
    "INACTIVE-SYSTEM": { text: "ממתין לחזרה לשעות הפעילות", icon: "clock.png" },
    "ROUND-END": { text: "נגמר הסבב", icon: "checked.svg" },
    "TIME-ENDED": { text: "נגמר הזמן", icon: "clock.png" },
    "ALL-PURCHASED": { text: "כל הכרטיסים מומשו", icon: "checked.svg" },
}

export const msgStatus = (status: number, isPhone?: boolean) => {
    if (status === 400) return 'בקשה לא תקינה';
    if (status === 401) return 'אין הרשאה';
    if (status === 403) return 'אסור';
    if (status === 404) return 'לא נמצא';
    if (status === 409) return isPhone ? 'מספר הפלאפון כבר קיים במערכת' : 'כתובת המייל כבר קיימת במערכת';
    if (status === 500) return 'שגיאה פנימית בשרת';
    return 'Oops!'
}