import { useContext } from "react";

import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";
import { AGENCIES, ORGANIZATIONS, PROJECT_PARTICIPANTS } from "../consts/hebrew";

import BarSeparatedValues from "../generic-components/BarSeparatedValues";
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper";
import { StatisticsContext } from "../context/StatisticsContext";

import '../style/agenciesAndOrgsCount.scss';

const AgenciesAndOrgsCount: React.FC = () => {
    const { agenciesOrgsCounter } = useContext(StatisticsContext) as StatContextInterface;

    return (
        <WhiteBoxWrapper>
            <p className="stats-title">{PROJECT_PARTICIPANTS}</p>
            <BarSeparatedValues
                num1={agenciesOrgsCounter.agenciesCount || 0}
                num1title={AGENCIES}
                num2={agenciesOrgsCounter.organizationsCount || 0}
                num2title={ORGANIZATIONS}
                className="participants-count"
            />
        </WhiteBoxWrapper>
    )
}

export default AgenciesAndOrgsCount;