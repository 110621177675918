import OrganizationDemographicsPopupProps from "../../consts/interfaces/OrganizationDemographicsPopup.interface";
import { ORGANIZATION_TYPES } from "../../consts/hebrew";
import GenericTextNumberRow from "../../generic-components/GenericTextNumberRow";
import GenericPopup from "../../generic-components/GenericPopup";

import '../../style/organizationDemographicsPopup.scss';

const PopupOrganizationDemographics: React.FC<OrganizationDemographicsPopupProps> = ({ demographics, open, handleClose }) => {

    return (
        <GenericPopup className="organization-demographics-popup" title={ORGANIZATION_TYPES} open={open} handleClose={handleClose} >
            <div className="demographics-container">
                {demographics.map(demographic =>
                    <GenericTextNumberRow key={demographic.name} text={demographic.name} number={demographic.value} width="98%" />)}
            </div>
        </GenericPopup>
    )
}

export default PopupOrganizationDemographics;