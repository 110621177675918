import { useContext, useState } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { SYSTEM_TYPE, specificStatistics } from "../consts/interfaces/SpecificStatistics.interface";
import { STATS_FILTER_OPTIONS, STATS_REPORT } from "../consts/hebrew";

import { OrganizationStatisticsProvider } from "../context/OrganizationStatisticsContext";
import { AgencyStatisticsProvider } from "../context/AgencyStatisticsContext";
import { SpecificStatisticsContext } from "../context/SpecificStatisticsContext";

import AgencyStatistics from "./statistics-components/AgencyStatistics";
import OrganizationStatistics from "./OrganizationStatistics";
import StatsFilterDropdown from "./StatsFilterDropdown";
import DownloadButton from "./DownloadButton";

import '../style/statistics.scss';

const SpecificStatistics = () => {
    const { systemType, setFilterDateContext, filterDate } = useContext(SpecificStatisticsContext) as specificStatistics
    const [selectedFilter, setSelectedFilter] = useState<STATS_FILTER_OPTIONS>(STATS_FILTER_OPTIONS.ALL)
    const [reportLoading, setReportLoading] = useState<boolean>(false)

    const downloadReport = async () => {
        setReportLoading(true)
        const id = systemType === SYSTEM_TYPE.agency ? 'agencies-statistics' : 'organizations-statistics'
        const input: HTMLElement | null = document.getElementById(id);
        if (input) {
            html2canvas(input, { backgroundColor: '#f6f7f5' })
                .then((canvas) => {
                    const pdf = new jsPDF({
                        orientation: "landscape",
                        unit: "px",
                        format: [input.clientWidth, input.scrollHeight]
                    })

                    pdf.addImage(canvas, 'JPEG', 0, 0, input.clientWidth, input.scrollHeight)

                    const today = (new Date()).toLocaleDateString("en-GB").replaceAll('/', '-')
                    const filter = filterDate ? selectedFilter : ''

                    pdf.save(`${STATS_REPORT} ${filter} ${today}.pdf`);
                    setReportLoading(false)
                })
        }
    }

    return (
        <div className='statistics-page specific'>
            <div className='buttons-container'>
                <DownloadButton onClick={downloadReport} loading={reportLoading} />
                <StatsFilterDropdown selected={selectedFilter} setSelected={setSelectedFilter} setFilterDateContext={setFilterDateContext} />
            </div>
            {
                systemType === SYSTEM_TYPE.agency ?
                    <AgencyStatisticsProvider>
                        <AgencyStatistics />
                    </AgencyStatisticsProvider> :
                    <OrganizationStatisticsProvider>
                        <OrganizationStatistics />
                    </OrganizationStatisticsProvider>
            }
        </div >
    )

}

export default SpecificStatistics