import { useEffect, useState, useContext } from "react";
import { withStyles } from "@material-ui/styles";
import { useAsyncEffect } from "@hilma/tools";
import Tooltip from '@mui/material/Tooltip';
import { Fade } from '@mui/material';
import axios from "axios";
import clsx from "clsx";

import { Event, InfoBarTicket } from "../consts/interfaces/EventInfoBar.interface";
import { OrgListItem } from "../consts/interfaces/OrgsFeedback.interface";
import { HOW_MUCH_SEAT_GROUP } from "../consts/hebrew";
import { _ERROR_MESSAGE } from "../consts/variables";

import { ErrorContext } from "../context/ErrorContext";
import { useSocketListener } from "../hooks/socket";

import OrgsFeedback from "./OrgsFeedback";

import "../style/infoBar.scss";

interface InfoBarProps {
  barType: "feedback" | "view-event";
  eventId: number;
}
const InfoBar: React.FC<InfoBarProps> = (props) => {
  const [organizationList, setOrganizationList] = useState<Array<OrgListItem>>([])
  const [eventInfo, setEventInfo] = useState<Event>({
    address: '',
    tickets: [],
    seatGroups: 0,
    numOfOrganization: 0,
    feedback: 0
  })

  const { showError } = useContext(ErrorContext);

  const [firstImage, setFirstImage] = useState<string>('');
  const [secondImage, setSecondImage] = useState<string>('');
  const [thirdImage, setThirdImage] = useState<string>('');

  const [firstText, setFirstText] = useState<string>('');
  const [secondText, setSecondText] = useState<string>('');
  const [thirdText, setThirdText] = useState<string>('');

  const [purchasedTickets, setPurchasedTickets] = useState<number | undefined>()
  const [availableTickets, setAvailableTickets] = useState<number | undefined>()

  //gets details for info bar from the server
  const fetchDetailsForInfoBar = async () => {
    try {
      const { data } = (await axios.get(`/api/event/details-of-event-info-bar?id=${props.eventId}`));

      const organizations: number[] = []
      data.tickets.forEach((ticket: InfoBarTicket) => {
        if (!organizations.includes(ticket.organizationId) && ticket.organizationId !== null && ticket.status === 'purchased') {
          organizations.push(ticket.organizationId)
        }
      })

      setPurchasedTickets(data.tickets.filter((ticket: InfoBarTicket) => ticket.status === 'purchased').length)
      setAvailableTickets(data.tickets.filter((ticket: InfoBarTicket) => ticket.status !== 'purchased').length)

      setEventInfo({
        address: data.location,
        tickets: data.tickets,
        seatGroups: data.seatGroups.length,
        numOfOrganization: organizations.length,
        feedback: data.feedbacksCount
      })

      setOrganizationList(await Promise.all(organizations.map(async (id) => {
        const orgsLogoAndName = await axios.get(`/api/organization/organization-info?id=${id}`);
        const orgName = orgsLogoAndName.data.originalInfo[0].organization.organizationName;
        const logo = orgsLogoAndName.data.originalInfo[0].organization.profilePhoto;
        return { name: orgName, logoImage: logo };
      })));
    }
    catch {
      showError(_ERROR_MESSAGE);
    }
  };

  useAsyncEffect(fetchDetailsForInfoBar, [props.eventId]);
  useSocketListener('ticket-purchased', fetchDetailsForInfoBar);

  useSocketListener('feedback-sent', () => {
    setEventInfo((prev) => {
      return {
        ...prev,
        feedback: prev.feedback + 1
      }
    });
  });

  useEffect(() => {
    switch (props.barType) {
      case 'view-event':
        setFirstImage("emptyPlace.svg")
        setSecondImage("emptyTicket.svg")
        setThirdImage("emptyCouch.svg")
        setFirstText(eventInfo.address)
        setSecondText(`${availableTickets} כרטיסים פנויים`)
        setThirdText(`${eventInfo.seatGroups} קבוצות מושבים`)
        break;

      case 'feedback':
        setFirstImage("emptyTicket.svg")
        setSecondImage("emptyCouch.svg")
        setThirdImage("new-message.svg")

        setFirstText(`הוזמנו ${purchasedTickets} כרטיסים מתוך ${eventInfo.tickets.length} `)
        setSecondText(`על ידי ${eventInfo.numOfOrganization} עמותות`)
        setThirdText(`התקבלו ${eventInfo.feedback} תגובות`)
        break;
    }
  }, [props.barType, eventInfo, purchasedTickets, availableTickets])

  const TooltipStyles = withStyles({
    tooltip: {
      backgroundColor: '#F6F7F5',
      boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.16)',
      borderRadius: 'unset',
    },
    arrow: {
      color: '#F6F7F5',
      fontSize: '5vh'
    }
  })(Tooltip);

  return (
    <div className="container-info-flex">
      <div className="small-container" >
        <div className="img-container">
          <img
            className="icon"
            src={`/icons-admin/${firstImage}`}
            alt=""
          />
        </div>
        <span className="text">{firstText}</span>
      </div>

      {props.barType === "feedback" ?
        <TooltipStyles
          title={organizationList.length ? <OrgsFeedback orgs={organizationList} /> : ""}
          arrow
        >
          <div className="small-container" >
            <div className="img-container">
              <img
                className="icon"
                src={`/icons-admin/${secondImage}`}
                alt=""
              />
            </div>
            <span className={clsx("text", organizationList.length > 0 && "orgs")}>{secondText}</span>
          </div>
        </TooltipStyles>
        :
        <div className="small-container" >
          <div className="img-container">
            <img
              className="icon"
              src={`/icons-admin/${secondImage}`}
              alt=""
            />
          </div>
          <span className="text">{secondText}</span>
        </div>}

      {props.barType === "feedback" ?
        <div className="small-container" >
          <div className="img-container">
            <img
              className="icon"
              src={`/icons-admin/${thirdImage}`}
              alt=""
            />
          </div>
          <span className="text">{thirdText}</span>
        </div>
        :
        <TooltipStyles
          className="tooltip-groups"
          title={HOW_MUCH_SEAT_GROUP}
          arrow
          TransitionComponent={Fade}
          enterTouchDelay={0}
          leaveTouchDelay={0}
        >
          <div className="small-container" >
            <div className="img-container">
              <img
                className="icon"
                src={`/icons-admin/${thirdImage}`}
                alt=""
              />
            </div>
            <span className="text">{thirdText}</span>
          </div>
        </TooltipStyles>
      }
    </div >
  );
}
export default InfoBar;