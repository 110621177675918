import { ComponentType } from "react";
import { useConfig } from "../context/ConfigContext";

export function featureFlagComponent<
  P extends {},
  C extends ComponentType<P>,
>(featureId: string, Component: C): ComponentType<P> {
  const wrapperComponent = (props: P) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { featureFlags } = useConfig();
    const featureIsEnabled = featureFlags[featureId] === true;
    
    if (!featureIsEnabled) return <></>;

    return <Component {...props as any} />;
  }

  const originalComponentDisplayName = Component.displayName || Component.name;
  wrapperComponent.displayName = `FeatureFlag: ${featureId}(${originalComponentDisplayName})`;

  return wrapperComponent;
}
