//Server error message popup

import React, { useState } from "react";
import ErrorMessage from "../generic-components/ErrorMessage";

export const ErrorContext = React.createContext({ showError: (_message?: string) => { } });

const ErrorProvider: React.FC = ({ children }) => {

    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("אירעה שגיאה")

    function showError(message?: string) {
        if (message) {
            setMessage(message)
        }
        setOpen(true)
        setTimeout(() => {
            setOpen(false)
        }, 5000)
    }

    return (
        <ErrorContext.Provider
            value={{
                showError
            }}
        >
            {children}
            <ErrorMessage open={open} setOpen={setOpen} message={message} />
        </ErrorContext.Provider>
    );

}

export default ErrorProvider
