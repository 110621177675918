import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';

import { AgencyEventFlowEnum, TICKETS, WERE_ORDERED } from '../consts/hebrew';
import { AgencyEventFlowProps, OrgOfferProfile, OrgOfferProfileFormatted } from '../consts/interfaces/AgencyEventFlow.interface';
import { EVENT_STATUS } from '../consts/variables';

import GenericProfilePic from '../generic-components/GenericProfilePic';
import { calculateTimeDifference, eventToDismantledDate } from "../functions/dateFunctions";
import { useEventOffersQuery } from '../hooks/api/event';
import { useSocketListener } from '../hooks/socket';

import '../style/agencyEventFlow.scss'

const AgencyEventFlow: React.FC<AgencyEventFlowProps> = ({ eventId, eventStatus, timeLeft, publishedAt, agencyName, timerDuration, expirationDate }) => {
    const { data, refetch } = useEventOffersQuery(eventId);

    const [showMoreOrgs, setShowMoreOrgs] = useState<boolean>(false)

    const formattedPublishedAt = useMemo(() => publishedAt ? eventToDismantledDate(publishedAt) : null, [publishedAt])

    const timerPercentage = () => {
        const secondsLeft = calculateTimeDifference(expirationDate) / 1000
        const percentLeft = secondsLeft / Number(timerDuration) * 100
        return 100 - percentLeft
    }

    const formattedOrgs = useMemo(() => {
        if (data) {
            return data.map((org: OrgOfferProfile) => {
                const reasonForReject = org.feedbackText ? AgencyEventFlowEnum.NOT_RELEVANT : AgencyEventFlowEnum.TIME_END
                const formattedDateOffered = eventToDismantledDate(org.offerReceivedAt);
                const formattedDatePurchased = org.ticketsPurchasedAt
                    ? eventToDismantledDate(org.ticketsPurchasedAt)
                    : null;
                return {
                    ...org,
                    formattedDateOffered,
                    formattedDatePurchased,
                    reasonForReject
                };
            });
        }
        return [];
    }, [data]);

    useSocketListener('organization-added-live', () => {
        refetch()
    });

    return (
        <div className='agency-event-flow'>
            <div className='event-published-name-date'>
                <div className='event-publisher'>{`${AgencyEventFlowEnum.EVENT_PUBLIC_BY} ${agencyName}`}</div>
                {publishedAt ? <div>{`${formattedPublishedAt?.time} | ${formattedPublishedAt?.date}`}</div> : null}

            </div>
            <Timeline className={showMoreOrgs ? 'timeline-with-curve' : 'timeline'}
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}>

                {formattedOrgs.map((organization: OrgOfferProfileFormatted, index: number) => {
                    const isTimer = index === formattedOrgs.length - 1 && timeLeft
                    return (
                        <TimelineItem key={organization.id} className='timeline-item'>
                            <TimelineSeparator className='time-line-separator'>
                                <div className={clsx('timer-wrapper', { 'timer': isTimer })}
                                    style={isTimer ? {
                                        background: `radial-gradient(closest-side, white 90%, transparent 0),
                                                    conic-gradient(#FFC266 ${timerPercentage()}%, #e6e6e6 0)`
                                    } : {}}>
                                    <GenericProfilePic className='timeline-profile-pic-wrapper' src={organization.profilePhoto || ''} imageCoordinates={organization.imageCoordinates} />
                                </div>

                                {isTimer
                                    ? null
                                    : <img className='arrow' src='/icons-admin/timeline-arrow-left.svg' alt='arrow-left' />}
                            </TimelineSeparator>

                            <TimelineContent className='timeline-content' sx={{ py: '12px', px: 2, textAlign: 'right' }}>
                                <div className='timeline-item-content'>
                                    <div className='organization-name'>{organization.name}</div>
                                    <div className='offer-accepted'>{`${AgencyEventFlowEnum.OFFER_GET}`}</div>
                                    <div className='date-format'> {`${organization.formattedDateOffered.time} | ${organization.formattedDateOffered.date}`}</div>
                                    {index === formattedOrgs.length - 1 ?
                                        <>
                                            {timeLeft ?
                                                <div className='tickets'>{` ${AgencyEventFlowEnum.TICKETS_SAVE_MORE} ${timeLeft}`}</div>
                                                :
                                                <div className='tickets'>
                                                    {organization.countOfPurchasedTickets ?
                                                        <>
                                                            <div className='tickets-purchased'>{`${WERE_ORDERED} ${organization.countOfPurchasedTickets} ${TICKETS}`}</div>
                                                            <div className='tickets-purchased'>{`מתוכם ימומשו ${organization.countOfUsedTickets}`}</div>
                                                            {organization.formattedDatePurchased && <div className='date-format'> {`${organization.formattedDatePurchased.time} | ${organization.formattedDatePurchased.date}`}</div>}
                                                        </>
                                                        :
                                                        <>
                                                            <div>{AgencyEventFlowEnum.NO_TICKETS_ORDER}</div>
                                                            <div>{organization.reasonForReject}</div>
                                                        </>
                                                    }
                                                </div>}
                                        </>
                                        :
                                        <>
                                            <div className='tickets'>
                                                {organization.countOfPurchasedTickets ?
                                                    <>
                                                        <div className='tickets-purchased'>{`${WERE_ORDERED} ${organization.countOfPurchasedTickets} ${TICKETS}`}</div>
                                                        <div className='tickets-purchased'>{`מתוכם ימומשו ${organization.countOfUsedTickets}`}</div>
                                                    </>
                                                    : <div>{AgencyEventFlowEnum.NO_TICKETS_ORDER}</div>}
                                            </div>
                                            {organization.formattedDatePurchased && <div className='date-format'> {`${organization.formattedDatePurchased.time} | ${organization.formattedDatePurchased.date}`}</div>}

                                        </>}
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
                {!timeLeft && eventStatus
                    ? <TimelineItem className='timeline-item'>
                        <TimelineSeparator className='time-line-separator'>
                            <div className='timeline-event-status-pic-wrapper'>
                                <img src={`/icons-admin/${EVENT_STATUS[eventStatus].icon}`} className="timeline-img" alt="org-profile-pic" />
                            </div >
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2, textAlign: 'right' }}>
                            <div className='timeline-item-content'>
                                <div className='event-status-title'>
                                    {EVENT_STATUS[eventStatus].text}
                                </div>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    : null}
            </Timeline>

            {formattedOrgs.length > 4
                ? <div onClick={() => setShowMoreOrgs(!showMoreOrgs)} className='show-more'>
                    <span className='show-more-text'>{showMoreOrgs ? `${AgencyEventFlowEnum.SHOW_LESS}` : `${AgencyEventFlowEnum.SHOW_MORE}`}</span>
                    <span className={clsx('show-more-arrow', { 'down': !showMoreOrgs })}>{showMoreOrgs ? '⌃' : '⌄'}</span>
                </div>
                : null}
        </div >
    )
}

export default AgencyEventFlow;