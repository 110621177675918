export const FEEDBACKS = 'פידבקים'
export const NO_PAST_EVENTS = 'אין אירועים שהתקיימו';
export const WERE_ORDERED = 'הוזמנו';
export const OUT_OF = "מתוך";
export const TICKETS = "כרטיסים"
export const SEARCH = "חיפוש";
export const TICKET_NUMBER = "מספר כרטיסים";
export const EVENT_NUMBER = "מספר אירועים";

export enum AgencyEventFlowEnum {
    NOT_RELEVANT = "לא רלוונטי",
    TIME_END = "נגמר הזמן",
    NO_TICKETS_ORDER = `לא הוזמנו כרטיסים`,
    EVENT_PUBLIC_BY = ` האירוע פורסם ע"י `,
    OFFER_GET = "התקבלה הצעה",
    TICKETS_SAVE_MORE = 'הכרטיסים שמורים עוד ',
    ALL_THE_TICKETS = 'כל הכרטיסים',
    PURCHASED = 'מומשו',
    SHOW_LESS = 'הצג פחות',
    SHOW_MORE = 'הצג עוד',
}

export enum MostInvolvedEnum {
    AGENCY = `המוסד שהעניק\nהכי הרבה אירועים`,
    ORGANIZATION = `העמותה שנהנתה\nבהכי הרבה אירועים`
}

export const AND_AGENCIES = "ומוסדות";
export const THERE_IS = "יש";
export const WAITING_MALE = "הממתינים";
export const WAITING_FEMALE = "הממתינות";
export const TO_ACCEPT = "לאישור הצטרפות לאנטר";
export const DONATION_WORTH = "שווי התרומה במיזם";
export const AGENCIES = "מוסדות";
export const ORGANIZATIONS = "עמותות";
export const NUM_OF_GIVING_AGENCIES = "מוסדות שהעניקו";
export const NUM_OF_RECEIVING_ORGANIZATIONS = "עמותות שנהנו";
export const NUM_OF_DONATED_TICKETS = "כרטיסים שהוענקו";
export const NUM_OF_PURCHASED_TICKETS = "כרטיסים שנמשכו";
export const PROJECT_PARTICIPANTS = "שותפים למיזם";
export const EVENT_TYPES = "סוגי אירועים";
export const TO_ALL_EVENT_CATEGORIES = "לכל סוגי האירועים";
export const ORGANIZATION_TYPES = "סוגי עמותות";
export const TO_ALL_ORGANIZATION_TYPES = "לכל סוגי העמותות";
export const TOP_DONATING_AGENCIES = "TOP 5 מוסדות שהעניקו הכי הרבה אירועים";
export const TOP_RECEIVING_ORGANIZATIONS = "TOP 5 עמותות שנהנו בהכי הרבה אירועים";
export const TOTAL_GIVEN_FEEDBACKS = "פידבקים ניתנו דרך האתר סך הכל";
export const FEEDBACK_ORGANIZATION_GAVE = "פידבקים העמותה העניקה דרך האתר";
export const TO_ALL_FEEDBACKS = "לכל הפידבקים";
export const FEEDBACKS_AGENCY_RECEIVED = "פידבקים המוסד קיבל דרך האתר";
export const OCCURRED_EVENTS = 'אירועים שהתקיימו'
export const OCCURRED_EVENTS_AMOUNT = 'אירועים התקיימו'
export const CANCELED_EVENTS_AMOUNT = 'אירועים בוטלו'
export const CANCELED_EVENTS = 'אירועים שבוטלו'
export const EVENTS_OCCURRENCE = 'התרחשות אירועים'
export const MORE_DETAILS = 'פרטים נוספים'
export const AGENCIES_CANCELED_EVENTS = "המוסדות שביטלו אירועים";
export const REASONS_TO_CANCEL_EVENT = "סיבות לביטול אירוע";
export const OTHER = "אחר";
export const CANCEL_EVENT_DETAILS = "פרטים ביטול אירוע";

export const CLICKED_ON_NOT_RELEVANT = 'פעמים עמותות לחצו על לא רלוונטי';
export const FROM_ALL_THE_OFFERS = 'מתוך כל ההצעות לאירועים';
export const REASONS_WHY_NOT_RELEVANT = 'סיבות למה אירוע לא רלוונטי'

export enum NOT_RELEVANT_FEEDBACKS {
    TOO_SOON = 'ההתראה קצרה מידי',
    TIME_NOT_GOOD = 'מועד זה לא מתאפשר',
    NO_MATCH = 'לא מתאים באופי',
    OTHER = 'אחר'
}

export enum NOT_RELEVANT_FEEDBACKS_POPUP_STATISTICS {
    TOO_SOON = 'התראה קצרה מידי',
    TIME_NOT_GOOD = 'מועד זה לא מתאפשר לנו',
    NO_MATCH = 'האירוע לא מתאים באופי שלו',
}

export const EVENTS = 'אירועים'
export const ALL_TICKETS_PURCHASED = 'כל הכרטיסים מומשו'
export const TICKETS_WERE_NOT_PURCHASED_AT_ALL = 'לא מומשו כרטיסים בכלל'
export const ABOVE_HALF = 'יותר מ50% מהכרטיסים מומשו'
export const UNDER_HALF = 'פחות מ50% מהכרטיסים מומשו'
export const TICKETS_UTILIZATION_OF_EVENTS = 'התפלגות מימוש כרטיסים באירועים'
export const REASONS_WHY_EVENT_NOT_RELEVANT = "סיבות למה אירוע לא רלוונטי";
export const POST_EVENT = "פרסום אירוע";
export const OFFER_ORGANIZATIONS = "הצעה לעמותות";
export const TICKETS_USE = "מימוש כרטיסים";
export const EVENT_START = "תחילת אירוע";
export const MINUTES = "דקות";
export const HOURS = "שעות";
export const AVERAGE_TIME_USE_SINCE_POST = "ממוצע זמן מימוש מרגע הפרסום";
export const AVERAGE_TIME_USE_SINCE_OFFER = "ממוצע זמן מימוש מרגע ההצעה";
export const AVERAGE_NUMBER_ORGANIZATIONS_OFFERED = "ממוצע מספר עמותות שהוצע להם";
export const AVERAGE_TIME_POST_BEFORE_EVENT = "ממוצע זמן פרסום לפני אירוע";
export const DAYS = "ימים";
export const IS_TICKETS_DONATION_DONE_AHEAD = "האם תרומת הכרטיסים לאנטר מתבצעת מראש או ברגע האחרון";
export const HOW_LONG_IT_TAKES_ORGANIZATION_TO_RECEIVE_TICKET = "כמה זמן לוקח לעמותה למשוך את הכרטיסים אליה";
export const HOW_MANY_ORGANIZATIONS_THE_EVENT_PASSED = "בכמה עמתות האירוע עבר עד שהגיע לעמותה המתאימה";
export const HOW_LONG_ENTER_TOOK_TO_FILL_THE_LINE = "כמה זמן לקח לאנטר למלא את השורה";

export const EXTRA_TIME = "תוספת זמן";
export const TIMES_USERS_CLICKED_ADD_TIME = "פעמים עמותות לחצו על תוספת זמן";
export const OUT_OF_ALL_OFFERS = "מתוך כל ההצעות";
export const TO_EVENTS = "לאירועים";
export const USED_AND_UTILIZATION = 'ניצול ומימוש';

export enum STATS_FILTER_OPTIONS {
    ALL = 'הכל',
    LAST_WEEK = 'בשבוע האחרון',
    LAST_MONTH = 'בחודש האחרון',
    LAST_YEAR = 'השנה'
}

export const STATS_REPORT = 'דוח סטטיסטיקות';
export const ORGS_RECEIVED = 'עמותות משכו'
export const FROM_THIS_AGENCY = `כרטיסים ממוסד זה`
export const FROM_POST_TO_PURCHASE = 'זמן ממוצע לפרסום אירוע'

export const FEEDBACKS_TO_SPECIFIC_AGENCY = 'פידבקים הוענקו למוסד זה'
export const NO_DATA = "אין עדיין נתונים";
export const EVENTS_ARCHIVE = 'ארכיון אירועים'

export enum EVENT_TIME_STATUS {
    ALL_TICKETS_PURCHASED = 'כל הכרטיסים מומשו',
    LEFT_TIME_FOR_PURCHASING = 'הזמן הנותר למשיכת הכרטיסים',
    TIME_IS_OVER = 'נגמר הזמן'
}

export const PURCHASED_EVENTS = "אירועים שנרכשו";
export const NO_RESULTS = "לא נמצאו תוצאות"
export const SUSPENDED = 'מושהה';
export const ACTIVE = 'פעיל'
export const ORGANIZATION_SUSPENDED = 'השהיית עמותה'
export const AGENCY_SUSPENDED = 'השהיית מוסד'

export const ERROR_LOADING_FILE = 'אין תצוגה מקדימה'
export const EDIT = "עריכה";
export const SAVE_CHANGES = "שמירת שינויים";
export const CANCEL = "ביטול";
export const EDIT_PHOTO = 'עריכת תמונה';
export const DELETE_PHOTO = "מחיקת תמונה";
export const LIMIT_ERROR = "אנא העלו קובץ עד 3MB"
export const LOAD_IMAGE = 'טעינת תמונה';
export const CONFIRM_DISCARD_CHANGES = "לבטל את השינויים?"

export enum REMOTE_LOGIN_TEXTS {
    POPUP_CODE_SEND_TO_MAIL = 'נשלח קוד אימות למייל',
    POPUP_SEND_CODE_AGAIN = "לא קיבלתם קוד? נסו שנית",
    POPUP_TITLE_TEXT = "התחברות לחשבון אנטר של",
    FIRST_POPUP_INFO_TEXT = 'כל הפעולות שיבוצעו ייחשבו במערכת',
    SECOND_POPUP_INFO_TEXT_ORGANIZATION = 'כאילו העמותה הנ"ל ביצעה',
    SECOND_POPUP_INFO_TEXT_AGENCY = 'כאילו המוסד הנ"ל ביצעה',
    VERIFY_CODE_TEXT_BUTTON = 'אישור, שלחו לי קוד אימות למייל',
    SEND_CODE_TEXT_BUTTON = 'התחברות',
}

export const NOT_INTERESTED_MAYBE_NEXT_TIME = 'לא מעוניין, אולי פעם הבאה'
export const AVAILABLE_HEARING_AIDS = "מכשירי שמיעה נגישים"
export const HOW_MUCH_SEAT_GROUP = 'אם יש יותר מקבוצת מושבים אחת המשמעות היא שהכרטיסים נמצאים בקבוצות ישיבה שונות באזור האולם בהתאם לכמות הכרטיסים ולנגישות.'
export const AVAILABLE_SEATS = "מקומות זמינים"

export enum GenericTagsEnum {
    CATEGORY = "קטגוריות:",
    NO_CHOSE_CATEGORY = "לא נבחרו קטגוריות",
    ASSEBILITY = "נגישות:",
    NO_ASSEBILITY = "אין נגישויות",
    GENERAL_MESSAGE = "אירעה שגיאה. נסו שוב מאוחר יותר",
    INTENDED_FOR_SECTORS = "מותאם למגזרים:",
    TYPE_EVENT = "סוג אירוע:",
    SEAT = "בישיבה",
    STAND = "בעמידה",
    PLACE_MARKET = ', מקומות מסומנים',
    PLACE_NOT_MARKET = ', מקומות לא מסומנים',
    TIME_OF_EVENT = "משך זמן האירוע:",
    HOURS = "שעות",
    MINUTES = "דקות",
    WAY_COMEING = "דרכי הגעה:",
    AGES = "טווח גילאים:",
    MONY_TICKETS = "שווי כרטיס:",
    READ_LESS = 'קרא פחות',
    READ_MORE = 'קרא עוד',
    REGULAR_CHAIRS_ONLY = 'מושבים רגילים בלבד',
    EVENT_VIP = "אירוע VIP"
}
export enum GenericSeatGroupEnum {
    SEAT_GROUP = 'קבוצת מושבים',
    TICKETS = 'כרטיסים',
    ACCESSIBILITY = 'נגישות:',
    REGULAR_SEATS = 'מושבים רגילים / ללא נגישות'
}
export enum Navbar {
    STATISTICS = 'סטטיסטיקות',
    AGENCIES = 'מוסדות',
    ORGANIZATIONS = 'עמותות',
    LIVE = 'LIVE'
}
export const enum DROPDOWN_MENU {
    LOGIN = 'התחבר',
    LOGOUT = 'התנתקות',
}