import clsx from 'clsx';
import '../style/bottomTextArrow.scss';

const BottomTextArrow: React.FC<{ bottomText: string, onBottomTextClick: React.MouseEventHandler<HTMLDivElement>, className?: string }> = ({ bottomText, onBottomTextClick, className }) => {

    return (
        <div className={clsx(['bottom-text', className])} onClick={onBottomTextClick}>
            <span>{bottomText}</span> <img src='/icons-admin/small-arrow.svg' alt='arrow' />
        </div>

    )
}

export default BottomTextArrow;