import clsx from "clsx";
import { GenericDropDownProps } from "../consts/interfaces/GenericDropDown.interface";
import "../style/genericDropDown.scss";

const GenericDropDown: React.FC<GenericDropDownProps> = ({ isOpen, elements, className }) => {
    return (
        <div className={clsx('dropdown-container', { 'shown': isOpen, 'hidden': !isOpen }, className)}>
            {elements.map(element => {
                return (
                    <div key={element.label} className="dropdown-element" onClick={element.onClick} >
                        <div className="element-text"> {element.label} </div>
                        <div className="log-out-img"><img src={element.limg} alt='profile picture' /></div>
                    </div>
                )
            })}
        </div>
    )

}

export default GenericDropDown;