import { FEEDBACK_ORGANIZATION_GAVE, TO_ALL_FEEDBACKS } from "../consts/hebrew";
import GenericFeedbackStats from "../generic-components/GenericFeedbackStats";

const OrganizationGivenFeedbacks: React.FC<{ totalFeedbacks: number }> = ({ totalFeedbacks }) => {

    return (
        <GenericFeedbackStats
            feedbackCount={totalFeedbacks || 0}
            text={FEEDBACK_ORGANIZATION_GAVE}
            bottomText={TO_ALL_FEEDBACKS}
            onBottomTextClick={() => { }}
            style={{ width: '20vw', maxHeight: '37vh' }}
        />
    )
}

export default OrganizationGivenFeedbacks;