import { useContext, useEffect, useState } from "react";

import { AVERAGE_TIME_USE_SINCE_OFFER, HOURS, HOW_LONG_IT_TAKES_ORGANIZATION_TO_RECEIVE_TICKET, MINUTES } from "../consts/hebrew";
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface";

import GenericInfoToolTip from "../generic-components/GenericInfoToolTip";
import { StatisticsContext } from "../context/StatisticsContext";

import '../style/averageTimeFromOffer.scss';

const AverageTimeTillUseFromOffer: React.FC<{
    setStages: React.Dispatch<React.SetStateAction<{
        text: string;
        color?: string | undefined;
    }[]>>,
}> = ({ setStages }) => {
    const { avgTimeFromOffer } = useContext(StatisticsContext) as StatContextInterface

    const [minutes, setMinutes] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);

    useEffect(() => {
        setMinutes(avgTimeFromOffer.minutes)
        setHours(avgTimeFromOffer.hours)
    }, [avgTimeFromOffer])

    const onMouseEnter = () => {
        const hoverColor = "#95C1CE";

        setStages(prev =>
            prev.map((stage, index) => index === 1 || index === 2 ? ({ ...stage, color: hoverColor }) : stage))
    }

    const onMouseLeave = () => {
        setStages(prev => prev.map(({ text }) => ({ text })))
    }

    return (
        <div className="average-time-from-offer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="time">
                <div className="time-unit">
                    <div className="number">
                        {minutes}
                    </div>
                    <div className="text">
                        {MINUTES}
                    </div>
                </div>
                :
                <div className="time-unit">
                    <div className="number">
                        {hours}
                    </div>
                    <div className="text">
                        {HOURS}
                    </div>
                </div>
            </div>

            <div className="description">
                {AVERAGE_TIME_USE_SINCE_OFFER}
                <GenericInfoToolTip text={HOW_LONG_IT_TAKES_ORGANIZATION_TO_RECEIVE_TICKET} />
            </div>
        </div>
    )
}

export default AverageTimeTillUseFromOffer;