import GenericFeedbackStats from "../generic-components/GenericFeedbackStats";
interface TotalFeedbacksGivenProps {
    totalFeedbacks: number
    textUnder: string
}
const TotalFeedbacksGiven: React.FC<TotalFeedbacksGivenProps> = ({ totalFeedbacks, textUnder }) => {
    return (
        <GenericFeedbackStats feedbackCount={totalFeedbacks || 0} text={textUnder} style={{ width: '15vw' }} />
    )
}

export default TotalFeedbacksGiven;