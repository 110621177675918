import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Tab, Tabs } from "@mui/material";

import { MiniNavbarProps } from "../consts/interfaces/MiniNavbar.interface";
import { LinkTabProps } from "../consts/interfaces/LinkTab.interface";
import { _ERROR_MESSAGE } from "../consts/variables";

import { useRequestNumber } from "../context/RequestNumberContext";
import { ErrorContext } from "../context/ErrorContext";

import { useNumberOfPendingApprovalRequestsQuery } from "../hooks/api/pending-entity-approval-requests";
import { useSocketListener } from '../hooks/socket';

import '../style/genericMiniNavbar.scss';

const GenericMiniNavbar: React.FC<MiniNavbarProps> = ({ subtitles }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const { requestNumber, setRequestNumber } = useRequestNumber(); //socket
    const { showError } = useContext(ErrorContext);

    const type = useMemo(() => {
        const short = location.pathname.split('/')[1] || "";
        if ((short.substring(0, short.length) === 'agencies')) {
            return 'agency'
        }
        return short.substring(0, short.length - 1);
    }, [location]);

    const numberOfRequestsQuery = useNumberOfPendingApprovalRequestsQuery(
        type,
        {
            onSuccess(newNumberOfRequests: number) {
                setRequestNumber(newNumberOfRequests); // it's not a component state, we can't use the data of the query here
            },
            onError() {
                showError(_ERROR_MESSAGE);
            },
            enabled: type === 'organization' || type === 'agency',
        },
    );

    //checks to see if its an org or agency
    const systemType = useMemo(() => {
        return location.pathname.split('/')[1];
    }, [location])

    //gives the system name back but in hebrew
    const system = useMemo(() => {
        if (location.pathname.split('/')[1] === 'organizations') {
            return 'עמותות'
        }
        return 'מוסדות'
    }, [location])


    //array of two types of pages and there routes
    const miniNavOptions = useMemo(() => {
        if (subtitles)
            return subtitles
        return (
            [{
                name: `רשימת ${system}`,
                href: ''
            },
            {
                name: 'בקשות',
                href: '/requests'
            }])
    }, [system, subtitles]);

    //checks request number
    const handleEntityAdded = () => {
        numberOfRequestsQuery.refetch();
    };
    useSocketListener('agency-added', handleEntityAdded);
    useSocketListener('organization-added', handleEntityAdded);

    const LinkTab = (props: LinkTabProps) => {
        return (
            <Tab
                component="a"
                icon={props.label === 'בקשות' ? <div className={`notification-circle ${requestNumber > 9 ? 'two-number' : ''}`}>{requestNumber}</div> : undefined}
                onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    event.preventDefault();
                    navigate(`/${systemType}${props.href}`);
                }}
                {...props}
            />
        );
    }

    const [value, setValue] = useState(0);

    useEffect(() => {
        const currentLocation = location.pathname.split('/')[2]
        if (currentLocation === 'requests' || currentLocation === 'archive-events' || currentLocation === 'request-profile') {
            setValue(1);
        }
        else setValue(0)
    }, [location.pathname]);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className='mini-nav-full main-nav'>
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
                {miniNavOptions.map((item, index) => {
                    return <LinkTab key={index} label={item.name} href={item.href}></LinkTab>
                })}
            </Tabs>
        </div>
    )
}
export default GenericMiniNavbar;