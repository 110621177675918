//User context - here we save he users info and what type of role he has so he can stay logged in on every page untill he loggs out
import React, { createContext, useState } from "react";

export const UserContext = createContext({
    role: '',
    name: '',
    systemID: 0,
    userName: "",
    setRole: (_val: string) => { },
    setName: (_val: string) => { },
    setUserName: (_val: string) => { },
    setSystemID: (_val: number) => { },
    loggingIn: (_role: string, _name: string, _systemID: number, _userName: string) => { },

});

const UserProvider: React.FC = ({ children }) => {

    const [role, setRole] = useState("")
    const [name, setName] = useState("")
    const [userName, setUserName] = useState("")
    const [systemID, setSystemID] = useState(Number)

    const loggingIn = (name: string, role: string, systemID: number, userName?: string) => {
        setRole(role)
        setName(name)
        setSystemID(systemID)
        userName ? setUserName(userName) : setUserName("")
    }

    return (
        <UserContext.Provider
            value={{
                role,
                name,
                systemID,
                userName,
                setUserName,
                setRole,
                setName,
                setSystemID,
                loggingIn
            }}
        >
            {children}

        </UserContext.Provider>
    )
}



export default UserProvider