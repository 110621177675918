import { useSocket } from "@hilma/socket.io-react";
import { useEffect, useRef } from "react";

export function useSocketListener<T>(eventName: string, eventHandler: (event: T) => void) {
    const socket = useSocket();
    const registeredEventHandler = useRef<Function>();
    const registeredEventName = useRef<string>();
    const registeredSocketInstance = useRef<typeof socket>();

    useEffect(() => {
        socket.on(eventName, eventHandler);
        registeredEventHandler.current = eventHandler;
        registeredEventName.current = eventName;
        registeredSocketInstance.current = socket;

        return () => {
            if (!registeredEventHandler.current || !registeredEventName.current || !registeredSocketInstance.current) return;
            registeredSocketInstance.current.off(registeredEventName.current, registeredEventHandler.current);
        }
    }, [socket, eventName, eventHandler]);
}