import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import StatsFilterDropdownProp from '../consts/interfaces/StatsFilterDropdown.interface';
import { STATS_FILTER_OPTIONS } from '../consts/hebrew';
import '../style/statsFilterDropdown.scss';

const StatsFilterDropdown: React.FC<StatsFilterDropdownProp> = ({ selected, setSelected, setFilterDateContext }) => {

    const statsFilterOptions = useRef<STATS_FILTER_OPTIONS[]>(Object.values(STATS_FILTER_OPTIONS))
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        setFilterDateContext(getDateFromFilter(selected))
    }, [selected])

    const getDateFromFilter = (filter: STATS_FILTER_OPTIONS) => {
        const date = new Date();

        switch (filter) {
            case STATS_FILTER_OPTIONS.LAST_YEAR:
                date.setFullYear(date.getFullYear() - 1);
                break;
            case STATS_FILTER_OPTIONS.LAST_MONTH:
                date.setMonth(date.getMonth() - 1);
                break;
            case STATS_FILTER_OPTIONS.LAST_WEEK:
                date.setDate(date.getDate() - 7);
                break;
            default:
                return undefined;
        }
        return date
    }

    return (
        <div className='dropdown-btn' onClick={() => setIsOpen(prev => !prev)}>
            {selected}
            <img src='/icons-admin/small-arrow.svg' className={clsx('arrow', { 'arrow-up': isOpen })} alt='dropdown arrow' />

            {isOpen &&
                <div className='dropdown-popup'>
                    {statsFilterOptions.current?.map(option =>
                        <div className={clsx('option', { selected: option === selected })} onClick={() => setSelected(option)}>
                            {option}
                        </div>
                    )}
                </div>}
        </div >
    )
}

export default StatsFilterDropdown;