import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "@hilma/auth";

import { emailValidation, passwordValidation } from "../functions/validations";
import { LOGIN, _ERROR_MESSAGE } from "../consts/variables";
import { ErrorContext } from "../context/ErrorContext";

import GenericButton from "../generic-components/GenericButton";
import GenericInput from "../generic-components/GenericInput";
import "../style/login.scss";

const LogIn: React.FC = () => {
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [emailValue, setEmailValue] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [serverLoading, setServerLoading] = useState<boolean>(false);

    const { showError } = useContext(ErrorContext)
    const navigate = useNavigate()
    const Login = useLogin();

    //function that checks if all the input is valid and if it is moves on to the next page
    const handleClick = async () => {
        const emailVal = emailValidation(emailValue);
        const passwordVal = passwordValidation(passwordValue);

        if (!emailVal && !passwordVal) {
            setServerLoading(true)
            try {
                const data = await Login("/api/contact/admin-login", {
                    username: emailValue,
                    email: emailValue,
                    password: passwordValue
                });

                if (data.success) {
                    setServerLoading(false)
                    navigate('')
                } else {
                    setServerLoading(false);
                    const message = data.msg.data.message;
                    if (message) {
                        switch (message) {
                            case "User doesn't exist":
                                setEmailError(LOGIN.USER_DOES_NOT_EXIST)
                                break;
                            case "Wrong password":
                                setPasswordError(LOGIN.WRONG_PASS)
                                break;
                            default:
                                setPasswordError(LOGIN.WAS_ERROR_TRY_AGAIN_LATER)
                        }
                    }
                }
            } catch (error) {
                showError(_ERROR_MESSAGE)
            }
        } else {
            setPasswordError(passwordVal);
            setEmailError(emailVal);
        }
    }

    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "email":
                setEmailError(emailValidation(e.target.value));
                break;
            case "password":
                setPasswordError(passwordValidation(e.target.value));
                break;
            default:
                break;
        }
    }

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "email":
                setEmailValue(e.target.value);
                break;
            case "password":
                setPasswordValue(e.target.value);
                break;
            default:
                break;
        }
    }

    return (
        <form>
            <div className="login-page">
                <div className="login-container">
                    <div className="login-first-title"> {LOGIN.ENTER}</div>
                    <div className="login-second-title">{LOGIN.ADMIN}</div>
                    <div>
                        <GenericInput
                            type='email'
                            title=""
                            onBlur={handleInputBlur}
                            onChange={handleInputChange}
                            name="email"
                            error={emailError}
                            value={emailValue}
                            placeholder={LOGIN.EMAIL}
                        />
                        <GenericInput
                            type='password'
                            title=""
                            onBlur={handleInputBlur}
                            onChange={handleInputChange}
                            name="password"
                            error={passwordError}
                            value={passwordValue}
                            placeholder={LOGIN.PASSWORD}
                        />
                    </div>

                    <GenericButton className="darkBlue big" handleClick={handleClick} serverLoading={serverLoading}>{LOGIN.LOGIN}</GenericButton>
                    <Link className="link" to='/forgot-password'>{LOGIN.FORGOT_PASS}</Link>
                </div>
            </div>
        </form>
    )
}

export default LogIn;