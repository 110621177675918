import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Tab, Tabs } from "@mui/material";

import { EditSystemProfileContext } from "../context/SystemProfileContexts";
import { LinkTabProps } from "../consts/interfaces/LinkTab.interface";
import { CANCEL, EDIT, SAVE_CHANGES } from "../consts/hebrew";

import GenericButton from "../generic-components/GenericButton";

import '../style/genericMiniNavbar.scss';

const ProfileMiniNavbar: React.FC = () => {
    const [value, setValue] = useState(0);

    const location = useLocation()
    const navigate = useNavigate()
    const id = useMemo(() => location.pathname.split('/')[3], [location.pathname])

    const { isEditMode, handleCancelClick, handleEditClicked, handleSaveChanges } = useContext(EditSystemProfileContext) || {}

    const systemType = useMemo(() => {
        return location.pathname.split('/')[1];
    }, [location])

    const profileMiniNavOptions = useMemo(() => [
        {
            name: `פרופיל`,
            href: ''
        },
        {
            name: 'סטטיסטיקות',
            href: '/statistics'
        },
        {
            name: 'ארכיון אירועים',
            href: '/archive'
        }
    ], []);


    useEffect(() => {
        if (location.pathname.split('/')[4] === 'statistics') {
            setValue(1);
        } else if (location.pathname.split('/')[4] === 'archive') {
            setValue(2)
        }
    }, [location.pathname]);


    const LinkTab = (props: LinkTabProps) => {
        return (
            <Tab
                component="a"
                onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

                    event.preventDefault();
                    navigate(`/${systemType}/profile/${id}${props.href}`);
                }}
                {...props}
            />
        );
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className='mini-nav-full'>
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
                {profileMiniNavOptions.map((item) => {
                    return <LinkTab key={item.href} label={item.name} href={item.href}></LinkTab>
                })}
            </Tabs>

            {value === 0 &&
                <div className="edit-buttons-container">
                    {isEditMode ?
                        <>
                            <GenericButton className="edit-profile-btn cancel" handleClick={handleCancelClick}>
                                {CANCEL}
                            </GenericButton>
                            <GenericButton className="edit-profile-btn" handleClick={handleSaveChanges}>
                                {SAVE_CHANGES}
                            </GenericButton>
                        </>
                        :
                        <GenericButton className="edit-profile-btn" handleClick={handleEditClicked}>
                            <img src='/icons-admin/pencil-white.svg' />
                            {EDIT}
                        </GenericButton>
                    }
                </div>}
        </div >
    )
}
export default ProfileMiniNavbar;