import { useMemo, useState } from 'react';
import { useAsyncEffect } from '@hilma/tools';
import { useParams } from "react-router-dom"
import { Skeleton } from "@mui/material";
import moment from 'moment';
import axios from 'axios';
import 'moment/locale/he';

import { AgeRange, Category, Hall, Population, SeatGroupAccessibility, SeatGroup, User, Ticket } from '../../consts/interfaces/EventTypes';
import { AVAILABLE_HEARING_AIDS, NOT_INTERESTED_MAYBE_NEXT_TIME } from '../../consts/hebrew';
import { Area } from '../../consts/interfaces/GenericProfilePic.interface';

import GenericFullScreenImagePopup from './GenericFullScreenImagePopup';
import PageNotFound from '../../pages/PageNotFound';
import GenericSeatGroup from './GenericSeatGroup';
import GenericTags from './GenericTags';
import InfoBar from '../InfoBar';
import YellowBanner from './YellowBanner';

import '../../style/ticketOrder.scss';
import '../../style/vipBanner.scss';

interface EventDetails {
    coverImage: string,
    posterImage?: string,
    contactName: string,
    contactPhone: string,
    eventType: 'seatGroups' | 'coupon' | 'oneGroup'
    duration?: number, // in minutes
    date: string,
    categories: Category[],
    populations: Population[],
    ageRange: AgeRange[]
    description: string,
    eventName: string,
    id: number,
    location: string,
    navigation: string,
    seatGroups: SeatGroup[],
    tickets: Array<Ticket>,
    isSeating?: boolean,
    isMarkedSeats?: boolean,
    hall: Hall,
    imageCoordinates: Area,
    accessibilities: SeatGroupAccessibility[],
    hearingAid: number,
    vipDetails?: string,
    eventContact?: User,
}
const TicketOrder: React.FC = () => {
    document.title = "דף אירוע"
    const [showFullPoster, setShowFullPoster] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketNum, setTicketNum] = useState(0)
    const [pageNotFound, setPageNotFound] = useState(false);

    const { id } = useParams()
    const eID = Number(id)

    const [statusRead, setStatusRead] = useState("קרא עוד")

    const [ticketPrice, setTicketPrice] = useState<number>(0);

    const [ticketDes, setTicketDes] = useState('')
    const [longTicketDes, setLongTicketDes] = useState('')
    const [eventDetails, setEventDetails] = useState<EventDetails>(
        {
            duration: 0,
            eventType: 'oneGroup',
            coverImage: '',
            date: "",
            posterImage: '',
            contactName: '',
            contactPhone: "",
            categories: [{ id: 0, categoryName: '' }],
            populations: [{
                populationName: '',
                id: 0
            }],
            ageRange: [],
            description: '',
            eventName: '',
            id: 0,
            location: '',
            navigation: '',
            isSeating: false,
            isMarkedSeats: false,
            seatGroups: [
                {
                    filePath: '',
                    id: 0,
                    seatNum: 0,
                    coupon: '0',
                    accessibilities: [],
                    usedSeats: 0,
                    tickets: []
                }
            ],
            tickets: [{
                id: 0,
                seatGroup: 0,
                status: '',
                timeExpired: '',
                extraTime: false,
                organization: { id: 0 }
            }],
            hall: {
                id: 0,
                hallName: '',
            },
            imageCoordinates: { x: 0, y: 0, width: 100, height: 100 },
            accessibilities: [],
            hearingAid: 0,
            vipDetails: ''
        }
    )

    const eventDateInfo = useMemo(() => {
        const momentDate = moment(new Date(eventDetails.date))
        const fullDate = momentDate.format('DD.MM.YYYY')
        const time = momentDate.format('HH:mm');
        const dayName = `יום ${momentDate.format('dddd')}`
        return `${dayName} | ${fullDate} | ${time}`
    }, [eventDetails.date])

    //function that asks the server for information on an event
    useAsyncEffect(async () => {
        if (!eID) return;

        try {
            const { data } = await (await axios.get(`/api/event/all-event-details?id=${eID}`))
            setTicketPrice(data.ticketPrice)
            const short = shortDescription(data.description)
            setTicketNum(data.tickets.filter((ticket: Ticket) => ticket.status === 'purchased').length)
            setTicketDes(short)
            setLongTicketDes(data.description)
            setEventDetails(data)

            setIsLoading(false);
        } catch (error) {
            setPageNotFound(true);
        }

    }, [eID])

    const shortDescription = (value: string) => {
        if (value && value.length > 79) {
            return value.substring(0, 79) + '...';
        } else {
            return value;
        }
    }

    //function that handles the read more button and shows the whole text or just the start of it
    const readMore = () => {
        if (statusRead === 'קרא עוד') {
            setTicketDes(longTicketDes);
            setStatusRead('קרא פחות');
        }
        else {
            let short = shortDescription(longTicketDes)
            setTicketDes(short);
            setStatusRead('קרא עוד');
        }
    }

    return (
        (!pageNotFound) ?
            <div className='details'>
                {!isLoading ?
                    <div>
                        <div className="big-image">
                            <YellowBanner isEvent tickets={ticketNum} />
                            <img className='sponge-bob' src={eventDetails.coverImage} alt={eventDetails.eventName}
                                style={eventDetails.imageCoordinates && {
                                    width: `${100 * 100 / eventDetails.imageCoordinates.width}%`, // makes the img bigger so the visible part is smaller
                                    left: `${-eventDetails.imageCoordinates.x * 100 / eventDetails.imageCoordinates.width}%`, // moves the img left so the cropped part moves right, proportionate to the img width
                                    top: `${-eventDetails.imageCoordinates.y * 100 / eventDetails.imageCoordinates.height}%`  // moves the img up so the cropped part moves down, proportionate to the img height
                                }}
                            />

                            {eventDetails?.vipDetails !== NOT_INTERESTED_MAYBE_NEXT_TIME ? <img className="vip-banner vip-banner-agency" src='/icons-admin/vip-banner-new.svg' alt="vip-banner" /> : null}
                        </div>

                        <p className='the-name'>{eventDetails.eventName}</p>
                        <p className='the-info'>{eventDateInfo}</p>
                        <InfoBar eventId={eID} barType='view-event' />
                        <div className='event-info-box'>
                            <div className='event-details'>
                                <p className='limitPara'>{ticketDes}</p>

                                {longTicketDes.length <= 79 ? "" : <button className="read_button" onClick={readMore}>{statusRead}</button>}
                                <br></br>
                                <br></br>


                                <div>
                                    <GenericTags
                                        {...eventDetails}
                                        ageRanges={eventDetails.ageRange}
                                        price={ticketPrice}
                                    />
                                    <div>
                                        {eventDetails.posterImage && <>
                                            <p onClick={() => setShowFullPoster(!showFullPoster)} className='limitPara-poster'>לצפייה בפוסטר המלא</p>
                                            <GenericFullScreenImagePopup
                                                open={showFullPoster}
                                                handleClose={() => setShowFullPoster(false)}
                                                image={eventDetails.posterImage}
                                            />
                                        </>
                                        }

                                        <div className="tickets-title">כרטיסים</div>
                                        {eventDetails.hearingAid !== 0 &&
                                            <div className='hearing-aids-text'>
                                                <img src="/icons-admin/accessibility/hearing-accessibility.svg" alt='hearing accessibility' />
                                                {eventDetails.hearingAid} {AVAILABLE_HEARING_AIDS}
                                            </div>}

                                        <div className="seat-groups-container">

                                            {eventDetails.seatGroups.map((seatgroup, index) =>
                                                <GenericSeatGroup
                                                    key={seatgroup.id}
                                                    index={index}
                                                    numOfTickets={seatgroup.seatNum}
                                                    accessibilities={seatgroup.accessibilities}
                                                    id={seatgroup.id}
                                                    hearingAids={eventDetails.hearingAid}
                                                />
                                            )}
                                            <div className="contact-name">איש קשר זמין</div>
                                            <div className='all-contact-details'>
                                                <div className="contact-details">{eventDetails.contactName} </div>
                                                <div className="contact-details"> {eventDetails.contactPhone}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Skeleton className='sponge-bob' variant="rectangular" width={"100vw"} height={"55vh"} />
                        <Skeleton variant="text" width={"15vh"} height={'7vh'} sx={{ mt: "4vh", mr: "10.8vw" }} />
                        <Skeleton variant="text" width={"30vh"} height={"4vh"} sx={{ mr: "10.8vw", mb: "2vh" }} />
                        <Skeleton variant="rectangular" width={"100vw"} height={'100vh'} sx={{ mt: '3vh' }} />
                    </div>
                }
            </div >
            :
            <PageNotFound />
    )
}
export default TicketOrder;