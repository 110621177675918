import clsx from 'clsx';
import { useNavigate } from 'react-router';

import { TO_ACCEPT, THERE_IS, AND_AGENCIES, AGENCIES, ORGANIZATIONS, WAITING_MALE, WAITING_FEMALE } from '../../consts/hebrew';

import { useRequestNumber } from "../../context/RequestNumberContext";
import GenericButton from '../../generic-components/GenericButton';
import GenericPopup from '../../generic-components/GenericPopup';

import '../../style/popupThereIsRequest.scss'

const PopupThereIsRequest: React.FC = () => {
    const { setIsPopupRequestOpen, isPopupRequestOpen, isWaitingOrganization, isWaitingAgency } = useRequestNumber();
    const navigate = useNavigate();

    const navigateToRequests = (orgOrAgency: string) => {
        setIsPopupRequestOpen(false);
        navigate(`/${orgOrAgency}/requests`);
    };

    const createWaitingSystemsTitle = () => {
        const waitingSystems = [];

        if (isWaitingOrganization) {
            waitingSystems.push(ORGANIZATIONS);
        }

        if (isWaitingAgency) {
            isWaitingOrganization ?
                waitingSystems.push(AND_AGENCIES) :
                waitingSystems.push(AGENCIES)
        }

        const waitingWord = isWaitingAgency ? WAITING_MALE : WAITING_FEMALE;

        return clsx(THERE_IS, waitingSystems, waitingWord, TO_ACCEPT);
    };

    return (
        <div>
            {(isWaitingAgency || isWaitingOrganization) &&
                <GenericPopup
                    LabelIcon='תזכורת'
                    imageStyles='imageIconWaitingStyle'
                    image='/icons-admin/waitingRequest.svg'
                    titleClassname='title-of-Waiting-Request'
                    isSmall={true} className="popup-waiting-accept"
                    title={createWaitingSystemsTitle()}
                    open={isPopupRequestOpen}
                    handleClose={() => { setIsPopupRequestOpen(false) }}
                >
                    <div className='agency-org-buttons'>
                        {isWaitingOrganization &&
                            <GenericButton
                                className='turquoise small margin'
                                handleClick={() => {
                                    navigateToRequests("organizations")
                                    setIsPopupRequestOpen(false)
                                }}
                            > <div className='showOrgAgency'>לצפייה בעמותות</div></GenericButton>}
                        <div style={{ marginBottom: "2vh" }}></div>
                        {isWaitingAgency && <GenericButton className='yellow small' handleClick={() => {
                            setIsPopupRequestOpen(false)
                            navigateToRequests("agencies")
                        }} > <div className='showOrgAgency'>לצפייה במוסדות</div></GenericButton>}

                    </div>
                </GenericPopup>}




        </div >
    )
}
export default PopupThereIsRequest;