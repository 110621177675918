
import { useCallback, useEffect, useState } from 'react';
import { ABOVE_HALF, ALL_TICKETS_PURCHASED, EVENTS, TICKETS_UTILIZATION_OF_EVENTS, TICKETS_WERE_NOT_PURCHASED_AT_ALL, UNDER_HALF } from '../consts/hebrew';
import { ticketUtilizationInterface } from '../consts/interfaces/StatisticsContext.interface';
import { PIE_TYPES } from '../consts/variables';

import GenericPieGraph from '../generic-components/GenericPieGraph';
import WhiteBoxWrapper from '../generic-components/WhiteBoxWrapper';

interface utilizationStatistics {
    name: string;
    value: number;
    tooltip: string;
    color: string;
}

const TicketsUtilizationDistribution: React.FC<{ ticketUtilization: ticketUtilizationInterface, legendFar?: boolean, style?: React.CSSProperties }> = ({ legendFar, ticketUtilization, style }) => {
    const { notPurchased, allPurchased, aboveHalf, underHalf, totalEvents } = ticketUtilization

    const [utilizationStatistics, setUtilizationStatistics] = useState<utilizationStatistics[] | null>(null)
    const [noData, setNoData] = useState<boolean>()

    const getTicketsUtilization = useCallback(async () => {
        setUtilizationStatistics([{
            name: ALL_TICKETS_PURCHASED,
            value: allPurchased || 0,
            tooltip: `${allPurchased} ${EVENTS}`,
            color: '#EDA424',
        },
        {
            name: ABOVE_HALF,
            value: aboveHalf || 0,
            tooltip: `${aboveHalf} ${EVENTS}`,
            color: '#F6D465',
        },
        {
            name: UNDER_HALF,
            value: underHalf || 0,
            tooltip: `${underHalf} ${EVENTS}`,
            color: '#95C1CE',
        },
        {
            name: TICKETS_WERE_NOT_PURCHASED_AT_ALL,
            value: notPurchased || 0,
            tooltip: `${notPurchased} ${EVENTS}`,
            color: '#08605F'
        }
        ])
    }, [allPurchased, notPurchased, aboveHalf, underHalf])

    useEffect(() => {
        setNoData(totalEvents === 0 || !totalEvents)

        if (allPurchased !== undefined && notPurchased !== undefined && aboveHalf !== undefined && underHalf !== undefined && totalEvents !== undefined)
            getTicketsUtilization()
    }, [allPurchased, notPurchased, aboveHalf, underHalf, totalEvents, getTicketsUtilization])

    return (
        <WhiteBoxWrapper >
            <h1 className='stats-title' style={{ paddingBottom: '2vh' }}>{TICKETS_UTILIZATION_OF_EVENTS}</h1>
            <GenericPieGraph
                data={utilizationStatistics}
                type={PIE_TYPES.EMPTY_PIE}
                isLegend={true}
                tooltip={true}
                firstLineMiddle={noData ? undefined : totalEvents}
                secondLineMiddle={noData ? undefined : EVENTS}
                noData={noData}
                legendFar={legendFar}
                style={{ width: '34vw', height: '20vh', ...style }}
            />
        </WhiteBoxWrapper >
    )

}

export default TicketsUtilizationDistribution