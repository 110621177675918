import { useContext, useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { OrganizationEditedSocket } from '../consts/interfaces/OrganizationSocket.interface';
import TableDetails from "../consts/interfaces/TableDetails.interface";
import { SYSTEM_FILTER_KEYS } from "../consts/arrays";
import { _ERROR_MESSAGE } from "../consts/variables";

import { updateElementById } from "../functions/array-functions";

import GenericSearchBar from "../generic-components/GenericSearchBar";
import GenericTable from "../generic-components/GenericTable";
import { ErrorContext } from "../context/ErrorContext";
import { useSocketListener } from '../hooks/socket';

const ListOfOrganizations: React.FC = () => {
    const { showError } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState<boolean>(true); //skeleton
    const [tableData, setTableData] = useState<Array<TableDetails>>([]);
    const [initTableData, setInitTableData] = useState<TableDetails[]>([])

    //gets minimal info about every organization approved to display in table
    useAsyncEffect(async () => {
        try {
            const org = (await axios.get('/api/organization/organization-minimal-info')).data;
            const orgNew: TableDetails[] = org.map(({ organizationName, isActive, ...item }: any) => {
                return {
                    ...item,
                    systemName: organizationName,
                    activityStatus: isActive,
                }
            })

            setInitTableData(orgNew);
            setTableData(orgNew);
            setIsLoading(false);
        } catch (err) {
            showError(_ERROR_MESSAGE);
        }
    }, []);

    //if an organization has been edited it updates the list here with the new info
    useSocketListener('organization-edited', (organizationEdited: OrganizationEditedSocket) => {
        if (organizationEdited) {
            const orgEditedDetails = {
                contactName: organizationEdited.contacts[0].name,
                systemName: organizationEdited.organizationName,
                email: organizationEdited.contacts[0].email,
                picture: organizationEdited.profilePhoto,
                contactPhone: organizationEdited.contacts[0].phone,
                id: organizationEdited.id
            }
            setInitTableData(prev => updateElementById(prev ?? [], organizationEdited.id, orgEditedDetails));
            setTableData(prev => updateElementById(prev ?? [], organizationEdited.id, orgEditedDetails));
        }
    });


    return (
        <div className="wrapper-list">
            <GenericSearchBar keysToSearch={SYSTEM_FILTER_KEYS} setFilteredArr={setTableData} initialOptions={initTableData} />
            {   
                isLoading || tableData?.length !== 0 ?
                    <GenericTable data={tableData ? tableData : []} accepted={false} isLoading={isLoading} />  
                    : <div className="no-results">לא נמצאו עמותות מתאימות</div>
            }
        </div>
    )
}
export default ListOfOrganizations;