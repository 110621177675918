import { useContext } from "react";
import { GoogleAnalyticsContext } from "../context/googleAnalytics";
import downloadServerFile from "../functions/download-server-file";
import { File } from "../consts/interfaces/SystemProfileContexts.interface"
import { PROFILE } from "../consts/variables"

const FileActions: React.FC<{ file: File }> = ({ file }) => {
    const { gaEvent } = useContext(GoogleAnalyticsContext)

    const handleDownloadFile = () => {
        try {
            downloadServerFile(file.url, file.downloadName);
        } catch (err) {
            gaEvent('download-file', {
                event_label: file.type
            })
        }
    }
    return (
        <div className="pdf-name">
            <div className="name-pdf-img">
                {file.titleView}
                <img src='/icons-admin/pdf-file.svg' alt='PDF file icon' />
            </div>
            <button className="download-file" onClick={handleDownloadFile}><img src="/icons-admin/downloadPdf.svg" alt="download" /></button>
            <div className="download-hover">{PROFILE.DOWNLOAD}</div>
        </div>
    )
}
export default FileActions