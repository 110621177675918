//this component returns the forgot password page(where they send an email to the user so they can update password)
import { useState } from "react";
import axios from "axios";

import { emailValidation } from "../functions/validations";
import GenericYesNoPopup from "../generic-components/GenericYesNoPopup";
import GenericButton from "../generic-components/GenericButton";
import GenericInput from "../generic-components/GenericInput";
import "../style/login.scss";

const ForgotPassword: React.FC = () => {

    const [emailError, setEmailError] = useState<string>("");
    const [emailValue, setEmailValue] = useState<string>("");
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [serverLoading, setServerLoading] = useState(false);

    //function that checks if all the input is valid and if it is moves on to the next page
    const handleClick = async () => {
        const emailVal = emailValidation(emailValue);
        if (!emailVal) {
            setServerLoading(true)
            const sendEmail = await axios.post("/api/contact/send-email-update-password", {
                email: emailValue
            })
            setServerLoading(false)
            if (sendEmail.data === "אימייל זה אינו קיים במערכת") {
                setEmailError('אימייל זה אינו קיים במערכת');


            } else if (sendEmail.data === "נשלח") {
                setShowPopup(true);
            }

        } else {
            setEmailError(emailVal);
            setServerLoading(false);
        }
    }

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmailError(emailValidation(e.target.value));
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmailValue(e.target.value);
    }

    return (
        <form>
            <div className="login-page">
                <div className="login-container">
                    <div className="login-first-title">אנטר</div>
                    <div className="login-second-title">איפוס סיסמה</div>
                    <GenericInput
                        onBlur={handleInputBlur}
                        onChange={handleInputChange}
                        error={emailError}
                        value={emailValue}
                        placeholder="אימייל"
                        name='email'
                        type='email'
                    />
                    <GenericButton className="darkBlue big" handleClick={handleClick} serverLoading={serverLoading}>שלח לי מייל</GenericButton>
                </div>

                {showPopup
                    ? <GenericYesNoPopup open={showPopup} handleClose={() => setShowPopup(false)} title="האיימיל נשלח בהצלחה!" type="forgotPassword" />
                    : null
                }
            </div>
        </form>
    )
}

export default ForgotPassword;