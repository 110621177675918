import { DONATION_WORTH } from "../consts/hebrew";
import WhiteBoxWrapper from "./WhiteBoxWrapper"
import '../style/genericPrice.scss';

const GenericPrice: React.FC<{ number: number, style?: React.CSSProperties }> = ({ number, style }) => {

    return (
        <WhiteBoxWrapper>
            <div className="generic-price" style={style}>
                <div className="number">
                    <span>₪</span>
                    {number.toLocaleString("en-US")}
                </div>
                <div className="description">
                    {DONATION_WORTH}
                </div>
            </div>
        </WhiteBoxWrapper>
    )
}

export default GenericPrice;