import { Dispatch, SetStateAction, useRef, useState } from "react";
import { FileInput, FileInputRefType, FilesUploader, UploadError, UploadedFile } from "@hilma/fileshandler-client";
import { Area } from "react-easy-crop";

import { DELETE_PHOTO, EDIT_PHOTO, LIMIT_ERROR, LOAD_IMAGE } from "../consts/hebrew";
import { INVALID_FILE_TYPE, _FILE_LIMIT, _FILE_TOO_BIG } from "../consts/variables";

import PopupCropPicture from "../components/popups/PopupCropPicture";
import useImageCoordinates from "../hooks/useImageCoordinates";

import GenericToolTip from "./GenericToolTip";

import "../style/genericProfilePicInput.scss";

interface GenericProfilePicInputProps {
    title?: string;
    filesUploader: FilesUploader;
    imageLink: string;
    imageCoordinates: Area;
    setImageCoordinates: Dispatch<SetStateAction<Area>> | ((value: Area) => void);
    setImageLink: Dispatch<SetStateAction<string>> | ((value: string) => void);
    imageError?: string;
}
const GenericProfilePicInput: React.FC<GenericProfilePicInputProps> = ({ title: side, imageCoordinates, setImageCoordinates, filesUploader, imageLink, setImageLink, imageError: parentImageError }) => {

    const imageCoordinatesStyle = useImageCoordinates(imageCoordinates)

    const [cropPopup, setCropPopup] = useState<boolean>(false);
    const [imageError, setImageError] = useState<string>("");
    const [imageCropLink, setImageCropLink] = useState<string>('')
    const fileInputRef = useRef<FileInputRefType>(null)

    const openFilesUploader = () => {
        fileInputRef.current?.open()
    }
    const onEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
        if (event?.key === "Enter") {
            openFilesUploader()
        }
    }
    // file uploader
    const handleFileChange = (value: UploadedFile) => { // function that handle the file uploader
        imageError && setImageError(""); // if there is an error in the file uploader, it will be removed
        setImageCropLink(value.link)
        setCropPopup(true)
    };

    const saveImgAfterConfirmCrop = (image: string) => {
        setImageLink(image);
    }

    const closeCropPopUp = () => {
        setCropPopup(false)
        setImageCropLink('')
        filesUploader.deleteAll()
    }

    const deleteProfilePic = () => {
        setImageLink('/')
        setImageCropLink('')
        filesUploader.deleteAll()
    }

    const handleUploadError = (err: UploadError) => { // function that handles the error
        if (err.type === "wrong-type")
            setImageError(INVALID_FILE_TYPE);
        else
            setImageError(_FILE_TOO_BIG);
    }

    return (
        <div className="generic-input-container">
            <div className="generic-photo">
                <div className="generic-logo-title">{side}</div>
                <div className="generic-first">
                    <div className="upload-arrow-image-back" onClick={openFilesUploader} onKeyDown={onEnter} tabIndex={0}>
                        {imageLink === "" ? (
                            <img
                                src="/icons/folderUpload.png"
                                className="upload-arrow-image"
                                alt="upload"
                            />
                        ) : (
                            <div className="upload-arrow-images-second">
                                <img
                                    src={imageLink}
                                    className="selected-image"
                                    alt="profile"
                                    style={imageCoordinatesStyle}
                                />
                            </div>
                        )}
                    </div>
                </div>


                <FileInput
                    preventDoubleSelection
                    className="generic-input-file-text"
                    style={{ display: 'none' }}
                    singleUpload
                    type="image"
                    sizeLimit={_FILE_LIMIT}
                    filesUploader={filesUploader}
                    placeholder={LOAD_IMAGE}
                    onChange={handleFileChange}
                    onError={handleUploadError}
                    ref={fileInputRef}
                />
                <PopupCropPicture open={cropPopup} image={imageCropLink || imageLink} ratio={1} handleClose={closeCropPopUp} setCropPopup={setCropPopup} setImageCoordinates={setImageCoordinates} saveImgAfterConfirmCrop={saveImgAfterConfirmCrop} />
            </div>

            {
                imageError || parentImageError ?
                    <div className="link-error">
                        {imageError || parentImageError}
                    </div>
                    :
                    <div className="limit link-error" >
                        {LIMIT_ERROR}
                    </div>
            }
            {
                imageLink &&
                <div className="edit-delete-box">
                    <GenericToolTip name='edit-photo' title={EDIT_PHOTO}>
                        <div className="edit-photo-cordinates" onClick={() => setCropPopup(true)}>
                            <img className="edit-icon" src="/icons-admin/Icon-edit.svg" />
                        </div>
                    </GenericToolTip>
                    <GenericToolTip name='delete-photo' title={DELETE_PHOTO}>
                        <div className="delete-photo" onClick={deleteProfilePic}>
                            <img className="delete-icon" src="/icons-admin/Icon-trash.svg" />
                        </div>
                    </GenericToolTip>
                </div>
            }
        </div >
    )
}
export default GenericProfilePicInput;
