import axios from 'axios'
import { ProfileAgencyInterface, ProfileOrganizationInterface } from '../../consts/interfaces/ProfileProps.interface'
import { AgencyEditAbleFields, OrganizationEditAbleFields } from '../../consts/interfaces/SystemProfile.interface'
import { FilesUploader } from '@hilma/fileshandler-client'

export async function fetchAgencyProfile(agencyId: number) {
    return (await axios.get(`/api/agency/profile-details?id=${agencyId}`)).data as ProfileAgencyInterface
}

export async function fetchOrganizationProfile(organizationId: number) {
    return (await axios.get(`/api/organization/profile-details?id=${organizationId}`)).data as ProfileOrganizationInterface
}

export async function editAgencyProfile(agencyId: number, data: AgencyEditAbleFields, filesUploader: FilesUploader) {
    return (await filesUploader.post('/api/agency/edit-agency', { ...data, id: Number(agencyId) })).data
}

export async function editOrganizationProfile(organizationId: number, data: OrganizationEditAbleFields, filesUploader: FilesUploader) {
    return (await filesUploader.post('/api/organization/edit-organization', { ...data, id: Number(organizationId) })).data
}