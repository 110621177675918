import { Navbar } from "./hebrew"

export const SYSTEM_FILTER_KEYS = ['email', 'systemName', 'contactName', 'contactPhone']
export const EVENTS_FILTER_KEYS = ['eventName', 'day', 'date']
export const navbarOptions = [
    { name: Navbar.STATISTICS, href: '/statistics' },
    { name: Navbar.AGENCIES, href: '/agencies' },
    { name: Navbar.ORGANIZATIONS, href: '/organizations' },
    { name: Navbar.LIVE, href: '/live' }
]
