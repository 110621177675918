import React from 'react';
import { EditSystemProfileContext } from '../context/SystemProfileContexts';
import { EditSystemProfileCx } from '../consts/interfaces/SystemProfileContexts.interface';
import { Area } from '../consts/interfaces/GenericProfilePic.interface';
import GenericProfilePicInput from '../generic-components/GenericProfilePicInput';
import GenericProfilePic from '../generic-components/GenericProfilePic';

const ProfilePic: React.FC<{ profilePhoto: string, imageCoordinates: Area }> = ({ profilePhoto, imageCoordinates }) => {

    const { isEditMode, saveDataByIdentifier, systemProfileData, filesUploader } = React.useContext(EditSystemProfileContext) as EditSystemProfileCx;

    return (
        <div className='profile-image'>
            {isEditMode ?
                <GenericProfilePicInput
                    imageLink={systemProfileData.coverImage || profilePhoto}
                    filesUploader={filesUploader}
                    imageCoordinates={systemProfileData.imageCoordinates || imageCoordinates}
                    setImageCoordinates={(value: Area) => saveDataByIdentifier("imageCoordinates", value)}
                    setImageLink={(value: string) => saveDataByIdentifier("coverImage", value)}
                />
                :
                <GenericProfilePic
                    src={profilePhoto}
                    imageCoordinates={imageCoordinates}
                />
            }
        </div>
    )
}
export default ProfilePic