import { useContext, useEffect, useState } from "react"
import { AgencyAddressCx } from "../consts/interfaces/SystemProfileContexts.interface"
import { AgencyAddresssProviderProps } from "../consts/interfaces/systemProfileProviders.interface"
import { PROFILE } from "../consts/variables"
import { EditSystemProfileContext } from "../context/SystemProfileContexts"
import ItemInSystemProfile from "./ItemInSystemProfile"

const AgencyLocation: React.FC<{ agencyLocation: AgencyAddressCx }> = ({ agencyLocation }) => {
    const { navigationCx, locationCx, hallsCx } = agencyLocation
    const { isEditMode, saveDataByIdentifier } = useContext(EditSystemProfileContext) || {};

    const [navigation, setNavigation] = useState<string>(navigationCx)
    const [location, setLocation] = useState<string>(locationCx)
    const [halls, setHalls] = useState<string[]>([])

    useEffect(() => {
        setNavigation(navigationCx)
        setLocation(locationCx)
        if (!hallsCx) return
        setHalls(hallsCx)
    }, [agencyLocation, isEditMode])

    const editHalls = (value: string, index: number) => {
        const copiedHalls = [...halls]
        copiedHalls[index] = value
        setHalls(copiedHalls)
    }

    function saveLocationDetails<T extends AgencyAddresssProviderProps, K extends keyof T>(key: K, value: T[K]) {
        const prevLocationDetails = {
            location: locationCx,
            navigation: navigationCx,
            halls: halls.map(hallName => ({ hallName }))
        }
        const newLocationDetails = {
            ...prevLocationDetails,
            [key]: value
        }
        saveDataByIdentifier?.("locationDetails", newLocationDetails)
    }

    const saveHalls = (value: string, index: number) => {
        const formattedHalls = halls.map((hallName, indx) => indx === index ? ({ hallName: value }) : ({ hallName }))
        saveLocationDetails("halls", formattedHalls)
    }

    return (
        <div className='place'>
            <ItemInSystemProfile name={PROFILE.ADDRESS}
                data={location}
                setData={setLocation}
                isEdit={isEditMode}
                saveDataByIdentifier={(_, value) => saveLocationDetails("location", value as string)}
                id="locationDetails"
            />
            <ItemInSystemProfile
                name={PROFILE.WAYS_TO_ARRIVE}
                data={navigation}
                setData={setNavigation}
                isEdit={isEditMode}
                saveDataByIdentifier={(_, value) => saveLocationDetails("navigation", value as string)}
                id="locationDetails"
            />
            {halls?.map((hall, index) => (
                <ItemInSystemProfile
                    name={PROFILE.HALL}
                    data={hall}
                    setData={(value) => editHalls(value, index)}
                    isEdit={isEditMode}
                    saveDataByIdentifier={(_, value) => saveHalls(value as string, index)}
                    id="locationDetails"
                />
            ))}
        </div>
    )
}
export default AgencyLocation