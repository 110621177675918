import PreviewFilePDF from "./PreviewFilePdf";
import { OrgPdfFilesProps } from "../consts/interfaces/OrgPdfFiles.interface";

const OrgPdfFiles: React.FC<OrgPdfFilesProps> = ({ organizationFiles }) => {
    return (
        <div className="big-pdf-container">
            <div className="pdf-container">
                {organizationFiles.map((file, index) => <PreviewFilePDF file={file} key={index} />)}
            </div>
        </div>
    )
}

export default OrgPdfFiles;