import { useContext } from "react"

import { orgStatContext } from "../consts/interfaces/OrganizationStatisticsContnext.interface"
import { specificStatistics } from "../consts/interfaces/SpecificStatistics.interface"
import { PURCHASED_EVENTS } from "../consts/hebrew"

import { OrganizationStatisticsContext } from "../context/OrganizationStatisticsContext"
import { SpecificStatisticsContext } from "../context/SpecificStatisticsContext"
import OrganizationGivenFeedbacks from "./OrganizationGivenFeedbacks"
import EventCategoriesOrdered from "./EventCategoriesOrdered"
import TicketsUtilization from "./TicketsUtilization"
import ExtraTimeClicks from "./ExtraTimeClicks"
import NumEventsCard from "./NumEventsCard"

const OrganizationStatistics: React.FC = () => {
    const { countOfEvents, totalFeedbacks } = useContext(SpecificStatisticsContext) as specificStatistics
    const { eventCategoriesOrdered, extraTimeInfo, ticketStatusTypesCount } = useContext(OrganizationStatisticsContext) as orgStatContext

    return (
        <div className="statistics-container" id='organizations-statistics'>
            <NumEventsCard eventsAmount={countOfEvents} style={{ width: '10vw' }} title={PURCHASED_EVENTS} />
            <EventCategoriesOrdered eventCategoriesOrdered={eventCategoriesOrdered} />
            <ExtraTimeClicks extraTimeInfo={extraTimeInfo} />
            <OrganizationGivenFeedbacks totalFeedbacks={totalFeedbacks} />
            <TicketsUtilization ticketStatusTypesCount={ticketStatusTypesCount} style={{ width: '57vw' }} />
        </div>
    )
}

export default OrganizationStatistics