//this component returns a event feedback page with feedbacks on the event
import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { useAsyncEffect } from '@hilma/tools';
import { IconButton } from "@mui/material";

import { EventEditedSocket } from "../consts/interfaces/EventSocket.interface";
import { feedbackSentSocket } from "../consts/interfaces/Socket.interface";
import { Feedback } from '../consts/interfaces/GenericFeedbacks.interface';
import { _ERROR_MESSAGE } from "../consts/variables";

import { ErrorContext } from "../context/ErrorContext";
import GenericYesNoPopup from './GenericYesNoPopup';
import { useSocketListener } from '../hooks/socket';
import InfoBar from '../components/InfoBar';
import SpeechBubble from './SpeechBubble';

import '../style/feedbacks.scss';

const Feedbacks: React.FC = () => {

    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [imageDisplay, setImageDisplay] = useState<string>('');
    const { showError } = useContext(ErrorContext);
    const params = useParams();

    const [feedbackDetails, setFeedbackDetails] = useState<Feedback>({
        eventName: "",
        day: "",
        date: "",
        time: "",
        feedbackText: [{ feedbackText: "", profilePhoto: "", organizationName: "", images: [] }],
    })

    const id = useMemo(() => params.id, [params]);
    const eID = useMemo(() => params.eventId, [params]);

    const navigate = useNavigate();

    //gets a number and returns a day in the week
    const dayPerNum = (dayNum: number) => {
        let week = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"]
        return week[dayNum]
    }

    //gets from the server the feedbacks for the specific event
    useAsyncEffect(async () => {
        try {
            const event = await axios.get(`/api/event/get-event-feedbacks?eventId=${eID}`);
            const date = new Date(event.data.event[0].date);
            let newDate = JSON.stringify(date.getDate());
            let month = JSON.stringify(date.getMonth() + 1);
            const year = date.getFullYear();
            if (date.getDate() < 10) {
                newDate = '0' + date.getDate();
            }
            if (date.getMonth() < 10) {
                month = '0' + (date.getMonth() + 1);
            }
            const minutesTime = () => (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();
            const hoursTime = () => (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();
            const day = dayPerNum(date.getDay());


            setFeedbackDetails({
                eventName: event.data.event[0].eventName,
                day: day,
                date: newDate + "." + month + "." + year,
                time: hoursTime() + ":" + minutesTime(),
                feedbackText: event.data.feedbacksForEvent,
            })
        }
        catch {
            showError(_ERROR_MESSAGE);
        }

    }, [eID]);

    //changes on socket- if the event has been edited
    useSocketListener('event-edited', (event: EventEditedSocket) => {
        if (event) {
            setFeedbackDetails(prev => ({ ...prev, eventName: event.eventName }));
        }
    });

    //changes on socket- if a feedback has been added for the event
    useSocketListener('feedback-sent', (feedbackSent: feedbackSentSocket) => {
        if (feedbackSent && feedbackSent.eventID === eID) {
            setFeedbackDetails((prev) => ({
                ...prev,
                feedbackText: [
                    ...prev.feedbackText,
                    {
                        feedbackText: feedbackSent.feedbackText,
                        profilePhoto: feedbackSent.profilePhoto,
                        organizationName: feedbackSent.organizationName,
                        images: feedbackSent.images
                    }
                ]
            }));
        }
    });

    //go back function
    const handleIconClick = () => {
        navigate(`/agencies/profile/${id}/archive`);
    }

    //opens a big popup of the image that has been sent as a feedback
    const handleImageClick = (imagePath: string) => {
        setPopupOpen(true);
        setImageDisplay(imagePath)
    }

    return (
        <div className="feedback-page">
            <div className='go-back'>
                <div className="all-go-back">
                    <IconButton onClick={handleIconClick} className='arrow-img-go-back'>
                        <img className='img-go-back' src='/icons-admin/arrow.svg' alt='back' />
                    </IconButton>
                    <div className='go-back-text'>חזרה לארכיון אירועים</div>
                </div>
            </div>
            <div className="feedback-items">
                <div className="container-title">
                    <div className="artist"> {feedbackDetails.eventName} </div>
                    <div className="date">{feedbackDetails.day} | {feedbackDetails.date} | {feedbackDetails.time}</div>
                </div>
                <InfoBar barType='feedback' eventId={Number(eID)} />
                <div className='container-content'>

                    {feedbackDetails.feedbackText.map((item, index) => {
                        let imgDown = false;

                        return (
                            <>
                                < GenericYesNoPopup
                                    open={popupOpen}
                                    handleClose={() => setPopupOpen(false)}
                                    image={imageDisplay}
                                />
                                <SpeechBubble key={index} imgSrc={item.profilePhoto} title={item.organizationName} imgDown={imgDown} isSameOrg={false}>
                                    {item.feedbackText}
                                </SpeechBubble>

                                {item.images.length > 0 ?
                                    item.images.map((image, index) => {

                                        return (
                                            <>
                                                <SpeechBubble key={index} imgSrc={item.profilePhoto} title={item.organizationName} imgDown={imgDown} isSameOrg={true} onClick={() => handleImageClick(image)} classname={index === item.images.length - 1 ? "last-img-in-bubble" : ""}>
                                                    {<img className="img-in-bubble" src={image} alt="visual feedback" />}
                                                </SpeechBubble>
                                            </>
                                        )
                                    })
                                    :
                                    <></>
                                }
                            </>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}

export default Feedbacks;