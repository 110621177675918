import { Grow } from '@mui/material'
import { ErrorMessageProps } from '../consts/interfaces/ErrorMessage.interface'
import '../style/error.scss'

const ErrorMessage = (props: ErrorMessageProps) => {

    return (
        <Grow in={props.open}>
            <div className='error-screen'>
                <div className="error-container">
                    <div className="error-text">
                        <div> {props.message}</div>
                    </div>
                    <div style={{ fontSize: "5vh" }} onClick={() => { props.setOpen(false) }} className="close-button-error">×</div>
                </div>
            </div>
        </Grow>
    )
}

export default ErrorMessage