import GenericProfilePicProps from '../consts/interfaces/GenericProfilePic.interface';
import '../style/genericProfilePic.scss';

const DEFAULT_IMAGE_URL = '/icons-admin/loginIcon.svg';

const GenericProfilePic: React.FC<GenericProfilePicProps> = ({ src, imageCoordinates, className, style }) => {

    return (
        <div className={"profile-pic-wrapper " + (className || "")} style={style}>
            <img
                style={src && imageCoordinates ? {
                    position: 'absolute',
                    width: `${100 * 100 / imageCoordinates.width}%`, // makes the img bigger so the visible part is smaller
                    left: `${-imageCoordinates.x * 100 / imageCoordinates.width}%`, // moves the img left so the cropped part moves right, proportionate to the img width
                    top: `${-imageCoordinates.y * 100 / imageCoordinates.height}%`  // moves the img up so the cropped part moves down, proportionate to the img height
                } : {}}
                src={src}
                alt="profile"
                onError={(e) => { 
                    if (e.currentTarget.src === DEFAULT_IMAGE_URL) return;
                    e.currentTarget.src = DEFAULT_IMAGE_URL;
                }}
            />
        </div>
    )
}
export default GenericProfilePic;