import '../style/genericInfoToolTip.scss'

const GenericInfoToolTip: React.FC<{ text: string }> = ({ text }) => {
    return (
        <div className="i-circle" >
            i
            <div className="tooltip">
                {text}
            </div>
        </div>
    )
}

export default GenericInfoToolTip;