import { Outlet } from "react-router"
import GenericMiniNavbar from "../components/MiniNavbar"
import { EVENTS, EVENTS_ARCHIVE } from "../consts/hebrew"
import '../style/enterLivePage.scss'

const EnterLivePage: React.FC = () => {
    const miniNavOptions = [
        {
            name: EVENTS,
            href: ''
        },
        {
            name: EVENTS_ARCHIVE,
            href: '/archive-events'
        }]
    return (
        <div className="live-page">
            <GenericMiniNavbar subtitles={miniNavOptions} />
            <Outlet />
        </div>
    )
}

export default EnterLivePage