import { HeadCell } from "../consts/interfaces/TableCells.interface";

export const headCells1: HeadCell[] = [
    {
        id: "logo",
        numeric: false,
        disablePadding: true,
        label: ""
    },
    {
        id: "name",
        numeric: true,
        disablePadding: false,
        label: "שם"
    },
    {
        id: "contact",
        numeric: true,
        disablePadding: false,
        label: "איש קשר"
    },
    {
        id: "phonenum",
        numeric: true,
        disablePadding: false,
        label: "טלפון"
    },
    {
        id: "email",
        numeric: true,
        disablePadding: false,
        label: "מייל"
    },
    {
        id: "aproved",
        numeric: false,
        disablePadding: true,
        label: ""
    },
];

export const headCells2: HeadCell[] = [
    {
        id: "logo",
        numeric: false,
        disablePadding: true,
        label: ""
    },
    {
        id: "name",
        numeric: true,
        disablePadding: false,
        label: "שם"
    },
    {
        id: "contact",
        numeric: true,
        disablePadding: false,
        label: "איש קשר"
    },
    {
        id: "phonenum",
        numeric: true,
        disablePadding: false,
        label: "טלפון"
    },
    {
        id: "email",
        numeric: true,
        disablePadding: false,
        label: "מייל"
    },
    {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "סטטוס פעילות"
    },
];

export const headCellsTicketsDetailsPopup: HeadCell[] = [
    {
        id: "ticketsPurchased",
        numeric: true,
        disablePadding: false,
        label: "כמות כרטיסים שמימשו בפועל"
    },
    {
        id: "tickets",
        numeric: true,
        disablePadding: false,
        label: "כמות כרטיסים באנטר"
    },
    {
        id: "name",
        numeric: true,
        disablePadding: false,
        label: "שם עמותה"
    }
];