import { useContext, useEffect, useMemo, useState } from "react"

import { CLICKED_ON_NOT_RELEVANT, FROM_ALL_THE_OFFERS, NOT_RELEVANT_FEEDBACKS, REASONS_WHY_NOT_RELEVANT } from "../consts/hebrew"
import { StatContextInterface } from "../consts/interfaces/StatisticsContext.interface"
import { PIE_TYPES, vw } from "../consts/variables"

import GenericHorizontalGraph from "../generic-components/GenericHorizontalGraph"
import GenericPieGraph from "../generic-components/GenericPieGraph"
import WhiteBoxWrapper from "../generic-components/WhiteBoxWrapper"
import { StatisticsContext } from "../context/StatisticsContext"
import PopupNotRelevantEvents from "./popups/PopupNotRelevantEvents"

import '../style/notRelevantFeedbacks.scss'
import NotRelevantEventProps from "../consts/interfaces/NotRelevantEvent.interface"

interface feedbackType {
    feedbackText: string;
    amount: number;
}
interface notRelevantReasons {
    name: string,
    value: number,
}
interface clickedNotRelevantPieData {
    color: string,
    value: number
}

const NotRelevantFeedbacks: React.FC = () => {
    const { notRelevantFeedbacks, allEventsOffers, notRelevantFeedbackReasonsNoMatch, notRelevantFeedbackReasonsElse, notRelevantFeedbackReasonsTimeNotGood, notRelevantFeedbackReasonsToSoon } = useContext(StatisticsContext) as StatContextInterface

    const [popup, setPopup] = useState<boolean>(false);
    const [reasonNotRelevant, setReasonNotRelevant] = useState<NotRelevantEventProps[]>([{
        eventName: "string",
        date: "string",
        organizationName: "string",
        organizationProfile: "string",
        text: ""
    }])
    const [noData, setNoData] = useState<boolean>()
    const [totalFeedbacks, setTotalFeedbacks] = useState<number>(0)
    const [feedbackPercentOfOffers, setFeedbackPercentOfOffers] = useState<number>(0)
    const [clickedNotRelevantPieData, setClickedNotRelevantPieData] = useState<null | clickedNotRelevantPieData[]>(null)
    const [notRelevantReasons, setNotRelevantReasons] = useState<notRelevantReasons[]>([
        {
            name: NOT_RELEVANT_FEEDBACKS.TOO_SOON,
            value: 0
        },
        {
            name: NOT_RELEVANT_FEEDBACKS.NO_MATCH,
            value: 0
        },
        {
            name: NOT_RELEVANT_FEEDBACKS.TIME_NOT_GOOD,
            value: 0
        },
        {
            name: NOT_RELEVANT_FEEDBACKS.OTHER,
            value: 0
        },
    ])
    
    const ArrClickedOnListNotRelevantFeedbacks = useMemo(() => {
        return notRelevantReasons.filter(feedback => feedback.value > 0).map(feedback => feedback.name)
    }, [notRelevantReasons]);// add the arr only the feedbackText that at least one organization choose. 


    const whatDataIsRight = (name: string) => {
        switch (name) {
            case NOT_RELEVANT_FEEDBACKS.NO_MATCH:
                setReasonNotRelevant(notRelevantFeedbackReasonsNoMatch)
                break;
            case NOT_RELEVANT_FEEDBACKS.TIME_NOT_GOOD:
                setReasonNotRelevant(notRelevantFeedbackReasonsTimeNotGood)
                break;
            case NOT_RELEVANT_FEEDBACKS.TOO_SOON:
                setReasonNotRelevant(notRelevantFeedbackReasonsToSoon)
                break;
            case NOT_RELEVANT_FEEDBACKS.OTHER:
                setReasonNotRelevant(notRelevantFeedbackReasonsElse)
                break;
        }

    }

    useEffect(() => {
        const sum: number = notRelevantFeedbacks.reduce((sum: number, feedback: feedbackType) => sum + Number(feedback.amount), 0);
        setTotalFeedbacks(sum)
        const dataForNotRelevantReasons = setDataForNotRelevantReasons()
        dataForNotRelevantReasons.length !== 0 && setNotRelevantReasons(dataForNotRelevantReasons)
    }, [notRelevantFeedbacks])

    useEffect(() => {
        const noData = allEventsOffers === 0
        setNoData(noData)

        const percent = Math.round(totalFeedbacks * 100 / allEventsOffers)
        setFeedbackPercentOfOffers(percent)
        setClickedNotRelevantPieData([
            { color: '#95C1CE', value: totalFeedbacks },
            { color: '#E4F1F0', value: allEventsOffers - totalFeedbacks }
        ])
    }, [allEventsOffers, totalFeedbacks])

    const setDataForNotRelevantReasons = () => {
        const notRelevantReasonsCopied = [...notRelevantReasons]
        notRelevantFeedbacks.forEach((feedback: feedbackType) => {
            const feedbackTypeIndex = notRelevantReasons.findIndex(reason => reason.name === feedback.feedbackText)
            if (feedbackTypeIndex !== -1) {
                notRelevantReasonsCopied[feedbackTypeIndex].value = feedback.amount
            }
        })
        return notRelevantReasonsCopied
    }

    return (
        <WhiteBoxWrapper>
            <div className="wrapper-div-reasons-not-relevant">
                <div className="pie-graph-right">
                    <GenericPieGraph
                        data={clickedNotRelevantPieData}
                        firstLineMiddle={noData ? undefined : totalFeedbacks}
                        textOnHover={noData ? undefined : `${feedbackPercentOfOffers}%`}
                        type={PIE_TYPES.ONE_SLICE}
                        style={{ width: '10vw', height: '16vh' }}
                        textUnderPie={CLICKED_ON_NOT_RELEVANT}
                        textUnderPieOnHover={FROM_ALL_THE_OFFERS}
                        noData={noData}
                    />
                </div>
                <div className="horizontal-graph-left">
                    <h1 className="stats-title">{REASONS_WHY_NOT_RELEVANT}</h1>
                    <GenericHorizontalGraph
                        data={notRelevantReasons}
                        style={{ width: '38vw', height: '25vh' }}
                        labelsWidth={9 * vw}
                        filledBars
                        onClick={(event) => {
                            setPopup(true);
                            whatDataIsRight(event.currentTarget.textContent || '')
                        }}
                        onClickText={ArrClickedOnListNotRelevantFeedbacks}
                    />
                </div>
            </div>

            <PopupNotRelevantEvents events={reasonNotRelevant} open={popup} handleClose={() => setPopup(false)} />
        </WhiteBoxWrapper>
    )
}
export default NotRelevantFeedbacks